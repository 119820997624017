/**
 *
 * @param {response} err
 * @param {function[]} callbacks
 */
export function errorTokenHasExpired(err, callbacks) {
  if (err?.response?.status === 401) {
    callbacks.forEach(cb => cb?.());
    return err;
  }
}

export function interceptor(err, callbacks) {
  if (err?.response?.status === 401) {
    userTokenHasExpired(err, callbacks);
  }
}

export function userTokenHasExpired(err, callbacks) {
  callbacks.forEach(cb => cb?.());
  return err;
}
