import React from "react";

// api
import { loginAdmin } from "../api/BackerpayApi_2nd";

// hooks
import { useAdminAuth } from "../context/AdminAuthContext";

export default function useLoginAdmin() {
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { dispatch } = useAdminAuth();

  const login = async (account, password) => {
    setError("");
    setIsLoading(true);

    try {
      const res = await loginAdmin(account, password);
      const admin = { account, token: res.token };
      dispatch({ type: "login", payload: admin });
      return admin;

      // error
    } catch (err) {
      console.error(err);
      setError("登入失敗");
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error, isLoading };
}
