import React from "react";

/**
 *    search
 */
const isSearched = (searchText, source) => {
  // if searchText is '', then re.test() return true
  const re = new RegExp(searchText, "ig");
  return re.test(source);
};

export default function CryptoTable({ listOfCrypto, showStore }) {
  // error message
  const [errorMsg] = React.useState("");
  const [errorOpen, setErrorOpen] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");

  const detailBtn = info => {
    showStore(info);
  };

  return (
    <div className='relative p-3 shadow-md sm:rounded-lg'>
      {/* search */}
      <div className='flex items-center justify-between pb-4 bg-white'>
        <label htmlFor='table-search' className='sr-only'>
          Search
        </label>

        <div className='relative'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>

          <input
            type='text'
            id='table-search-users'
            className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
            placeholder='代幣名稱'
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {/* search end */}

      <section className='overflow-x-auto'>
        <table className='w-full text-sm text-left text-gray-500'>
          {/* header */}
          <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                時間
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                店家名稱
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                廠商資訊
              </th>
              {/* <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                提領總金額(台幣)
              </th> */}
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                代幣名稱
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                實際提領(加密貨幣)
              </th>
            </tr>
          </thead>

          {/* withdraw data row */}
          <tbody>
            {listOfCrypto?.map((crypto, idx) => {
              return (
                <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                  {/* 時間 */}
                  <th scope='row' className='px-3 py-4'>
                    <div>{new Date(crypto?.time).toLocaleString()}</div>
                  </th>

                  {/* 店家名稱 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{crypto?.store?.remark}</div>
                  </td>

                  {/* 廠商資訊 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <button
                      type='button'
                      className='group   relative py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'
                      onClick={() => detailBtn(crypto)}
                    >
                      廠商資訊
                      {/* tool tip */}
                      <p className='absolute z-10 -top-[120%] left-[0%] invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100 dark:bg-gray-700'>
                        {crypto.paymentId}
                      </p>
                    </button>
                  </td>

                  {/* 代幣名稱 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{crypto?.tokenSymbol}</div>
                  </td>

                  {/* 實際提領(加密貨幣) */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{crypto?.amount}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}
