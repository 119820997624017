import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_WEB3_URL,
});

const instance = axios.create({
  baseURL: process.env.REACT_APP_WEB3_URL,
});

/**
 * 同意廠商台幣出金
 * @param {string} token
 * @param {number} paymentId
 * @returns
 */
export async function approveTWDWithdraw(token, paymentId) {
  const res = await instance.post(
    "/api/v1/txn/withdraw-all",
    { paymentId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
}
