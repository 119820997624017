import React from "react";

const cssCurrent =
  "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500";
const cssDisabled =
  "inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500";
const cssOther =
  "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300";

export default function DefaultTabs({ tab, currentTab, setCurrentTab }) {
  //   const [tab] = React.useState({
  //     Profile: "profile",
  //     Dashboard: "dashboard",
  //     Settings: "settings",
  //     Contacts: "contacts",
  //     Disabled: "disabled",
  //   });

  //   const [currentTab, setCurrentTab] = React.useState();

  return (
    <ul className='flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400'>
      {Object.entries(tab).map(([key, value]) => (
        <li key={key} className='mr-2'>
          <button
            className={
              value.toLowerCase() === "disabled"
                ? cssDisabled
                : currentTab === value
                ? cssCurrent
                : cssOther
            }
            onClick={() => {
              if (value.toLowerCase() !== "disabled") {
                setCurrentTab(value);
                // console.log(value);
              }
            }}
          >
            {key}
          </button>
        </li>
      ))}
    </ul>
  );
}
