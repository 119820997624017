import "flowbite";
import { useState } from "react";
import { Link } from "react-router-dom";

// context
import { useAdminAuth } from "../context/AdminAuthContext";

const Navbar = () => {
  const { admin, dispatch } = useAdminAuth();
  const logout = () => dispatch({ type: "logout" });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const btnLogout = () => {
    logout();
  };

  return (
    <header>
      <div className='flex justify-between items-center p-3 bg-[#f1f1f1]'>
        {/* bavepay 的網頁要另外載入，不能用 Link */}
        <a href='/'>
          <h1 className='text-2xl'>Backerpay</h1>
        </a>

        <nav>
          {admin?.token && (
            <div className='relative flex items-center gap-3'>
              <button
                className='text-black font-medium rounded-lg text-sm px-2.5 py-2.5 text-center inline-flex items-center'
                type='button'
                onClick={() => setIsMenuOpen(prev => !prev)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>

              {/* <!-- Dropdown menu --> */}
              <div
                className={`
                  ${isMenuOpen ? "" : "hidden"} 
                  absolute top-0 right-0
                  z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow`}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className='px-4 py-3 text-gray-900'>
                  <div className='text-base font-bold text-center '>{admin.account}</div>
                </div>

                <ul
                  className='py-1 text-sm text-gray-700'
                  aria-labelledby='dropdownInformationButton'
                >
                  {/* <li>
                    <Link to='/create-payment' className='block px-4 py-2 hover:bg-gray-100'>
                      建立支付店家
                    </Link>
                  </li> */}
                  <li>
                    <Link to='/approve-payment' className='block px-4 py-2 hover:bg-gray-100'>
                      核准商家
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/approve-newcoin-vendor'
                      className='block px-4 py-2 hover:bg-gray-100'
                    >
                      核准新思幣廠商
                    </Link>
                  </li>
                  <li>
                    <Link to='/approve-3w-kyc' className='block px-4 py-2 hover:bg-gray-100'>
                      核准 3W 會員
                    </Link>
                  </li>
                  <li>
                    <Link to='/approve-withdraw' className='block px-4 py-2 hover:bg-gray-100'>
                      出金查閱
                    </Link>
                  </li>
                  <li>
                    <Link to='/update-contract' className='block px-4 py-2 hover:bg-gray-100'>
                      更新合約
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='/behavior-report' className='block px-4 py-2 hover:bg-gray-100'>
                      消費紀錄報表
                    </Link>
                  </li> */}
                </ul>

                <div className='py-1'>
                  <button
                    className='w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                    onClick={btnLogout}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          )}

          {!admin?.token && (
            <div className='relative flex items-center gap-3'>
              <button
                className='text-black font-medium rounded-lg text-sm px-2.5 py-2.5 text-center inline-flex items-center'
                type='button'
                onClick={() => setIsMenuOpen(prev => !prev)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>

              {/* <!-- Dropdown menu --> */}
              <div
                className={`
                  ${isMenuOpen ? "" : "hidden"} 
                  absolute top-0 right-0
                  z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow`}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className='px-4 py-3 text-gray-900'>
                  <div className='text-base font-bold text-center '></div>
                </div>

                <ul
                  className='py-1 text-sm text-gray-700'
                  aria-labelledby='dropdownInformationButton'
                >
                  <li>
                    <Link to='/userkycflow' className='block px-4 py-2 hover:bg-gray-100'>
                      3W KYC
                    </Link>
                  </li>

                  <li>
                    <Link to='/login' className='block px-4 py-2 hover:bg-gray-100'>
                      Login
                    </Link>
                  </li>
                  {/* <Link to='/signup' className='hover:text-blue-700'>
                Signup
              </Link> */}
                </ul>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
