import { useTranslation } from "react-i18next";

export default function Footer() {
  const {t} = useTranslation()

  return (
    <footer>
      <div className='container'>
        <div className='footer__wrap'>
          <div className='footer__logo'>
            <a href='/#' className='imgwrap' alt={'footer'} aria-label={'Footer'}>
              <embed src='dist/images/logo-svg-w.svg' type='image/svg+xml' />
            </a>
          </div>
          <div className='footer__info'>
            <ul>
              <li>
                <span>Email</span>
                <a href='mailto: saltlab.service@gmail.com'>saltlab.service@gmail.com</a>
              </li>
              <li>
                <span>Addr.</span>
                <a href='https://goo.gl/maps/mktMBPJz13znXc7F8' target='_blank' rel='noreferrer'>
                  {t('台北市中正區館前路2號12樓')}
                </a>
              </li>
            </ul>

            <div className='official-btn flex flex-col'>
              <p
                className='cursor-pointer text-left md:text-right ml-3 mb-3 md:mr-3 whitespace-nowrap'
                onClick={() => {
                  window.open("service/backerpay.pdf", "_blank");
                }}
              >
                {t('服務條款')}
              </p>
              <a href='https://page.line.me/923ikbsb' target='_blank' rel='noreferrer' className={'block'}>
                {t('聯絡我們')}
              </a>
            </div>
          </div>
          <div className='footer__line' />
          <div className='footer__copyright'>
            © Produced by SaltLab |{" "}
            <a href='https://mak66design.com/' target='_blank' rel='noreferrer'>
              Design by M.A.K.
            </a>
          </div>
        </div>
      </div>
    </footer>

    // <footer>
    //   <div classname='container'>
    //     <div classname='footer__wrap'>
    //       <div classname='footer__index'>
    //         <h2>現在就立即註冊吧！</h2>
    //         <div classname='btns'>
    //           <a classname='signinbtn' href='registration.html'>
    //             立即註冊
    //             <span />
    //           </a>
    //           <a classname='contactbtn' href='javascript:;'>
    //             聯絡我們
    //             <span />
    //           </a>
    //         </div>
    //         <div classname='imgwrap'>
    //           <img src='dist/images/footer_img.gif' alt />
    //         </div>
    //       </div>
    //       <div classname='footer__logo'>
    //         <a href='./' classname='imgwrap'>
    //           <embed src='dist/images/logo_footer.svg' type='image/svg+xml' />
    //         </a>
    //       </div>
    //       <div classname='footer__info'>
    //         <ul>
    //           <li>
    //             <span>Email</span>
    //             <a href='mailto: bavenft@gmail.com'>bavenft@gmail.com</a>
    //           </li>
    //           <li>
    //             <span>Addr</span>
    //             <a href='https://goo.gl/maps/mktMBPJz13znXc7F8' target='_blank'>
    //               台北市中正區館前路2號12樓
    //             </a>
    //           </li>
    //         </ul>
    //         <div classname='official-btn'>
    //           <a href='#' target='_blank'>
    //             聯絡我們
    //           </a>
    //         </div>
    //       </div>
    //       <div classname='footer__line'>
    //         <div classname='footer__copyright'>
    //           © 2022 BAVE區塊鏈應用服務公司 All Rights Reserved. |{" "}
    //           <a href='https://mak66design.com/' target='_blank'>
    //             Design by M.A.K.
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
  );
}
