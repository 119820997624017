/**
 * 日期加減
 * @param {*} numOfMonths
 * @param {*} date
 * @returns
 */
export function addMonths(numOfMonths, date = new Date()) {
  date.setMonth(date.getMonth() + numOfMonths);

  return date;
}

/**
 * 取當天開始的時間
 * @param {*} date
 * @returns unix time 13 digits
 */
export function getStartTimeOfDay(date = new Date()) {
  return date.setHours(0, 0, 0, 0);
}

/**
 * 取當天最後的時間
 * @param {*} date
 * @returns unix time 13 digits
 */
export function getEndTimeOfDay(date = new Date()) {
  return date.setHours(23, 59, 59, 999);
}
