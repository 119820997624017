import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function MessageModal({
  title = "請輸入訊息",
  isOpen = false,
  setIsOpen,
  btnName = "確認",
  readMessage,
}) {
  const [msg, setMsg] = useState("");

  const onConfirm = msg => {
    closeModal();
    readMessage(msg);
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                  <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                    {title}
                  </Dialog.Title>
                  <div className='mt-2'>
                    {/* <p className='text-sm text-gray-500'>{content}</p> */}
                    <textarea
                      rows='5'
                      className='w-full'
                      placeholder='訊息'
                      onChange={e => setMsg(e.target.value)}
                    ></textarea>
                  </div>

                  <div className='mt-4'>
                    <button
                      type='button'
                      className={
                        // "inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        "inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      }
                      onClick={() => onConfirm(msg)}
                    >
                      {btnName}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
