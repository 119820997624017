export const navbar = {
  zh: {
    廠商專區: "廠商專區",
    廠商總覽: "廠商總覽",
    登出: "登出",
  },
  en: {
    廠商專區: "Store",
    廠商總覽: "Account",
    登出: "Logout",
  },
};

export const footer = {
  zh: {
    台北市中正區館前路2號12樓: "台北市中正區館前路2號12樓",
    服務條款: "服務條款",
    聯絡我們: "聯絡我們",
  },

  en: {
    台北市中正區館前路2號12樓: "12F, No. 2, Guanqian Rd., Zhongzheng Dist., Taipei City",
    服務條款: "Terms of Service",
    聯絡我們: "Contact Us",
  },
};

export const index = {
  zh: {
    開啟: "開啟",
    你的加密貨幣: "你的加密貨幣",
    支付服務: "支付服務",
    交易無國界: "交易無國界",
    提前布局元宇宙: "提前布局元宇宙",
    體驗升級: "體驗升級",
    付款簡單又便利: "付款簡單又便利",
    申辦快速: "申辦快速",
    最佳商務夥伴: "最佳商務夥伴",
    擁有加密貨幣錢包: "擁有加密貨幣錢包",
    即可快速註冊: "即可快速註冊",
    如何申辦加密貨幣錢包: "如何申辦加密貨幣錢包",
    "下載Metamask App": "下載Metamask App",
    創建密碼: "創建密碼",
    寫下助記詞: "寫下助記詞",
    確認助記詞: "確認助記詞",
    申辦成功: "申辦成功",
    支援多款加密貨幣錢包: "支援多款加密貨幣錢包",
    創建自己的: "創建自己的",
    加密貨幣商店: "加密貨幣商店",
    商城內容設定: "商城內容設定",
    "商品圖片、售價、說明": "商品圖片、售價、說明",
    物流資料收集: "物流資料收集",
    收款資訊建置: "收款資訊建置",
    "支援多鏈、多幣種": "支援多鏈、多幣種",
    "進階服務－多簽、分潤": "進階服務－多簽、分潤",
    提供多元企業合作方案: "提供多元企業合作方案",
    收款QRcode: "收款QRcode",
    加密貨幣支付好簡單: "加密貨幣支付好簡單",
    商城網站API介接: "商城網站API介接",
    "提供BACKER PAY作為網路商店的付款方式": "提供BACKER PAY作為網路商店的付款方式",
    提前布局新時代支付模式: "提前布局新時代支付模式",
  },
  en: {
    開啟: "",
    你的加密貨幣: "Crypto Currency",
    支付服務: "Payment",
    交易無國界: "Borderless Transactions",
    提前布局元宇宙: "Early Layout of Metaverse",
    體驗升級: "Experience Upgrade",
    付款簡單又便利: "Simple and Convenient Payment",
    申辦快速: "Fast Application",
    最佳商務夥伴: "Best Business Partner",
    擁有加密貨幣錢包: "Having a Cryptocurrency Wallet",
    即可快速註冊: "Can Quickly Register",
    如何申辦加密貨幣錢包: "How to Apply for a Cryptocurrency Wallet",
    "下載Metamask App": "Download Metamask App",
    創建密碼: "Create Password",
    寫下助記詞: "Write Down Mnemonic Phrase",
    確認助記詞: "Confirm Mnemonic Phrase",
    申辦成功: "Successful",
    支援多款加密貨幣錢包: "Support Multiple Cryptocurrency Wallets",
    創建自己的: "Create Your Own",
    加密貨幣商店: "Cryptocurrency Store",
    商城內容設定: "Content Setting",
    "商品圖片、售價、說明": "Product Images, Prices, and Descriptions",
    物流資料收集: "Collection of Logistics Information",
    收款資訊建置: "Payment Information",
    "支援多鏈、多幣種": "Support for Multiple Chains and Currencies",
    "進階服務－多簽、分潤": "Advanced Services - Multisig, Profit Sharing",
    提供多元企業合作方案: "Providing Diversified Business Collaboration Plans",
    收款QRcode: "Payment QR Code",
    加密貨幣支付好簡單: "Cryptocurrency Payment is so simple",
    商城網站API介接: "Integration of API for E-commerce Websites",
    "提供BACKER PAY作為網路商店的付款方式":
      "Providing BACKER PAY as a Payment Method for Online Stores",
    提前布局新時代支付模式: "Advance Preparation for New Era Payment Models",
  },
};

export const registration = {
  zh: {
    會員登入: "會員登入",
    "註冊會員請先申請錢包，並連結錢包獲得錢包地址喔！":
      "註冊會員請先申請錢包，並連結錢包獲得錢包地址喔！",
    連結錢包: "連結錢包",
    錢包地址: "錢包地址",
    "*必填": "*必填",
    登入: "登入",
    帳號: "帳號",
    密碼: "密碼",
    我已同意商家合約書: "我已同意商家合約書",
    服務條款: "服務條款",
    請同意服務條款: "請同意服務條款",
  },
  en: {
    會員登入: "Member Login",
    "註冊會員請先申請錢包，並連結錢包獲得錢包地址喔！":
      "Please apply for a metamask wallet and obtain a address before registering as a member.",
    連結錢包: "Wallet",
    錢包地址: "Wallet Address",
    "*必填": "*required",
    登入: "Login",
    帳號: "Account",
    密碼: "Password",
    我已同意商家合約書: "I have agreed to the Merchant Agreement",
    服務條款: "Terms of Service",
    請同意服務條款: "Please check out the Terms of Service",
  },
};

export const memberprofile = {
  zh: {},
  en: {},
};
