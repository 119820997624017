import React, { useState } from "react";
import moment from "moment-timezone";

import MessageModal from "./Modal/MessageModal";

export default function PaymentTable({ storeType, stores, handleApproveStore, handleRejectStore }) {
  const isApproved = storeType.value === "approved";

  /**
   *    按鈕 - 核准
   */
  const btnApprove = store => {
    handleApproveStore(store);
  };

  /**
   *    按鈕 - 開啟輸入退件訊息
   */
  const [rejectStore, setRejectStore] = useState(null);
  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const btnReject = async store => {
    setRejectStore(store);
    setIsOpenMessageModal(true);
  };

  /**
   * 處理退件商家
   * @param {string} rejectMessage
   */
  const handleRejectMessage = rejectMessage => {
    rejectStore && handleRejectStore(rejectStore, rejectMessage);
    rejectStore && setRejectStore(null);
  };

  /**
   *    勾選 - 全部選取 | 全部取消
   */
  const [checkedStoreIds, setCheckedStoreIds] = useState([]);
  const btnCheckAll = (e, stores) => {
    const isCheckAll = e.target.checked;

    !isCheckAll && setCheckedStoreIds([]);

    isCheckAll && setCheckedStoreIds(stores.map(store => store.id));
  };

  return (
    <div className='relative p-3 shadow-md sm:rounded-lg'>
      <MessageModal
        isOpen={isOpenMessageModal}
        setIsOpen={setIsOpenMessageModal}
        readMessage={handleRejectMessage}
      />

      {/* search */}
      {/* <div className='flex items-center justify-between pb-4 bg-white'>
        <label htmlFor='table-search' className='sr-only'>
          Search
        </label>
        <div className='relative'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            type='text'
            id='table-search-users'
            className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
            placeholder='公司名稱 | 聯絡人'
            onChange={e => setSearchText(e.target.value)}
          />
        </div>

        <Button onClick={approvedAllBtn}>全部核准</Button>
      </div> */}
      {/* search end */}

      <section className='overflow-x-auto'>
        <table className='w-full text-sm text-left text-gray-500'>
          {/* header */}
          <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
              <th scope='col' className='p-4'>
                <div className='flex items-center'>
                  <input
                    id='checkbox-all-search'
                    type='checkbox'
                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                    onChange={e => btnCheckAll(e, stores)}
                  />
                  <label htmlFor='checkbox-all-search' className='sr-only'>
                    checkbox
                  </label>
                </div>
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                時間
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                店家名稱
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                公司
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                聯絡人
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                錢包
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                支付幣種
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                Chain ID
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                Union ID
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                狀態
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                動作
              </th>
            </tr>
          </thead>

          {/* payment data row */}
          <tbody>
            {stores?.map(store => {
              // const { payments } = store;

              return (
                <tr key={store.id} className='bg-white border-b hover:bg-gray-50'>
                  {/* check box */}
                  <td className='w-4 p-4'>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        id='checkbox-table-search-1'
                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                        checked={checkedStoreIds.includes(store.id)}
                        onChange={e => {
                          if (e.target.checked) {
                            setCheckedStoreIds(prev => [...prev, store.id]);
                          } else {
                            const storeIds = checkedStoreIds.filter(id => id !== store.id);
                            setCheckedStoreIds(storeIds);
                          }
                        }}
                      />
                      <label htmlFor='checkbox-table-search-1' className='sr-only'>
                        checkbox
                      </label>
                    </div>
                  </td>

                  {/* 時間 */}
                  <th scope='row' className='px-3 py-4 whitespace-nowrap'>
                    <div>{moment(store.createdAt).format("YYYY-MM-DD")}</div>
                    <div>{moment(store.createdAt).format("HH:mm:ss")}</div>
                  </th>

                  {/* 店家名稱 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{store.remark}</div>
                  </td>

                  {/* 公司 */}
                  <th scope='row' className='px-3 py-4 whitespace-nowrap'>
                    <div className='text-base font-semibold text-gray-900'>{store.name}</div>
                    <div className='font-normal'>{store.email}</div>
                    <div className='font-normal'>{store.url}</div>
                    <div className='font-normal'>{store.tel}</div>
                  </th>

                  {/* 聯絡人 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='text-base font-semibold text-gray-900'>
                      {store.contactPerson}
                    </div>
                    <div className='font-normal text-gray-500'>{store.contactTel}</div>
                    <div className='font-normal text-gray-500'>{store.email}</div>
                  </td>

                  {/* 錢包 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>操作: {store.clientOper}</div>
                    <div className='font-normal text-gray-500'>收款: {store.clientAcct}</div>
                  </td>

                  {/* 支付幣種 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    {store.tokens.split(",").map(token => (
                      <div key={token} className='font-normal text-gray-500'>
                        {token}
                      </div>
                    ))}
                  </td>

                  {/* Chain ID */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>{store?.payments[0]?.chainName}</div>
                  </td>

                  {/* Union ID */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>
                      {store.unionId || store.paymentId}
                    </div>
                  </td>

                  {/* 狀態 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='flex items-center'>
                      {isApproved ? (
                        <>
                          <span className='h-2.5 w-2.5 rounded-full bg-green-400 mr-2'></span>
                          <span>approved</span>
                        </>
                      ) : (
                        <>
                          <span className='h-2.5 w-2.5 rounded-full bg-red-500 mr-2'></span>
                          <span>not approved</span>
                        </>
                      )}
                    </div>
                  </td>

                  {/* buttons */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='flex flex-col gap-2'>
                      {!isApproved && (
                        <button
                          className='font-medium text-green-600 hover:underline'
                          onClick={() => btnApprove(store)}
                        >
                          核准
                        </button>
                      )}

                      {!isApproved && (
                        <button
                          className='font-medium text-rose-600 hover:underline'
                          onClick={() => btnReject(store)}
                        >
                          退件
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}
