import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// hooks
import { useVendorAuth } from "../context/VendorAuthContext";

// 給商家前台 Backer 首頁登入使用
const RequireAuth = ({ allowedRoles }) => {
  const { vendor } = useVendorAuth();
  const location = useLocation();

  return !vendor?.token ? <Navigate to='/registration' state={{ from: location }} replace /> : <Outlet />;
};

export default RequireAuth;
