import React from "react";
import { Outlet } from "react-router-dom";

// componets
import Navbar from "../components/Navbar";

const Layout = () => {
  return (
    <main className='flex flex-col w-full h-screen App'>
      <Navbar />
      <Outlet />
      <div className='min-h-[1px] mt-0'></div>
    </main>
  );
};

export default Layout;
