import React from "react";
import Select from "react-select";

import { Button, Checkbox, Label } from "flowbite-react";

// import axios from "axios";
import Countries from "../data/countries.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "create_name":
      return { ...state, name: action.payload };
    case "create_birthDay":
      return { ...state, birthDay: action.payload };
    case "create_nationality":
      return { ...state, nationality: action.payload };
    case "create_isDoubleNationality":
      return { ...state, isDoubleNationality: action.payload };
    case "create_idNumber":
      return { ...state, idNumber: action.payload };
    case "create_issueDay":
      return { ...state, issueDay: action.payload };
    case "create_issuePlace":
      return { ...state, issuePlace: action.payload };
    case "create_idAddress":
      return { ...state, idAddress: action.payload };
    case "create_idType":
      return { ...state, idType: action.payload };
    case "create_risk":
      return { ...state, risk: action.payload };
    case "create_eInvoice":
      return { ...state, eInvoice: action.payload };
    default:
      throw new Error("action.type error!!!");
  }
};

/**
 *    user KYC form
 *    - state and dispatch are from UserKycFlow.js
 */
export default function UserKyc({ user, callback, btnName }) {
  // const [errorMsg, setErrorMsg] = React.useState('')
  // console.log(user);

  const [isOver20, setIsOver20] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    name: user?.name || "",
    birthDay: user?.birthDay ? new Date(user?.birthDay) : null, // 要以 new Date() 的型別送入
    nationality: user?.nationality || "台灣",
    isDoubleNationality: user?.isDoubleNationality || false, // true | false
    idNumber: user?.idNumber || "",
    issueDay: user?.issueDay ? new Date(user?.issueDay) : null, // 要以 new Date() 的型別送入
    issuePlace: user?.issuePlace || "",
    idAddress: user?.issuePlace || "",
    idType: user?.idType, // "初發 | 換發 | 補發"
    // risk: user?.risk, // '低' | '高'
    // eInvoice: user?.eInvoice || "",
  });

  /**
   *    handle submit
   *    - return "user", state
   */
  const handleSubmit = e => {
    e.preventDefault();
    if (btnName && !isOver20) {
      console.log('no check')
      return 
    }
    // console.log(state)
    callback("user", state);
  };

  React.useEffect(() => {
    // console.log(state)
  }, [state]);

  return (
    <div className='w-full max-w-[600px] mx-auto my-5 p-5 rounded-lg bg-[#f1f1f1]'>
      {/* <button onClick={() => console.log(formData)}>print</button> */}

      <h4 className='text-2xl text-center font-bold'>用戶 KYC 資料</h4>

      <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
        <div className='flex-grow'>
          <label htmlFor='name' className='form-label inline-block mb-2 text-gray-700'>
            姓名
          </label>
          <input
            required
            id='name'
            type='text'
            className={cssInput}
            value={state.name}
            onChange={e => dispatch({ type: "create_name", payload: e.target.value })}
          />
        </div>

        <div className='flex-grow'>
          <label htmlFor='birth_day' className='form-label inline-block mb-2 text-gray-700'>
            出生年月日
          </label>

          <div className='relative'>
            <DatePicker
              required
              className={cssInput}
              dateFormat='yyyy/MM/dd'
              isClearable={true}
              selected={state.birthDay}
              onChange={date => {
                // console.log(date);
                dispatch({ type: "create_birthDay", payload: date });
              }}
              peekNextMonth
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
            />
          </div>
        </div>

        <div className='flex-grow'>
          <label htmlFor='nationality' className='form-label inline-block mb-2 text-gray-700'>
            國籍
          </label>
          <Select
            required
            className=''
            isSearchable={true}
            defaultValue={{
              label: state.nationality,
              value: state.nationality,
            }}
            options={countries}
            onChange={({ label, value }) =>
              dispatch({ type: "create_nationality", payload: value })
            }
            openMenuOnFocus={() => {
              console.log("hi");
            }}
          />
        </div>

        <div className='lex-grow'>
          <label
            htmlFor='is_double_nationality'
            className='form-label inline-block mb-2 text-gray-700'
          >
            雙重國籍
          </label>

          <Select
            required
            isSearchable={false}
            defaultValue={
              state.isDoubleNationality
                ? isDoubleNationalityOptions[1]
                : isDoubleNationalityOptions[0]
            }
            options={isDoubleNationalityOptions}
            onChange={({ label, value }) =>
              dispatch({ type: "create_isDoubleNationality", payload: value })
            }
          />
        </div>

        <div className='flex-grow'>
          <label htmlFor='id_number' className='form-label inline-block mb-2 text-gray-700'>
            身分證字號
          </label>
          <input
            required
            id='id_number'
            type='text'
            className={cssInput}
            value={state.idNumber}
            onChange={e => dispatch({ type: "create_idNumber", payload: e.target.value })}
          />
        </div>

        <div className='flex-grow'>
          <label htmlFor='issue_day' className='form-label inline-block mb-2 text-gray-700'>
            發證日期
          </label>

          <div className='relative'>
            {/* <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
              <svg
                aria-hidden='true'
                className='w-5 h-5 text-gray-500 dark:text-gray-400'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </div> */}
            <div className='absolute'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-6 h-6 absolute'
              >
                <path d='M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z' />
                <path
                  fillRule='evenodd'
                  d='M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z'
                  clipRule='evenodd'
                />
              </svg>
            </div>

            <DatePicker
              required
              className={cssInput}
              selected={state.issueDay}
              onChange={date => dispatch({ type: "create_issueDay", payload: date })}
              dateFormat='yyyy/MM/dd'
              isClearable={true}
              peekNextMonth
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
            />
          </div>
        </div>

        <div className='flex-grow'>
          <label htmlFor='issue_place' className='form-label inline-block mb-2 text-gray-700'>
            發證地點
          </label>
          <input
            required
            id='issue_place'
            type='text'
            className={cssInput}
            value={state.issuePlace}
            onChange={e => dispatch({ type: "create_issuePlace", payload: e.target.value })}
          />
        </div>

        <div className='flex-grow'>
          <label htmlFor='id_address' className='form-label inline-block mb-2 text-gray-700'>
            戶籍地址
          </label>
          <input
            required
            id='id_address'
            type='text'
            className={cssInput}
            value={state.idAddress}
            onChange={e => dispatch({ type: "create_idAddress", payload: e.target.value })}
          />
        </div>

        <div className='flex-grow'>
          <label htmlFor='id_type' className='form-label inline-block mb-2 text-gray-700'>
            換補發類別
          </label>

          <Select
            required
            isSearchable={false}
            // defaultValue={state.idType}
            defaultValue={idTypeOptions.find(option => option.value === state.idType)}
            options={idTypeOptions}
            onChange={({ label, value }) => dispatch({ type: "create_idType", payload: value })}
          />
        </div>

        {/* <div className='flex-grow'>
          <label htmlFor='id_type' className='form-label inline-block mb-2 text-gray-700'>
            風險
          </label>

          <Select
            required
            isSearchable={false}
            // defaultValue={state.idType}
            defaultValue={isRiskOptions.find(option => option.value === state.risk)}
            options={isRiskOptions}
            onChange={({ label, value }) => dispatch({ type: "create_risk", payload: value })}
          />
        </div> */}

        {/* <div className='flex-grow'>
          <label htmlFor='e_invoice' className='form-label inline-block mb-2 text-gray-700'>
            載具
          </label>
          <input
            id='e_invoice'
            type='text'
            className={cssInput}
            value={state.eInvoice}
            onChange={e => dispatch({ type: "create_eInvoice", payload: e.target.value })}
          />
        </div> */}

        {/* <div className='flex-grow'>
          <label htmlFor='id_photo_down' className='form-label inline-block mb-2 text-gray-700'>
            身分證圖片
          </label>

          <div className='flex gap-3'>
            <div className='flex justify-center items-center w-full'>
              <label
                htmlFor='id_photo_up'
                className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer'
              >
                <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                  <svg
                    aria-hidden='true'
                    className='mb-3 w-10 h-10 text-gray-400'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                    ></path>
                  </svg>
                  <p className='mb-2 text-sm text-gray-500'>
                    <span className='font-semibold'>Click to upload</span> or drag and drop
                  </p>
                  <p className='text-xs text-gray-500'>身分證正面</p>
                </div>
                <input id='id_photo_up' type='file' className='hidden' />
              </label>
            </div>

            <div className='flex justify-center items-center w-full'>
              <label
                htmlFor='id_photo_down'
                className='flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer'
              >
                <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                  <svg
                    aria-hidden='true'
                    className='mb-3 w-10 h-10 text-gray-400'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                    ></path>
                  </svg>
                  <p className='mb-2 text-sm text-gray-500'>
                    <span className='font-semibold'>Click to upload</span> or drag and drop
                  </p>
                  <p className='text-xs text-gray-500'>身分證反面</p>
                </div>
                <input id='id_photo_down' type='file' className='hidden' />
              </label>
            </div>
          </div>
        </div> */}

        {btnName && (<div className='flex items-center gap-2'>
          <Checkbox id='remember' onChange={e => setIsOver20(e.target.checked)} required />
          <Label htmlFor='remember'>
            我已滿 20
            歲且非居住在日本、美國、歐洲經濟區境內，也沒有日本、美國、歐洲經濟區國家公民身份，並同意遵守網站的
            使用條款 和 隱私權政策 。
          </Label>
        </div>)}

        {btnName && (
          <div className='flex space-x-2 justify-center'>
            <Button type='submit'>{btnName}</Button>
          </div>
        )}
      </form>
    </div>
  );
}

const cssInput = `form-control 
    block
    w-full
    m-0
    pl-3 py-1.5
    text-base text-gray-700
    font-normal
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition ease-in-out
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`;

/**
 *    country options
 */
const countries = Countries.map(({ countryCode, countryName }) => {
  return { label: countryName, value: countryName };
});

/**
 *    id type options
 */
const idTypeOptions = [
  { label: "初發", value: "初發" },
  { label: "補發", value: "補發" },
  { label: "換發", value: "換發" },
];

/**
 *    is double nationality options
 */
const isDoubleNationalityOptions = [
  { label: "否", value: false },
  { label: "是", value: true },
];

/**
 *    is risk
 */
// const isRiskOptions = [
//   { label: "低", value: "低" },
//   { label: "高", value: "高" },
// ];
