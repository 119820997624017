import React from "react";

import { scriptUrls } from "../context/ScriptUrlsContext";

const useScript = url => {
  // StrictMode 防止跑 2 次
  const isRunned = React.useRef(false);

  React.useEffect(() => {
    if (isRunned.current || !url) return;
    isRunned.current = true;

    if (scriptUrls.includes(url)) return 
    else scriptUrls.push(url)

    const script = document.createElement("script");

    script.src = url;
    script.async = false;

    document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, [url]);
};

export default useScript;
