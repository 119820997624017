import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// hooks
import { useAdminAuth } from "../context/AdminAuthContext";

const RequireAuth = () => {
  const { admin } = useAdminAuth();

  const location = useLocation();

  return !admin?.token ? <Navigate to='/login' state={{ from: location }} replace /> : <Outlet />;
};

export default RequireAuth;
