import React from "react";

import useScript from "../../hooks/useScript";

import "./dist/css/main.css";
import "./dist/css/swiper-bundle.min.css";
import "./dist/css/product.css";

export default function Product() {
  // useScript('dist/js/main.js')
  useScript("dist/js/swiper-bundle.min.js");
  useScript("dist/js/product.js");

  return (
    <div>

      <main>
        <div className='product-section'>
          <div className='container'>
            <h1 className='f44-30 fw7'>產品介紹</h1>
            <div className='info'>
              <div className='product-img'>
                <div className='swiper productSwiper'>
                  <div className='swiper-wrapper'>
                    <div className='swiper-slide'>
                      <div className='img-wrap'>
                        <img src='dist/images/bag.png' alt='' />
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <div className='img-wrap'>
                        <img src='dist/images/product.png' alt='' />
                      </div>
                    </div>
                    <div className='swiper-slide'>
                      <div className='img-wrap'>
                        <img src='dist/images/product.png' alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='swiper-pagination' />
              </div>
              <div className='product-detail'>
                <div className='product-name f30-20 fw7'>balloon索繩水桶包</div>
                <div className='product-price f24-18 fw7'>NT$ 89,000</div>
                <div className='divider' />
                <p>
                  Balloon 索繩水桶包具有堅固的外部底座，配上柔軟內袋。
                  兼具重量輕巧、配戴舒適的特色。 此小號版本以小牛皮精製而成，具有緹花背帶，一側飾有
                  LOEWE 品牌圖案，背面飾有重複的 Anagram 圖案。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='description-section'>
          <div className='container'>
            <div className='editor_content'>
              <div className='editor_box pc_use'>
                <table border={0} cellPadding={8} cellSpacing={0} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#727272" }}>
                        <p style={{ textAlign: "center" }}>
                          <span style={{ fontSize: "18px" }}>
                            <span style={{ color: "#ffffff" }}>
                              <strong>商品特點</strong>
                            </span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table border={0} cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "8%" }}>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td style={{ width: "55%" }}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td style={{ verticalAlign: "top" }}>
                        <p>
                          <span style={{ fontSize: "16px" }}>•可肩背、斜背或手提 </span>
                        </p>
                        <p>
                          <span style={{ fontSize: "16px" }}>•索繩包口 </span>
                        </p>
                        <p>
                          <span style={{ fontSize: "16px" }}>•內部插袋 </span>
                        </p>
                        <p>
                          <span style={{ fontSize: "16px" }}>•可依個人飾品客製化 </span>
                        </p>
                        <p>
                          <span style={{ fontSize: "16px" }}>•無襯裡 </span>
                        </p>
                        <p>
                          <span style={{ fontSize: "16px" }}>•Anagram 圖案壓花</span>
                        </p>
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        <img alt='' src='dist/images/product_img.jpg' style={{ width: "100%" }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
              </div>
              <div className='editor_box mo_use'>
                <table border={0} cellPadding={8} cellSpacing={0} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: "#727272" }}>
                        <p style={{ textAlign: "center" }}>
                          <span style={{ fontSize: "16px" }}>
                            <span style={{ color: "#ffffff" }}>
                              <strong>商品特點</strong>
                            </span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <br />
                  <span style={{ fontSize: "16px" }}>•可肩背、斜背或手提 </span>
                </p>
                <p>
                  <span style={{ fontSize: "16px" }}>•索繩包口 </span>
                </p>
                <p>
                  <span style={{ fontSize: "16px" }}>•內部插袋 </span>
                </p>
                <p>
                  <span style={{ fontSize: "16px" }}>•可依個人飾品客製化 </span>
                </p>
                <p>
                  <span style={{ fontSize: "16px" }}>•無襯裡 </span>
                </p>
                <p>
                  <span style={{ fontSize: "16px" }}>•Anagram 圖案壓花</span>
                </p>
                <p>&nbsp;</p>
                <table border={0} cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <img alt='' src='dist/images/product_img.jpg' style={{ width: "100%" }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
        <div className='send-section'>
          <div className='container'>
            <h2 className='f30-20 fw7'>寄送資訊</h2>
            <form action>
              <div className='white-bg'>
                <div className='f16 fw7'>收件資訊</div>
                <div className='flex'>
                  <div className='form-group req'>
                    <lable className='form-label'>
                      <i>*</i>姓名
                    </lable>
                    <div className='form-input'>
                      <input type='text' placeholder='請輸入姓名' />
                      <small>*必填</small>
                    </div>
                  </div>
                  <div className='form-group req'>
                    <lable className='form-label'>
                      <i>*</i>手機
                    </lable>
                    <div className='form-input'>
                      <input type='text' placeholder='請輸入手機號碼' />
                      <small>*必填</small>
                    </div>
                  </div>
                </div>
                <div className='flex'>
                  <div className='form-group req'>
                    <lable className='form-label'>
                      <i>*</i>E-mail
                    </lable>
                    <div className='form-input'>
                      <input type='text' placeholder='請輸入E-mail' />
                      <small>*必填</small>
                    </div>
                  </div>
                </div>
                <div className='form-group req'>
                  <lable className='form-label'>
                    <i>*</i>通訊地址
                  </lable>
                  <div className='form-input'>
                    <input type='text' placeholder='請輸入地址' />
                    <small>*必填</small>
                  </div>
                </div>
                <div className='form-group'>
                  <lable className='form-label'>寄送方式</lable>
                  <div className='chekbox-wrap'>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='deliver'
                        id='deliver-none'
                      />
                      <label className='checkbox-label' htmlFor='deliver-none'>
                        無
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='deliver'
                        id='deliver-home'
                        defaultChecked
                      />
                      <label className='checkbox-label' htmlFor='deliver-home'>
                        宅配
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='deliver'
                        id='deliver-pickup'
                      />
                      <label className='checkbox-label' htmlFor='deliver-pickup'>
                        超商取貨
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='deliver'
                        id='deliver-store'
                      />
                      <label className='checkbox-label' htmlFor='deliver-store'>
                        到店取貨
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <lable className='form-label'>付款方式</lable>
                  <div className='chekbox-wrap'>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='payment'
                        id='payment-crypto'
                      />
                      <label className='checkbox-label' htmlFor='payment-crypto'>
                        crypto
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='payment'
                        id='payment-credit'
                        defaultChecked
                      />
                      <label className='checkbox-label' htmlFor='payment-credit'>
                        信用卡
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='payment'
                        id='payment-onDelivery'
                      />
                      <label className='checkbox-label' htmlFor='payment-onDelivery'>
                        貨到付款
                      </label>
                    </div>
                    <div className='checkbox-group'>
                      <input
                        className='checkbox-input'
                        type='radio'
                        name='payment'
                        id='payment-ATM'
                      />
                      <label className='checkbox-label' htmlFor='payment-ATM'>
                        ATM轉帳
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <lable className='form-label'>備註</lable>
                  <textarea placeholder='請輸入內容' defaultValue={""} />
                </div>
              </div>
              <div className='btnwrap'>
                <a className='btn' href='/#'>
                  付款
                  <span className='money' />
                </a>
              </div>
            </form>
          </div>
        </div>
      </main>

      <div className='top_btn' />
    </div>
  );
}
