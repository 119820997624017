import React from "react";
import axios from "axios";

import Select from "react-select";

import { Modal, Button, Textarea } from "flowbite-react";

// urls
const url_verify_vendor = process.env.REACT_APP_BACKERPAY_SERVER_URL + "/platformapi/verify_vendor";

/**
 * options
 */
const approvedOptions = [
  { label: "all", value: "all" },
  { label: "approved", value: "approved" },
  { label: "not approved", value: "not approved" },
];

export default function NewcoinUnapprovedVendorsTable({ user, vendors, dispatch }) {
  const [errorMsg, setErrorMsg] = React.useState("");
  // const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [storeIdSelecteds, setStoreIdSelecteds] = React.useState([]); // [1003, 1004]
  const [searchText, setSearchText] = React.useState("");
  const [rejectVendor, setRejectVendor] = React.useState(null); // 1002, 新思幣的 storeId 是唯一值
  const [showApproved, setShowApproved] = React.useState("all"); // default is "all"

  /**
   *    post storeId to backend
   */
  const verifyVendor = async (storeId, note, clientOper) => {
    try {
      const response = await axios.post(
        url_verify_vendor,
        {
          store_id: storeId,
          note: note || "success",
          clientOper,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response?.data?.op_code !== "1") {
        throw new Error(response.data.op_msg);
      }
    } catch (err) {
      setErrorMsg(err.message);
      console.error(err);
    }
  };

  /**
   *    按鈕 - 全部核准
   */
  const handleAllApprovedBtn = async () => {
    const vendorsSelecteds = vendors.filter(vendor => storeIdSelecteds.includes(vendor.storeId));

    for (let i = 0; i < vendorsSelecteds.length; i++) {
      const vendor = vendorsSelecteds[i];
      approveBtn(vendor);
    }
  };

  /**
   *    按鈕 - 核准
   */
  const approveBtn = async vendor => {
    await verifyVendor(vendor.storeId, 'success', vendor.clientOper);
    vendor.kyc = true;
    dispatch({ type: "update_vendor", payload: vendor });
  };

  /**
   *    按鈕 - 退件
   */
  const rejectBtn = async vendor => {
    setRejectVendor(vendor);
  };

  /**
   *    按鈕 - 發送出退件訊息
   */
  const SubmitRejectMsg = async vendor => {
    const message = inputRef.current.value;

    inputRef.current.value = "";
    setRejectVendor(null);

    await verifyVendor(vendor.storeId, message, vendor.clientOper);
    vendor.kyc = false;
    dispatch({ type: "update_vendor", payload: vendor });
  };

  /**
   *    勾選 - 全部選取 | 全部取消
   */
  const handleSelectAll = e => {
    let selecteds = [];

    if (e.target.checked) {
      vendors.forEach(vendor => {
        selecteds.push(vendor.storeId);
      });
    }

    setStoreIdSelecteds(selecteds);
  };

  /**
   *    set input auto focus
   */
  const inputRef = React.useRef();
  React.useEffect(() => {
    if (rejectVendor) inputRef.current.focus();
  }, [rejectVendor]);

  return (
    <div className='relative p-3 overflow-x-auto shadow-md sm:rounded-lg'>
      {/* error message */}
      <Modal show={errorMsg} size='md' popup={true}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {errorMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={() => setErrorMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* 退件原因對話框 */}
      <section>
        <Modal show={rejectVendor} size='md' popup={true} onClose={() => setRejectVendor()}>
          <Modal.Header />
          <Modal.Body>
            <div className='px-6 pb-4 space-y-6 sm:pb-6 lg:px-8 xl:pb-8'>
              <h3 className='text-xl font-medium text-gray-900'>請輸入退件原因</h3>
              <Textarea
                ref={inputRef}
                className='h-[100px]'
                placeholder='地址錯誤'
                required={true}
              />
              <div className='w-full'>
                <Button color='failure' onClick={() => SubmitRejectMsg(rejectVendor)}>
                  送出
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>

      {/* search */}
      <div className='flex items-center justify-between pb-4 bg-white'>
        <label htmlFor='table-search' className='sr-only'>
          Search
        </label>
        <div className='relative'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            type='text'
            id='table-search-users'
            className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
            placeholder='Search name'
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
        <Button onClick={handleAllApprovedBtn}>全部核准</Button>
      </div>
      {/* search end */}

      {/* show approved state */}
      <div className='flex items-center justify-start gap-3 pb-4'>
        <Select
          className='w-80'
          required
          isSearchable={false}
          defaultValue={approvedOptions[0]}
          options={approvedOptions}
          onChange={({ label, value }) => setShowApproved(value)}
        />
      </div>

      <table className='w-full text-sm text-left text-gray-500'>
        {/* header */}
        <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
          <tr>
            <th scope='col' className='p-4'>
              <div className='flex items-center'>
                <input
                  id='checkbox-all-search'
                  type='checkbox'
                  className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                  onChange={handleSelectAll}
                />
                <label htmlFor='checkbox-all-search' className='sr-only'>
                  checkbox
                </label>
              </div>
            </th>
            <th scope='col' className='px-6 py-3'>
              公司名稱
            </th>
            <th scope='col' className='px-6 py-3'>
              店家名稱
            </th>
            <th scope='col' className='px-6 py-3'>
              聯絡人
            </th>
            <th scope='col' className='px-6 py-3'>
              狀態
            </th>
            <th scope='col' className='px-6 py-3'>
              動作
            </th>
          </tr>
        </thead>

        {/* vendor data row */}
        <tbody className='whitespace-nowrap'>
          {vendors?.map(vendor => {
            const re = new RegExp(searchText, "ig");

            const isRender = searchText
              ? re.test(vendor.name) || re.test(vendor.contact_person)
              : true;

            if (!isRender) return "";
            if (showApproved === "approved" && !vendor?.kyc) return "";
            if (showApproved === "not approved" && vendor?.kyc) return "";

            return (
              <tr key={vendor.storeId} className='bg-white border-b'>
                {/* check box */}
                <td className='w-4 p-4'>
                  <div className='flex items-center'>
                    <input
                      id='checkbox-table-search-1'
                      type='checkbox'
                      className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                      checked={storeIdSelecteds.includes(vendor.storeId)}
                      onChange={e => {
                        if (e.target.checked) {
                          setStoreIdSelecteds(prev => [...prev, vendor.storeId]);
                        } else {
                          const selecteds = storeIdSelecteds.filter(
                            storedId => storedId !== vendor.storeId
                          );
                          setStoreIdSelecteds(selecteds);
                        }
                      }}
                    />
                    <label htmlFor='checkbox-table-search-1' className='sr-only'>
                      checkbox
                    </label>
                  </div>
                </td>

                {/* company */}
                <th scope='row' className='px-6 py-4 whitespace-nowrap'>
                  <div>
                    <div className='text-base font-semibold text-gray-900'>{vendor.name}</div>
                    <div className='font-normal'>{vendor.url}</div>
                  </div>
                </th>

                {/* store */}
                <td className='px-6 py-4 whitespace-nowrap'>
                  <div>{vendor.remark}</div>
                  <div>{vendor.storeId}</div>
                </td>

                {/* contact person */}
                <td className='px-6 py-4 whitespace-nowrap'>
                  <div>
                    <div className='text-base'>{vendor.contact_person}</div>
                    <div className='font-normal text-gray-500'>{vendor.contact_tel}</div>
                    <div className='font-normal text-gray-500'>{vendor.email}</div>
                  </div>
                </td>

                {/* status */}
                {vendor.kyc ? (
                  <td className='px-6 py-4'>
                    <div className='flex items-center'>
                      <div className='h-2.5 w-2.5 rounded-full bg-green-400 mr-2'></div> approved
                    </div>
                  </td>
                ) : (
                  <td className='px-6 py-4'>
                    <div className='flex items-center'>
                      <div className='h-2.5 w-2.5 rounded-full bg-red-500 mr-2'></div> not approved
                    </div>
                  </td>
                )}

                {/* buttons */}
                <td className='flex flex-col items-start gap-2 px-6 py-4'>
                  <button
                    className='font-medium text-green-600 hover:underline'
                    onClick={() => approveBtn(vendor)}
                  >
                    核准
                  </button>
                  <button
                    className='font-medium text-rose-600 hover:underline'
                    onClick={() => rejectBtn(vendor)}
                  >
                    退件
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
