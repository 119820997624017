import React from "react";

export default function PhotoInput({
  label,
  button,
  errorMessage,
  inputType,
  initValue,
  callback,
}) {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [imageFiles, setImageFiles] = React.useState("");
  const [imageUrl, setImageUrl] = React.useState("");

  /**
   *    handle image change
   */
  function handleChange(e) {
    setErrorMsg("");
    setImageFiles("");
    setImageUrl("");

    if (!isImage(e.target.files)) return setErrorMsg("只能上傳圖片");
    
    setImageFiles(e.target.files);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  }

  /**
   *    handle submit
   */
  const handleSubmit = e => {
    e.preventDefault();

    if (errorMsg) return;
    if (!imageFiles) return;

    callback(inputType, imageFiles);
  };

  /**
   *    handle init data
   */
  React.useEffect(() => {
    if (initValue) {
      if (typeof initValue === "string") {
        setImageUrl(initValue);
      } else {
        setImageFiles(initValue);
        setImageUrl(URL.createObjectURL(initValue[0]));
      }
    }
  }, [initValue]);

  return (
    <form
      className='block p-5 max-w-sm mx-auto rounded-lg border border-gray-200 shadow-md'
      onSubmit={handleSubmit}
    >
      {/* label */}
      <label htmlFor={inputType} className='block mb-2 text-sm font-medium text-gray-900'>
        {label}
      </label>

      {/* input */}
      <label
        // htmlFor={inputType}
        className='flex justify-center items-center w-full h-52 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer'
      >
        {imageUrl ? (
          <img src={imageUrl} alt='' className='h-full' />
        ) : (
          <div className='flex flex-col justify-center items-center pt-5 pb-6'>
            <svg
              aria-hidden='true'
              className='mb-3 w-10 h-10 text-gray-400'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
              ></path>
            </svg>

            <p className='mb-2 text-sm text-gray-500'>
              <span className='font-semibold'>Click to upload</span> or drag and drop
            </p>

            <p className='text-xs text-gray-500'>{label}</p>
          </div>
        )}

        <input id={inputType} type='file' className='hidden' onChange={handleChange} />
      </label>

      {/* error message */}
      <p className={`mt-2 text-pink-600 text-sm ${errorMsg ? "" : "hidden"}`}>{errorMsg}</p>

      {/* button */}
      <button className='mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 '>
        {button}
      </button>
    </form>
  );
}

/**
 *    check is image or not
 */
const isImage = files => {
  const myImage = files[0];
  const imageType = /image.*/;

  if (!myImage.type.match(imageType)) {
    return;
  }

  // if (myImage.size > 100 * 1024) {
  //   alert("Sorry, the max allowed size for images is 100KB");
  //   return;
  // }

  return true;
};
