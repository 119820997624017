import React from "react";
import { Outlet } from "react-router-dom";

// componets
import Header from "../components/Bavepay/Header";
import Footer from "../components/Bavepay/Footer";

const Layout = () => {
  return (
    <div id='body-content' className='page-faq'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
