import "./dist/css/main.css";
import "./dist/css/registration.css";

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// hooks
import useScript from "../../hooks/useScript";
import useLoginVendor from "../../hooks/useLoginVendor";

export default function Registration() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAgreeBackerpayTerms, setIsAgreeBackerpayTerms] = useState(false);

  const { login, isLoading, error } = useLoginVendor();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from || "/";

  /**
   * 登入
   */
  const btnLogin = e => {
    e.preventDefault();

    if (!isAgreeBackerpayTerms) return alert(t("請同意服務條款"));

    handleLogin(email, password);
  };

  async function handleLogin(email, password) {
    const vendor = await login(email, password);
    vendor && navigate(from, { replace: true });
  }

  useScript("dist/js/main.js");

  return (
    <div>
      <main>
        <div className='banner-section'>
          <div className='bg'>
            <div className='banner pc' style={{ backgroundImage: "url(dist/images/banner.jpg)" }} />
            <div
              className='banner mo'
              style={{ backgroundImage: "url(dist/images/banner_mb.jpg)" }}
            />
          </div>
          <div className='container'>
            <h1 className='main-title'>{t("會員登入")}</h1>
          </div>
          <div className='yel-ball' />
        </div>
        <div className='form-section'>
          <div className='container'>
            <div className='wrap'>
              <form>
                <div className='form-group req'>
                  <label className='form-label'>
                    <i>*</i>
                    {t("帳號")}
                  </label>
                  <div className='form-input'>
                    <input type='text' onChange={e => setEmail(e.target.value)} />
                  </div>
                </div>

                <div className='form-group req'>
                  <label className='form-label'>
                    <i>*</i>
                    {t("密碼")}
                  </label>
                  <div className='flex flex-col w-full'>
                    <input
                      type='password'
                      onChange={e => setPassword(e.target.value)}
                      className='px-[18px] md:px-6 py-[14px]  md:py-5 rounded-[20px] border border-[#2da9ef]'
                    />
                  </div>
                </div>

                <div className='btnwrap'>
                  <div className='flex items-center justify-center gap-2 mb-2'>
                    <input
                      type='checkbox'
                      id='ckeck-out-backerpay-terms'
                      onChange={e => {
                        // console.log(e.target.checked);
                        setIsAgreeBackerpayTerms(e.target.checked);
                      }}
                    />

                    <label htmlFor='ckeck-out-backerpay-terms'>
                      {t("我已同意商家合約書")}、
                      <span
                        className='font-bold text-blue-500 cursor-pointer'
                        onClick={() => window.open("service/backerpay.pdf", "_blank")}
                      >
                        {t("服務條款")}
                      </span>
                    </label>
                  </div>

                  <button className='btn' disabled={isLoading} onClick={btnLogin}>
                    {t("登入")}
                    <img
                      className='ml-3'
                      src={process.env.PUBLIC_URL + "/dist/images/signin.png"}
                      alt=''
                    />
                  </button>

                  {/* error message */}
                  {error && (
                    <p className='px-10 py-2 mx-auto mt-4 text-center bg-red-200 rounded-lg text-rose-700 w-fit'>
                      {error}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <div className='top_btn' />
    </div>
  );
}
