import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ExcelJs from "exceljs";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";

// components
import BackDrop from "../components/Spinner/BackDrop";

// utils
import { addMonths } from "../utils/datetime";

// api
import { getBehaviorReport } from "../api/BackerpayApi";

// hooks
import { useAdminAuth } from "../context/AdminAuthContext";

export default function BehaviorReport() {
  // isLoading
  const [isLoading, setIsLoading] = React.useState(false);

  const [startDate, setStartDate] = React.useState(addMonths(-2));
  const [endDate, setEndDate] = React.useState(new Date());

  const {admin} = useAdminAuth()
  const location = useLocation();
  const navigate = useNavigate();

  const btnDownload = async () => {
    setIsLoading(true);

    try {
      await handleDownload({ admin, startDate, endDate, location, navigate });
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  return (
    <div className='w-full max-w-[600px] mx-auto my-5 p-5 rounded-lg bg-[#f1f1f1]'>
      {/* isLoading */}
      {isLoading && <BackDrop />}

      <h1 className='text-2xl font-bold text-center'>建立支付店家</h1>

      <div className='flex items-center justify-center gap-4 mt-4'>
        <div>
          <label htmlFor='start-date' className='inline-block mb-2 text-gray-700 form-label'>
            起始日期
          </label>
          <DatePicker
            id='start-date'
            className='w-full rounded-lg shadow-md'
            selected={startDate}
            onChange={date => setStartDate(date)}
          />
        </div>

        <div>
          <label htmlFor='end-date' className='inline-block mb-2 text-gray-700 form-label'>
            結束日期
          </label>
          <DatePicker
            id='end-date'
            className='w-full rounded-lg shadow-md'
            selected={endDate}
            onChange={date => setEndDate(date)}
          />
        </div>
      </div>

      <button
        className='block px-5 py-2 mx-auto mt-4 text-white bg-indigo-500 rounded-md hover:bg-indigo-500/90 active:bg-indigo-600'
        onClick={btnDownload}
      >
        下載
      </button>
    </div>
  );
}

/**
 *
 * @param {*} startDate
 * @param {*} endDate
 */
async function handleDownload({ user, startDate, endDate, location, navigate }) {
  const from = moment(startDate).format("YYYY-MM-DD");
  const to = moment(endDate).format("YYYY-MM-DD");
  console.log("download behavior report", "\n", "from >>>", from, "\n", "to   >>>", to);

  // get data from backend
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };
  const data = await getBehaviorReport(from, to, config);

  // token expired
  if (data === -101) {
    alert("token 過期，請重新登入");
    navigate("/login", { state: { from: location.pathname }, replace: true });
    return null;
  }
  // no data
  if (data === -1) {
    alert("查無資料");
    return null;
  }

  // raw data
  console.log("raw data", data);

  // datas
  const report = data?.report;
  const statistics = data?.statistics;
  const walletAddresses = data?.walletAddress;
  const results = data?.results;

  // create excel
  const workbook = new ExcelJs.Workbook();

  // create sheet
  report && createReport(workbook, "report", report);
  statistics && createStatistics(workbook, "statistics", statistics);
  walletAddresses && createWalletAddress(workbook, "walletAddresses", walletAddresses);
  results && createResults(workbook, "results", results);

  // download report
  workbook.xlsx.writeBuffer().then(content => {
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `支付瀏覽紀錄_${from}_${to}.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
  });
}

/**
 * create report sheet
 * @param {*} workbook
 * @param {*} data
 */
function createReport(workbook, tableName, data) {
  const sheet = workbook.addWorksheet(tableName);

  sheet.addTable({
    name: tableName, // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [
      { name: "browse" },
      { name: "complete" },
      { name: "fail" },
      { name: "lost" },
      { name: "win" },
    ],
    rows: [[data.browse, data.complete, data.fail, data.lost, data.win]],
  });
}

/**
 * create statistics
 * @param {*} workbook
 * @param {*} data
 */
function createStatistics(workbook, tableName, data) {
  const sheet = workbook.addWorksheet(tableName);

  sheet.addTable({
    name: tableName, // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [
      { name: "browse" },
      { name: "complete" },
      { name: "confirmTransfer" },
      { name: "enterTransfer" },
      { name: "stores" },
      { name: "waitTransfer" },
    ],
    rows: [
      [
        data.browse,
        data.complete,
        data.confirmTransfer,
        data.enterTransfer,
        data.stores,
        data.waitTransfer,
      ],
    ],
  });
}

/**
 * create wallet address
 * @param {*} workbook
 * @param {*} tableName
 * @param {*} data
 */
function createWalletAddress(workbook, tableName, data) {
  const sheet = workbook.addWorksheet(tableName);

  const wallets = data.map(obj => [obj.walletAddress]); // [[0x1...], [0x2...], [0x3...], ...]
  //   console.log(wallets)
  //   return;

  sheet.addTable({
    name: tableName, // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [{ name: "wallet address" }],
    rows: wallets,
  });
}

/**
 * create results
 * @param {*} workbook
 * @param {*} tableName
 * @param {*} data
 */
function createResults(workbook, tableName, data) {
  const sheet = workbook.addWorksheet(tableName);

  const rows = [];
  const wallets = Object.keys(data);

  for (const wallet of wallets) {
    const browseDatas = data[wallet];

    for (const browseData of browseDatas) {
      rows.push([
        browseData.walletAddress,
        browseData.ip,
        browseData.chainName,
        browseData.unionAddress,
        browseData.storeId,
        browseData.paymentId,
        browseData.tokenName,
        browseData.tokenAddress,
        browseData.browse,
        browseData.enterTransfer,
        browseData.confirmTransfer,
        browseData.waitTransfer,
        browseData.complete,
      ]);
    }
  }

  sheet.addTable({
    name: tableName, // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [
      { name: "walletAddress" },
      { name: "ip" },
      { name: "chainName" },
      { name: "unionAddress" },
      { name: "storeId" },
      { name: "paymentId" },
      { name: "tokenName" },
      { name: "tokenAddress" },
      { name: "browse" },
      { name: "enterTransfer" },
      { name: "confirmTransfer" },
      { name: "waitTransfer" },
      { name: "complete" },
    ],
    rows: rows,
  });
}
