import { createContext, useContext, useReducer } from "react";

export const VendorAuthContext = createContext();

export const useVendorAuth = () => {
  return useContext(VendorAuthContext);
};

const preVendor = window.sessionStorage.vendor;

export const VendorAuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    vendor: preVendor && JSON.parse(preVendor),
  });

  return (
    <VendorAuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </VendorAuthContext.Provider>
  );
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case "login":
      const vendor = { account: action.payload?.email, token: action.payload?.token };
      window.sessionStorage.setItem("vendor", JSON.stringify(vendor));
      return { vendor };

    case "logout":
      window.sessionStorage.removeItem("vendor");
      return { vendor: null };

    default:
      throw new Error("no action type is match");
  }
};
