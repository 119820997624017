import useSWR from "swr";
import Select from "react-select";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

// api
import {
  getUnapprovedStore,
  getApprovedStore,
  approveStore,
  rejectStore,
} from "../api/BackerpayApi_2nd";
import {
  errorTokenHasExpired,
  // interceptor
} from "../api/ErrorHelper";

// hooks
import { useAdminAuth } from "../context/AdminAuthContext";

// components
import PaymentTable from "../components/PaymentTable";
import Pagination from "../components/Pagination";

export default function ApprovePayment() {
  // auth
  const { admin, dispatch } = useAdminAuth();
  const logout = () => dispatch({ type: "logout" });

  /**
   * 選擇 核准 | 未核准  商家資料
   */
  const [storeTypes] = useState([
    { label: "not approved", value: "not approved" },
    { label: "approved", value: "approved" },
  ]);
  const [storeType, setStoreType] = useState(storeTypes[0]);

  /**
   * 取得未核准商家資料
   */
  const [pageOfUnapprovedStore, setPageOfUnapprovedStore] = useState(1);
  const [pageSizeOfUnapprovedStore] = useState(10);
  const [totalContentsOfUnapprovedStore, setTotalContentsOfUnapprovedStore] = useState(0);
  const [unapprovedStores, setUnapprovedStores] = useState([]);
  useSWR(
    // args
    ["/getUnapprovedStore", admin.token, pageOfUnapprovedStore, pageSizeOfUnapprovedStore],

    // api
    ([_, token, page, pageSize]) => {
      const params = { page: page.toString(), pageSize: pageSize.toString() };
      return getUnapprovedStore(token, params);
    },

    // callbacks
    {
      onSuccess(data) {
        const { total, stores } = data;
        setTotalContentsOfUnapprovedStore(total);
        setUnapprovedStores(stores);
        console.log(total);
        console.log("Unapproved Store", stores);
      },
      onError(err) {
        const expiredError = errorTokenHasExpired(err, [
          () => toast.error("Token has expired \nLogout after 3 seconds"),
          () => setTimeout(() => logout(), 3000),
        ]);

        !expiredError && toast.error(err.message);
        console.error(err);
      },
    }
  );

  /**
   * 取得核准商家資料
   */
  const [pageOfApprovedStore, setPageOfApprovedStore] = useState(1);
  const [pageSizeOfApprovedStore] = useState(10);
  const [totalContentsOfApprovedStore, setTotalContentsOfApprovedStore] = useState(0);
  const [approvedStores, setApprovedStores] = useState([]);
  useSWR(
    // args
    ["/getApprovedStore", admin.token, pageOfApprovedStore, pageSizeOfUnapprovedStore],

    // api
    ([_, token, page, pageSize]) => {
      const params = { page: page.toString(), pageSize: pageSize.toString() };
      return getApprovedStore(token, params);
    },

    // callbacks
    {
      onSuccess(data) {
        const { total, stores } = data;
        setTotalContentsOfApprovedStore(total);
        setApprovedStores(stores);
        console.log(total);
        console.log("Approved Store", stores);
      },
      onError(err) {
        // interceptor(err)
        const expiredError = errorTokenHasExpired(err, [
          () => toast.error("Token has expired \nLogout after 3 seconds"),
          () => setTimeout(() => logout(), 3000),
        ]);

        !expiredError && toast.error(err.message);
        console.error(err);
      },
    }
  );

  /**
   * 核准商家
   * @param {obj} store
   */
  const handleApproveStore = async store => {
    console.log("approve store:", store);

    try {
      removePendingStore(store);

      const { id: storeId, unionId, paymentTmpId } = store;
      await approveStore(admin.token, storeId, unionId, paymentTmpId);

      // errors
    } catch (err) {
      undoPendingStore(store);
      toast.error("核准失敗");
      toast.error(err.message);
      console.error(err);
    }
  };

  /**
   * 退件商家
   * @param {obj} store
   * @param {string} rejectMsg
   */
  const handleRejectStore = async (store, rejectMsg) => {
    console.log("reject store:", store);
    console.log("reject message:", rejectMsg);

    try {
      removePendingStore(store);

      const { id: storeId, unionId, paymentTmpId } = store;
      await rejectStore(admin.token, storeId, unionId, paymentTmpId, rejectMsg);

      // errors
    } catch (err) {
      undoPendingStore(store);
      toast.error("退件失敗");
      toast.error(err.message);
      console.error(err);
    }
  };

  /**
   * 移除剛送出請求的商家
   * @param {obj} store
   */
  function removePendingStore(store) {
    const setStores = storeType.value === "approved" ? setApprovedStores : setUnapprovedStores;
    setStores(stores => stores.filter(s => s.id !== store.id));
  }

  /**
   * 還原送出請求的商家
   * @param {obj} store
   */
  function undoPendingStore(store) {
    const setStores = storeType.value === "approved" ? setApprovedStores : setUnapprovedStores;
    setStores(stores => [store, ...stores]);
  }
  return (
    <div className='p-5'>
      <div>
        <Toaster />
      </div>

      <h1 className='text-5xl font-extrabold text-center dark:text-white'>核准商家</h1>

      <Select
        className='mt-5 w-80'
        required
        options={storeTypes}
        isSearchable={false}
        defaultValue={storeTypes[0]}
        onChange={setStoreType}
      />

      <PaymentTable
        storeType={storeType}
        stores={storeType.value === "approved" ? approvedStores : unapprovedStores}
        handleRejectStore={handleRejectStore}
        handleApproveStore={handleApproveStore}
      />

      {storeType.value === "not approved" && (
        <Pagination
          currentPage={pageOfUnapprovedStore}
          setCurrentPage={setPageOfUnapprovedStore}
          contentsPerPage={pageSizeOfUnapprovedStore}
          totalContents={totalContentsOfUnapprovedStore}
        />
      )}

      {storeType.value === "approved" && (
        <Pagination
          currentPage={pageOfApprovedStore}
          setCurrentPage={setPageOfApprovedStore}
          contentsPerPage={pageSizeOfApprovedStore}
          totalContents={totalContentsOfApprovedStore}
        />
      )}
    </div>
  );
}
