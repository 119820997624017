import { createContext, useContext, useReducer } from "react";

export const AdminAuthContext = createContext();

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};

const preAdmin = window.sessionStorage.admin;

export const AdminAuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    admin: preAdmin && JSON.parse(preAdmin),
  });

  return (
    <AdminAuthContext.Provider value={{ ...state, dispatch }}>{children}</AdminAuthContext.Provider>
  );
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case "login":
      const admin = { accout: action.payload?.account, token: action.payload?.token };
      window.sessionStorage.setItem("admin", JSON.stringify(admin));
      return { admin };

    case "logout":
      window.sessionStorage.removeItem("admin");
      return { admin: null };

    default:
      throw new Error("no action type is match");
  }
};
