import React, {useEffect, useRef} from 'react';

function LazyLoadImage({imgSrc, sourceSrc, alt, className}) {
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const img = entry.target;
                    const source = img.previousSibling;
                    img.src = imgSrc;
                    source.srcset = sourceSrc;
                    observer.disconnect();
                }
            });
        });

        observer.observe(imgRef.current);

        return () => {
            observer.disconnect();
        };
    }, [imgSrc, sourceSrc]);

    return (
        <picture>
            <source type="image/webp" data-srcset={sourceSrc}/>
            <img ref={imgRef} src="https://placehold.co/400" data-src={imgSrc} alt={alt} className={className}/>
        </picture>
    );
}

export default LazyLoadImage;
