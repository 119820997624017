import React from "react";

// api
import { loginVendor } from "../api/BackerpayApi_2nd";

// hooks
import { useVendorAuth } from "../context/VendorAuthContext";

export default function useLoginVendor() {
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { dispatch } = useVendorAuth();

  const login = async (email, password) => {
    setError("");
    setIsLoading(true);

    try {
      const res = await loginVendor(email, password);
      const vendor = { email, token: res.token };
      dispatch({ type: "login", payload: vendor });
      return vendor;

      // error
    } catch (err) {
      console.error(err);
      setError("登入失敗");
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error, isLoading };
}
