import { useState } from "react";
import Select from "react-select";
import bigDecimal from "js-big-decimal";

// tools
import { isSearched } from "../../utils/tools";

// components
import TransferInfoForm from "../Form/TransferInfoForm";

export default function TWDsTable({
  listOfTWD,
  stateType,
  setStateType,
  showStore,
  enterTransferInfo,
  approveTwdApply,
}) {
  // 搜尋字串
  const [searchText, setSearchText] = useState("");

  // 匯款狀態
  const [transferStateOptions] = useState([
    { label: "Apply", value: "Apply" },
    { label: "Withdraw", value: "Withdraw" },
    { label: "Remit", value: "Remit" },
  ]);

  const [openTransferForm, setOpenTransferFrom] = useState(false);
  const [waitApproveTWD, setWaitApproveTWD] = useState(null);

  const btnShowStore = twd => {
    showStore(twd);
  };

  const btnApproveApply = twd => {
    approveTwdApply(twd);
  };

  const btnEnterTransferInfo = twd => {
    setOpenTransferFrom(true);
    setWaitApproveTWD(twd);
  };

  const handleApproveApply = (twd, batchNum, serialNum) => {
    // console.log({ twd, batchNum, serialNum });
    enterTransferInfo(twd, batchNum, serialNum);
  };

  return (
    <div className='relative p-3 shadow-md sm:rounded-lg'>
      {/* search */}
      <div className='flex items-center justify-between pb-4 bg-white'>
        <label htmlFor='table-search' className='sr-only'>
          Search
        </label>

        <div className='relative'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>

          <input
            type='text'
            id='table-search-users'
            className='block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
            placeholder='店家名稱'
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {/* search end */}

      {/* show state */}
      <div className='flex items-center justify-start gap-3 pb-4'>
        <Select
          required
          className='w-80'
          isSearchable={false}
          options={transferStateOptions}
          defaultValue={{ label: stateType, value: stateType }}
          onChange={({ label, value }) => setStateType(value)}
        />
      </div>

      <section className='overflow-x-auto'>
        <table className='w-full text-sm text-left text-gray-500'>
          {/* header */}
          <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
              {/* <th scope='col' className='p-4'>
                <div className='flex items-center'>
                  <input
                    id='checkbox-all-search'
                    type='checkbox'
                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'
                    onChange={handleSelectAll}
                  />
                  <label htmlFor='checkbox-all-search' className='sr-only'>
                    checkbox
                  </label>
                </div>
              </th> */}
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                時間
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                店家名稱
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                廠商資訊
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                提領總金額
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                交易手續費(2%)
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                交易手續費稅額(0.1%)
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                提領手續費
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                匯款金額
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                批次編號
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                銀行序號
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                狀態
              </th>
              <th scope='col' className='px-3 py-3 whitespace-nowrap'>
                動作
              </th>
            </tr>
          </thead>

          {/* withdraw data row */}
          <tbody>
            {listOfTWD.map((twd, id) => {
              return (
                <tr key={id} className='bg-white border-b hover:bg-gray-50'>
                  {/* 時間 */}
                  <th scope='row' className='px-3 py-4'>
                    <div>{new Date(twd.applyTime).toLocaleString()}</div>
                  </th>

                  {/* 店家名稱 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.store.remark}</div>
                  </td>

                  {/* 廠商資訊 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <button
                      type='button'
                      className='group relative py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'
                      onClick={() => btnShowStore(twd)}
                    >
                      廠商資訊
                      {/* tool tip */}
                      <p className='absolute z-10 -top-[120%] left-[0%] invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:visible group-hover:opacity-100 dark:bg-gray-700'>
                        {twd.paymentId}
                      </p>
                    </button>
                  </td>

                  {/* 提領總金額 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{new bigDecimal(twd.sum).getPrettyValue()}</div>
                  </td>

                  {/* 交易手續費(2%) */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.fee}</div>
                  </td>

                  {/* 交易手續費稅額(0.1%) */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.feeTax}</div>
                  </td>

                  {/* 提領手續費 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.withdrawFee}</div>
                  </td>

                  {/* 匯款金額 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{new bigDecimal(twd.remitTwd).getPrettyValue()}</div>
                  </td>

                  {/* 批次編號 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.batchNum}</div>
                  </td>

                  {/* 銀行序號 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.serialNum}</div>
                  </td>

                  {/* 狀態 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div>{twd.state}</div>
                  </td>

                  {/* 動作 */}
                  <td className='px-3 py-4 whitespace-nowrap'>
                    <div className='flex flex-col items-start gap-2'>
                      {twd.state === "Apply" && (
                        <button
                          className='font-medium text-blue-600 hover:underline'
                          onClick={() => btnApproveApply(twd)}
                        >
                          核准
                        </button>
                      )}

                      {twd.state === "Withdraw" && (
                        <button
                          className='font-medium text-green-600 hover:underline'
                          onClick={() => btnEnterTransferInfo(twd)}
                        >
                          匯款資訊
                        </button>
                      )}

                      <TransferInfoFormModal
                        twd={waitApproveTWD}
                        isOpen={openTransferForm}
                        setOpen={setOpenTransferFrom}
                        onConfirm={handleApproveApply}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}

function TransferInfoFormModal({ isOpen, setOpen, twd, onConfirm }) {
  if (!isOpen) return "";

  return (
    <div className='fixed inset-0 z-10 flex items-center justify-center transition-opacity bg-gray-500 bg-opacity-75'>
      <div className='p-8 bg-white rounded-xl'>
        <TransferInfoForm
          onCancle={() => setOpen(false)}
          onConfirm={(batchNum, serialNum) => {
            onConfirm(twd, batchNum, serialNum);
            setOpen(false);
          }}
        />
      </div>
    </div>
  );
}
