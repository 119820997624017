import { useState } from "react";

export default function TransferInfoForm({ onCancle, onConfirm }) {
  const [batchNum, setBatchNum] = useState("");
  const [serialNum, setSerialNum] = useState("");

  const btnCancle = () => {
    onCancle?.();
  };

  const btnConfirm = (batchNum, serialNum) => {
    onConfirm?.(batchNum, serialNum);
  };

  return (
    <form className='flex flex-col justify-center gap-5'>
      <h1 className='text-5xl font-extrabold text-center dark:text-white'>輸入匯款資訊</h1>

      <div className=''>
        <label
          htmlFor='batchNum'
          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
        >
          批次編號
        </label>

        <input
          autoFocus
          id='batchNum'
          type='integer'
          onChange={e => setBatchNum(e.target.value)}
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        />
      </div>

      <div className=''>
        <label
          htmlFor='serialNum'
          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
        >
          序號
        </label>

        <input
          id='serialNum'
          type='integer'
          onChange={e => setSerialNum(e.target.value)}
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
        />
      </div>

      <div className='flex justify-center gap-2'>
        <button
          onClick={btnCancle}
          className='text-white self-center bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'
        >
          取消
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            btnConfirm(batchNum, serialNum);
          }}
          className='text-white self-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'
        >
          完成
        </button>
      </div>
    </form>
  );
}
