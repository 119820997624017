import {
  connect,
  getAccount,
  getNetwork,
  prepareWriteContract,
  switchNetwork,
  writeContract,
} from "@wagmi/core";

/**
 * 錢包連接
 * @param {number} chainId 1 || 80001
 * @param {obj} connector
 */
export async function connectWallet(chainId, connector) {
  const { isConnected } = getAccount();
  !isConnected && (await connect({ chainId, connector }));
}

/**
 * 換鏈
 * @param {number} chainId 1 || 80001
 */
export async function switchChainId(chainId) {
  const { chain } = getNetwork();
  const isWrongChainId = chain.id !== chainId;
  isWrongChainId && (await switchNetwork({ chainId }));
}

/**
 * 合約連接
 * @param {string} address
 * @param {json} abi
 * @param {string} functionName
 * @param {[]} args
 * @returns obj
 */
export async function connectContract(address, abi, functionName, args) {
  const config = await prepareWriteContract({ address, abi, functionName, args });
  return config;
}

/**
 * 合約寫入資料
 * @param {obj} config
 * prepareWriteContract ({
 *    address: string,
 *    abi: json,
 *    functionName: string,
 *    args?: [],
 * })
 * @returns obj
 * {
 *    hash: `0x${string}`,
 *    wait: (confirmations?: number) => Promise<TransactionReceipt>
 * }
 */
export async function writeConfigToContract(config) {
  return await writeContract(config);
}

/**
 * 合約等待確認
 * @param {string} hash `0x${string}`
 * @param {function} wait wait: (confirmations?: number) => Promise<TransactionReceipt>
 */
export async function waitContract(hash, wait, confirmations = 1) {
  if (wait) {
    await wait(confirmations);
    return true;
  } else {
    console.log("you need to implement contract wait process");
    return false;
  }
}

/**
 * 操作合約的程序
 * @param {number} chainId
 * @param {obj} connector new InjectedConnector()
 * @param {function} writeCB Wagmi writeContract()
 */
export async function writeContractProcess(chainId, connector, writeCB, callback = {}) {
  const { toast } = callback;

  try {
    await connectWallet(chainId, connector);
    await switchChainId(chainId);
    const res = await writeCB?.();
    const isSuccess = await waitContract(res?.hash, res?.wait);
    isSuccess && toast?.success("transaction success");
  } catch (err) {
    toast?.error(err.message);
    console.error(err);
  }
}
