import useSWR from "swr";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

// api 2nd
import {
  getCryptoWithdrawLog,
  getTWDWithdrawLog,
  submitTransferInfo,
} from "../api/BackerpayApi_2nd";

// api
import { errorTokenHasExpired } from "../api/ErrorHelper";
import { approveTWDWithdraw } from "../api/Web3Api";

// context
import { useAdminAuth } from "../context/AdminAuthContext";

// components
import DefaultTabs from "../components/Tab/DefaultTabs";
import Form from "../components/Form";
import CryptosTable from "../components/Table/CryptosTable";
import TWDsTable from "../components/Table/TWDsTable";
import Spinner from "../components/Spinner/Spinner";
import Pagination from "../components/Pagination";

export default function ApproveWithdraw() {
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const { admin, dispatch } = useAdminAuth();
  const logout = () => dispatch({ type: "logout" });

  // fetch data
  const [storeInfo, setStoreInfo] = useState(null);

  // tab
  const [tab] = useState({ 法幣: "twd", 加密貨幣: "crypto" });
  const [currentTab, setCurrentTab] = useState("twd");

  /**
   * 顯示商家資訊
   * @param {obj} twd from API getTWDWithdrawLog() --> twd[]
   */
  const showStore = twd => {
    const { chainName, store, clientOperator, clientAccount } = twd;

    // 轉換成 Form 可讀的形式
    const formData = [];

    // store
    store?.remark && formData.push({ label: "店家名稱", defaultValue: store?.remark });
    store?.name && formData.push({ label: "公司名稱", defaultValue: store?.name });
    store?.email && formData.push({ label: "公司信箱", defaultValue: store?.email });
    store?.url && formData.push({ label: "公司網址", defaultValue: store?.url });
    store?.tel && formData.push({ label: "公司電話", defaultValue: store?.tel });
    store?.contactPerson && formData.push({ label: "聯絡人", defaultValue: store?.contactPerson });
    store?.contactTel && formData.push({ label: "聯絡人電話", defaultValue: store?.contactTel });
    store?.bankName && formData.push({ label: "銀行名稱", defaultValue: `${store?.bankCode} ${store?.bankName}` });
    store?.bankAccount && formData.push({ label: "銀行帳戶", defaultValue: store?.bankAccount });
    // account
    clientOperator && formData.push({ label: "操作地址", defaultValue: clientOperator });
    clientAccount && formData.push({ label: "收款地址", defaultValue: clientAccount });
    // chain name
    chainName && formData.push({ label: "鏈", defaultValue: chainName });

    setStoreInfo(formData);
  };

  /**
   * 輸入匯款資訊
   * @param {obj} twd from API getTWDWithdrawLog() --> twd[]
   * @param {string} batchNum
   * @param {string} serialNum
   */
  const enterTransferInfo = async (twd, batchNum, serialNum) => {
    // 行為:
    // 1. 會計到銀行匯款
    // 2. 填入匯款批次編號和序號

    if (isLoading) return;

    const { paymentId, withdrawTxnHash } = twd;
    const data = { paymentId, withdrawTxnHash, batchNum, serialNum };

    // console.log({twd, batchNum, serialNum});

    try {
      setIsLoading(true);

      await submitTransferInfo(admin.token, data);
      mutateTWD();
      toast.success("update success");

      // errors
    } catch (err) {
      toast.error(err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 核准台幣出金
   * @param {obj} twd from API getTWDWithdrawLog() --> twd[]
   */
  const approveTWDApply = async twd => {
    if (isLoading) return;

    const { paymentId } = twd;

    try {
      setIsLoading(true);

      await approveTWDWithdraw(admin.token, paymentId);
      mutateTWD();

      // errors
    } catch (err) {
      toast.error(err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * API 2nd version
   * TWD withdraw logs
   */
  const [pageOfTWD, setPageOfTWD] = useState(1);
  const [pageSizeOfTWD] = useState(5);
  const [stateOfTWD, setStateOfTWD] = useState("Apply"); // 'Apply' || 'Remit' || 'Withdraw'
  const [listOfTWD, setListOfTWD] = useState([]);
  const [totalTWD, setTotalTWD] = useState(0); // total logs of TWD

  const { isLoading: isLoadingTWD, mutate: mutateTWD } = useSWR(
    // arguments
    ["/getTWDWithdrawLog", admin.token, pageOfTWD.toString(), pageSizeOfTWD.toString(), stateOfTWD],

    // fetcher
    ([fakeURL, token, page, pageSize, states]) =>
      getTWDWithdrawLog(token, { page, pageSize, states }),
    {
      // handle data
      onSuccess(data) {
        setListOfTWD(data.results);
        setTotalTWD(data.total);
      },

      // handle error
      onError(err) {
        const expiredError = errorTokenHasExpired(err, [
          () => toast.error("Fetch TWD logs fail \nToken has expired \nLogout after 3 seconds"),
          () => setTimeout(() => logout(), 3000),
        ]);

        !expiredError && toast.error(err.message);
        console.error(err);
      },
    }
  );

  /**
   * API 2nd version
   * Crypto withdraw logs
   */
  const [pageOfCrypto, setPageOfCrypto] = useState(1);
  const [pageSizeOfCrypto] = useState(5);
  const [listOfCrypto, setListOfCrypto] = useState([]);
  const [totalCrypto, setTotalCrypto] = useState(0); // total logs of Crypto

  const { isLoading: isLoadingCrypto } = useSWR(
    // arguments
    ["/getCryptoWithdrawLog", admin.token, pageOfCrypto.toString(), pageSizeOfCrypto.toString()],

    // fetcher
    ([fakeURL, token, page, pageSize]) => getCryptoWithdrawLog(token, { page, pageSize }),
    {
      // handle data
      onSuccess(data) {
        setListOfCrypto(data.results);
        setTotalCrypto(data.total);
      },

      // handle error
      onError(err) {
        const expiredError = errorTokenHasExpired(err, [
          () => toast.error("Fetch Crypto logs fail \nToken has expired \nLogout after 3 seconds"),
          () => setTimeout(() => logout(), 3000),
        ]);

        !expiredError && toast.error(err.message);
        console.error(err);
      },
    }
  );

  /**
   *    顯示商家資訊
   */
  if (storeInfo)
    return (
      <div className='flex flex-col justify-center p-5 mx-auto '>
        <Form datas={storeInfo} />

        <button
          autoFocus
          type='button'
          className='text-white mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none'
          onClick={() => setStoreInfo(null)}
        >
          退出
        </button>
      </div>
    );

  return (
    <div className='p-5'>
      <div>
        <Toaster />
      </div>

      <Spinner isLoading={isLoading || isLoadingTWD || isLoadingCrypto} />

      {/* Header */}
      <h1 className='text-5xl font-extrabold text-center dark:text-white'>出金查閱</h1>

      {/* Tabs */}
      <DefaultTabs tab={tab} currentTab={currentTab} setCurrentTab={setCurrentTab} />

      {/* Cryptos */}
      {currentTab === "crypto" && (
        <div className='space-y-2'>
          <CryptosTable listOfCrypto={listOfCrypto} showStore={showStore} />
          <Pagination
            currentPage={pageOfCrypto}
            setCurrentPage={setPageOfCrypto}
            contentsPerPage={pageSizeOfCrypto}
            totalContents={totalCrypto}
          />
        </div>
      )}

      {/* TWDs */}
      {currentTab === "twd" && (
        <div className='space-y-2'>
          <TWDsTable
            listOfTWD={listOfTWD}
            stateType={stateOfTWD}
            setStateType={setStateOfTWD}
            showStore={showStore}
            enterTransferInfo={enterTransferInfo}
            approveTwdApply={approveTWDApply}
          />
          <Pagination
            currentPage={pageOfTWD}
            setCurrentPage={setPageOfTWD}
            contentsPerPage={pageSizeOfTWD}
            totalContents={totalTWD}
          />
        </div>
      )}
    </div>
  );
}
