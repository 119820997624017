import React from "react";
import axios from "axios";
import { Modal, Button } from "flowbite-react";

// hooks
import { useAdminAuth } from "../context/AdminAuthContext";

// components
import Kyc3wTable from "../components/Kyc3wTable";
import Edit3wKyc from "../components/Edit3wKyc";

// urls
const URL_KYC_USERS = process.env.REACT_APP_BACKERPAY_SERVER_URL + "/platformapi/user";
const URL_EDIT_KYC = process.env.REACT_APP_BACKERPAY_SERVER_URL + "/platformapi/edit_user_kyc";

/**
 *      dispatch
 */
const reducer = (state, action) => {
  switch (action.type) {
    // create
    case "create_kyc3ws":
      // console.log(action.payload);
      return { ...state, kyc3wDatas: action.payload };
    // update kyc data
    case "update_kyc":
      const user = action.payload;
      const _kyc3wDatas = state.kyc3wDatas.map(kyc => {
        if (kyc.id === user.id) return user;
        return kyc;
      });
      return { ...state, kyc3wDatas: _kyc3wDatas };
    // approve all
    case "approve_all_kyc":
      return { ...state, kycData: action.payload };
    // edit
    case "open_edit":
      return { ...state, isOpenEdit: true, kycData: action.payload };
    case "close_edit":
      return { ...state, isOpenEdit: false, kycData: {} };

    case "send_reject_message":
      return { ...state, rejectMsg: action.payload };
    default:
      throw new Error("action type error!!!");
  }
};

export default function Approve3wKyc() {
  const [successMsg, setSuccessMsg] = React.useState("");

  const { admin } = useAdminAuth();
  const [errorMsg, setErrorMsg] = React.useState("");

  const [state, dispatch] = React.useReducer(reducer, {
    kyc3wDatas: [],
    isOpenEdit: false,
    kycData: {},
    rejectMsg: "",
  });

  /**
   * approve or reject kyc user
   * @param {'approve_kyc || reject_kyc'} type
   * @param { kyc user data } kycData
   */
  const editKycData = async (type, kycData) => {
    if (!type) return;

    if (type === "edit") {
    }
    if (type === "update") {
      // kycData.remark = "用戶資料更新";
      // console.log(kycData);
    }
    if (type === "approve_kyc") {
      // kycData.risk = "低";
      // kycData.remark = "用戶資料正常";
      kycData.status = "approved";
    }
    if (type === "reject_kyc") {
      // kycData.risk = "高";
      kycData.status = "rejected";
    }

    console.log(kycData);

    try {
      const response = await axios.post(
        URL_EDIT_KYC,
        {
          ...kycData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      if (response?.data?.op_code === "1") {
        dispatch({
          type: "update_kyc",
          payload: { ...kycData },
        });
      } else {
        console.log(response.data);
        throw new Error(response?.data?.op_msg);
      }
    } catch (err) {
      setErrorMsg(err?.message);
      console.error(err);
    }
  };

  /**
   *    fetch kyc3wDatas
   */
  React.useEffect(() => {
    const controller = new AbortController();

    const getUnapprovedVendors = async () => {
      try {
        const response = await axios.post(
          URL_KYC_USERS,
          {
            email: "all",
          },
          {
            signal: controller.signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${admin.token}`,
            },
          }
        );

        if (response?.data?.op_code === "1") {
          // console.log(response.data.items);
          dispatch({ type: "create_kyc3ws", payload: response.data.items });
        } else if (response?.data?.op_code === "-1") {
          console.log("token expired!");
        } else {
          throw new Error(response?.data?.op_msg);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("cancel post");
        } else {
          if (err?.message) {
            setErrorMsg(err?.message);
          }
          console.error(err);
        }
      }
    };
    getUnapprovedVendors();

    // const getMockData = async () => {
    //   const response = await axios.get("http://localhost:8000/kyc3ws");
    //   dispatch({ type: "create_kyc3ws", payload: response.data });
    // };
    // getMockData();

    return () => {
      controller.abort();
    };
  }, [admin.token]);

  return (
    <div className='p-5'>
      {/* error message */}
      <Modal show={errorMsg} size='md' popup={true} onClose={() => setErrorMsg("")}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {errorMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={() => setErrorMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success message */}
      <Modal show={successMsg} size='md' popup={true} onClose={() => setSuccessMsg("")}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {successMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='success' onClick={() => setSuccessMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <h1 className='text-5xl font-extrabold text-center dark:text-white'>核准 3W 會員</h1>

      {!state.isOpenEdit ? (
        <Kyc3wTable kyc3wDatas={state.kyc3wDatas} dispatch={dispatch} editKycData={editKycData} />
      ) : (
        <Edit3wKyc kycData={state.kycData} dispatch={dispatch} editKycData={editKycData} />
      )}

      {/* <p className='p-2 font-bold text-center roundedbg-red-200 text-rose-700'>{errorMsg}</p> */}
    </div>
  );
}
