import React from "react";
import Select from "react-select";
// import { Button } from "flowbite-react";

/**
 * options
 */
const approvedOptions = [
  { label: "all", value: "all" },
  { label: "approved", value: "approved" },
  { label: "not approved", value: "not approved" },
  { label: "rejected", value: "rejected" },
];

export default function Kyc3wTable({ kyc3wDatas, dispatch, editKycData }) {
  // const [kyc3wSelecteds, setKyc3wSelecteds] = React.useState([]); // [1003, 1004]
  const [searchText, setSearchText] = React.useState("");
  const [showApproved, setShowApproved] = React.useState("all"); // default is "all"

  /**
   *    按鈕 - 編輯
   */
  const handleEdit = async kycData => {
    // console.log(kycData);
    dispatch({ type: "open_edit", payload: kycData });
  };

  /**
   *    按鈕 - 核准
   */
  // const approveBtn = async kycData => {
  //   // console.log(kycData);
  //   // dispatch({ type: "approve_kyc", payload: kycData });
  //   editKycData("approve_kyc", kycData);
  // };

  /**
   *    按鈕 - 全部核准
   */
  // const approveAllBtn = async () => {
  //   const kycDatas = kyc3wDatas.filter(kyc => kyc3wSelecteds.includes(kyc.id));
  //   // console.log(kycDatas);
  //   dispatch({ type: "approve_all_kyc", payload: kycDatas });
  // };

  /**
   *    按鈕 - 退件
   */
  // const rejectBtn = async kycData => {
  //   // dispatch({ type: "reject_kyc", payload: kycData });
  //   editKycData("reject_kyc", kycData);
  // };

  /**
   *    勾選 - 全部選取 | 全部取消
   */
  // const handleSelectAll = e => {
  //   if (!e.target.checked) return setKyc3wSelecteds([]);

  //   const kycIds = kyc3wDatas.map(kyc => kyc.id);
  //   setKyc3wSelecteds(kycIds);
  // };

  /**
   *    search
   */
  const isSearched = (searchText, source) => {
    // if searchText is '', then re.test() return true
    const re = new RegExp(searchText, "ig");
    return re.test(source);
  };

  return (
    // 3w kyc kycData table
    <div className='relative shadow-md sm:rounded-lg p-3'>
      {/* search */}
      <div className='flex justify-between items-center pb-4 bg-white'>
        <label htmlFor='table-search' className='sr-only'>
          Search
        </label>
        <div className='relative'>
          <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            type='text'
            id='table-search-users'
            className='block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
            placeholder='聯絡人 | 信箱 | 手機'
            onChange={e => setSearchText(e.target.value)}
          />
        </div>

        {/* <Button onClick={approveAllBtn}>全部核准</Button> */}
      </div>
      {/* search end */}

      {/* show approved state */}
      <div className='flex justify-start items-center gap-3 pb-4'>
        <Select
          className='w-80'
          required
          isSearchable={false}
          defaultValue={approvedOptions[0]}
          options={approvedOptions}
          onChange={({ label, value }) => {
            setShowApproved(value);
            // console.log(value);
          }}
        />
      </div>

      <section className='overflow-x-auto'>
        <table className='w-full text-sm text-left text-gray-500'>
          {/* header */}
          <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
              {/* <th scope='col' className='p-4'>
                <div className='flex items-center'>
                  <input
                    id='checkbox-all-search'
                    type='checkbox'
                    className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500'
                    onChange={handleSelectAll}
                  />
                  <label htmlFor='checkbox-all-search' className='sr-only'>
                    checkbox
                  </label>
                </div>
              </th> */}
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                時間
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                姓名
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                聯絡方式
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                資料
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                國籍
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                雙重國籍
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                風險
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                狀態
              </th>
              <th scope='col' className='py-3 px-3 whitespace-nowrap'>
                動作
              </th>
            </tr>
          </thead>

          {/* kycData data row */}
          <tbody>
            {kyc3wDatas?.map((kycData, index) => {
              // search function
              const isFound =
                isSearched(searchText, kycData.email) ||
                isSearched(searchText, kycData.name) ||
                isSearched(searchText, kycData.phone);
              if (!isFound) return "";
              if (showApproved === "approved" && kycData?.status !== "approved") return "";
              if (showApproved === "not approved" && kycData?.status !== "not approved") return "";
              if (showApproved === "rejected" && kycData?.status !== "rejected") return "";

              return (
                <tr key={kycData.id} className='bg-white border-b hover:bg-gray-50'>
                  {/* check box */}
                  {/* <td className='p-4 w-4'>
                    <div className='flex items-center'>
                      <input
                        id='checkbox-table-search-1'
                        type='checkbox'
                        className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500'
                        checked={kyc3wSelecteds.includes(kycData.id)}
                        onChange={e => {
                          if (e.target.checked) {
                            setKyc3wSelecteds(prev => [...prev, kycData.id]);
                          } else {
                            const selecteds = kyc3wSelecteds.filter(kycId => kycId !== kycData.id);
                            setKyc3wSelecteds(selecteds);
                          }
                        }}
                      />
                      <label htmlFor='checkbox-table-search-1' className='sr-only'>
                        checkbox
                      </label>
                    </div>
                  </td> */}

                  {/* 時間 */}
                  <th scope='row' className='py-4 px-3'>
                    <div>{kycData.createDate}</div>
                  </th>

                  {/* 會員姓名 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    <div>{kycData.name}</div>
                  </td>

                  {/* 聯絡方式 */}
                  <th scope='row' className='py-4 px-3 whitespace-nowrap'>
                    {/* <div className='text-base text-gray-900 font-semibold'>
                      {kycData.companyName}
                    </div> */}
                    <div className='font-normal'>{kycData.email}</div>
                    <div className='font-normal'>{kycData.phone}</div>
                  </th>

                  {/* 資料 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    {/* <div className='text-base text-gray-900 font-semibold'>
                      {kycData.contactPerson}
                    </div> */}
                    <div className='font-normal text-gray-500'>{kycData.birthDay}</div>
                    <div className='font-normal text-gray-500'>{kycData.idNumber}</div>
                  </td>

                  {/* 國籍 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>{kycData.nationality}</div>
                  </td>

                  {/* 雙重國籍 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>
                      {kycData.isDoubleNationality === "1" ? "是" : "否"}
                    </div>
                  </td>

                  {/* 風險 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    <div className='font-normal text-gray-500'>{kycData.risk}</div>
                  </td>

                  {/* 狀態 */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    {kycData.status === "approved" && (
                      <div className='flex items-center'>
                        <span className='h-2.5 w-2.5 rounded-full bg-green-400 mr-2'></span>approved
                      </div>
                    )}
                    {kycData.status === "not approved" && (
                      <div className='flex items-center'>
                        <span className='h-2.5 w-2.5 rounded-full bg-red-500 mr-2'></span>not
                        approved
                      </div>
                    )}
                    {kycData.status === "rejected" && (
                      <div className='flex items-center'>
                        <span className='h-2.5 w-2.5 rounded-full bg-yellow-500 mr-2'></span>
                        rejected
                      </div>
                    )}
                  </td>

                  {/* buttons */}
                  <td className='py-4 px-3 whitespace-nowrap'>
                    <div className='flex flex-col gap-2 justify-center items-center'>
                      <button
                        className='font-medium block text-blue-600 hover:underline'
                        onClick={() => handleEdit(kycData)}
                      >
                        編輯
                      </button>
                      {/* {kycData.status === "not approved" && (
                        <button
                          className='font-medium text-green-600 hover:underline'
                          onClick={() => approveBtn(kycData)}
                        >
                          核准
                        </button>
                      )}
                      {kycData.status === "not approved" && (
                        <button
                          className='font-medium text-rose-600 hover:underline'
                          onClick={() => rejectBtn(kycData)}
                        >
                          退件
                        </button>
                      )} */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}
