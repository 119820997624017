import React from "react";

import { Modal, Button } from "flowbite-react";

// api
import backerpayApi from "../../api/BackerpayApi";

// utils
import { mergeTwoBy } from "../../utils/mergeTools";

// hooks
import { useAdminAuth } from "../../context/AdminAuthContext";

// components
import NewcoinUnapprovedVendorsTable from "../../components/NewcoinUnapprovedVendorsTable";

// urls
const URL_VENDORS = "/platformapi/vendors";

const reducer = (state, action) => {
  switch (action.type) {
    case "create_vendors":
      return { ...state, vendors: action.payload };
    case "update_vendor":
      const updateVendor = action.payload;
      const vendors = state.vendors.map(vendor => {
        if (vendor.storeId === updateVendor.storeId) return updateVendor;
        else return vendor;
      });
      return { ...state, vendors: vendors };
    case "refresh_unapproved_vendors":
      return { ...state, unapprovedVendors: action.payload };
    default:
      throw new Error("action.type error!!!");
  }
};

export default function ApproveNewcoinVendor() {
  // error message
  const [errorMsg, setErrorMsg] = React.useState("");

  // user
  const { admin } = useAdminAuth();

  const [state, dispatch] = React.useReducer(reducer, {
    vendors: [],
    unapprovedVendors: [],
    msgReject: "",
  });

  /**
   *    fetch unapproved vendors
   */
  React.useEffect(() => {
    const controller = new AbortController();

    const getVendors = async type => {
      // type:
      // 1: 已驗證，2: 未驗證

      try {
        const response = await backerpayApi.post(
          URL_VENDORS,
          { type },
          {
            signal: controller.signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${admin.token}`,
            },
          }
        );
        if (response?.data?.op_code === "1") {
          return response?.data?.results;
        } else {
          throw new Error(response?.data?.op_msg);
        }
      } catch (err) {
        if (err?.name === "CanceledError") {
          console.log("cancel");
        } else {
          setErrorMsg(err?.message);
          console.error(err);
        }
      }
    };

    const createVendors = async () => {
      const waitApproveds = getVendors(1);
      const waitNotApproveds = getVendors(2);
      const approveds = (await waitApproveds) || [];
      const notApproveds = (await waitNotApproveds) || [];
      const vendors = mergeTwoBy("storeId")(approveds, notApproveds);
      dispatch({ type: "create_vendors", payload: vendors });
    };

    admin.token && createVendors();

    return () => {
      controller.abort();
    };
  }, [admin.token]);

  return (
    <div className='p-5'>
      {/* error message */}
      <Modal show={errorMsg} size='md' popup={true}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {errorMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={() => setErrorMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <h1 className='text-5xl font-extrabold text-center dark:text-white'>核准新思幣廠商</h1>

      <NewcoinUnapprovedVendorsTable user={admin} vendors={state.vendors} dispatch={dispatch} />
    </div>
  );
}
