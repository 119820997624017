import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// hooks
import useLoginVendor from "../../hooks/useLoginVendor";

export default function LoginVendor() {
  const navigate = useNavigate();
  const { login, error, isLoading } = useLoginVendor();

  const location = useLocation(); // 如果剛註冊完，會直接傳值過來
  const from = location?.state?.from || "/vendor/list-store";
  const [email, setEmail] = useState(location?.state?.email || "");
  const [password, setPassword] = useState(location?.state?.password || "");

  const onRedirect = () => {
    navigate("/vendor/register-vendor");
  };

  const onLogin = async e => {
    e.preventDefault();
    const vendor = await login(email, password);
    vendor && navigate(from);
  };

  return (
    <div>
      <h1 className='mt-10 mb-5 text-4xl font-bold text-center md:text-left md:ml-10 md:mb-0'>
        店家
      </h1>

      <form className='max-w-md px-4 mx-auto space-y-3'>
        <h2 className='text-3xl font-bold text-center'>登入</h2>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='email'>
            Email
          </label>
          <input
            className='rounded-2xl'
            id='email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='password'>
            密碼
          </label>
          <input
            className='rounded-2xl'
            id='password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <br />

        <div className='flex flex-row-reverse justify-between'>
          <button
            className='py-2.5 px-7 text-3xl font-bold rounded-xl text-white bg-[#2010D6] disabled:bg-gray-500'
            disabled={isLoading}
            onClick={onLogin}
          >
            確認
          </button>
          <button
            className='py-2.5 text-3xl font-bold rounded-xl text-[#2010D6]'
            onClick={onRedirect}
          >
            註冊店家
          </button>
        </div>

        {/* error message */}
        {error && (
          <div className='p-2 font-bold text-center bg-red-200 rounded text-rose-700'>
            <p>{error}</p>
          </div>
        )}
      </form>
    </div>
  );
}
