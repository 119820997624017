import "tw-elements";

import useSWR from "swr";
import { ethers } from "ethers";
import Select from "react-select";
import { Modal, Button } from "flowbite-react";
import React, { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import taiwanBankCode from "../data/taiwanBankCode.json";

// hooks
import { useVendorAuth } from "../context/VendorAuthContext";

// apis
import { createStore, getBackerpayColdWallet, getSupportedChains } from "../api/BackerpayApi_2nd";
import { errorTokenHasExpired } from "../api/ErrorHelper";

/**
 *    css style
 */
const cssInput = `form-control
    block
    w-full
    m-0
    pl-3 py-1.5
    text-base text-gray-700
    font-normal
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition ease-in-out
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`;
// const cssSelect = `form-select
//     appearance-none
//     block
//     w-full
//     m-0
//     px-3 py-1.5
//     text-base text-gray-700
//     font-normal
//     bg-white bg-clip-padding bg-no-repeat
//     border border-solid border-gray-300
//     rounded
//     transition ease-in-out
//     focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`;

/**
 *    bank options
 */
const bankCodeOptions = taiwanBankCode.map(bank => ({
  label: `${bank.code} - ${bank.name}`,
  value: { code: bank.code, name: bank.name },
}));

// 不顯示的 tokens
const BlockTokens = [
  "0x0000000000000000000000000000000000000000", // 原生幣
  process.env.REACT_APP_NEWCOIN_CONTRACT_ADDRESS, // 新思幣合約
];

/**
 *      建立店家
 */
export default function CreatePayment() {
  const [successMsg, setSuccessMsg] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");

  const { vendor } = useVendorAuth();
  const [state, dispatch] = React.useReducer(reducer, {
    //  新版 API 使用的參數
    withdrawType: "", // "TWD",
    // bank
    bankAcct: "", // "5522..."
    bankCode: "", // "123",
    bankName: "", // "bank",
    //  company
    companyTel: "", // "0912345678",
    companyUrl: "", // "www.test.com"
    companyName: "", // "test"
    companyRemark: "", // "西門店"
    companyAddress: "", // "taipei",
    // contact
    contactTel: "", // "0912345678",
    contactEmail: "", // "test@gmail.com"
    contactPerson: "", // "frank",
    // crypto
    tokens: [],
    unionId: "", // "F0U0",
    chainName: "", // "polygon"
    clientAcct: "", // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
    clientOper: "", // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
  });

  /**
   * 取得貝殼支付冷錢包
   */
  const [bpWallet, setBpWallet] = useState("");
  useSWR("/getBackerpayColdWallet", getBackerpayColdWallet, {
    onSuccess(data) {
      if (bpWallet) return;
      setBpWallet(data);
    },
    onError(err) {
      toast.error(err.message);
      console.error(err);
    },
  });

  /**
   * 取得支援的鏈
   */
  const [supportedChains, setSupportedChains] = useState([]);
  useSWR("/getSupportedChains", getSupportedChains, {
    onSuccess(data) {
      if (supportedChains.length > 0) return;
      setSupportedChains(data);
      handleChainOptions(data);
    },
    onError(err) {
      toast.error(err.message);
      console.error(err);
    },
  });

  /**
   * 設定 chainName 選項
   */
  const [chainOptions, setChainOptions] = useState([]);
  const handleChainOptions = chains => {
    const options = chains.map(chain => ({ label: chain.chainName, value: chain }));
    setChainOptions(options);
    handleUnionOptions([]); // 重設 unionId 選項
    handleTokenOptions([]); // 重設 tokens 選項
    handleInput("clear_chainName"); // 清除之前選的
  };

  /**
   * 設定 unionId 選項
   */
  const unionOptionsRef = useRef();
  const [unionOptions, setUnionOptions] = useState([]);
  const handleUnionOptions = unions => {
    const options = unions.map(union => ({
      label: `${union.unions} - ${union.type}`,
      value: union,
    }));
    setUnionOptions(options);
    handleInput("clear_unionId"); // 清除之前選的
    unionOptionsRef.current.clearValue(); // 清除螢幕顯示
  };

  /**
   * 設定 tokens 選項
   */
  const tokenOptionsRef = useRef();
  const [tokenOptions, setTokenOptions] = useState([]);
  const handleTokenOptions = tokens => {
    const options = tokens
      .filter(token => !BlockTokens.includes(token.tokenAddr))
      .map(token => ({ label: `${token.tokenSymbol} - ${token.tokenName}`, value: token }));
    setTokenOptions(options);
    handleInput("clear_tokens"); // 清除之前選的
    tokenOptionsRef.current.clearValue(); // 清除螢幕顯示
  };

  /**
   * handle input
   */
  const handleInput = (type, payload) => {
    dispatch({ type, payload });
  };

  /**
   * handle submit
   */
  const handleSubmit = async e => {
    e.preventDefault();

    const formData = handleSubmitData();
    console.log("form data:", formData);

    const isError = handleErrorHint();
    if (isError) return;

    // return

    try {
      await createStore(vendor.token, formData);
      toast.success("create success");

      // errors
    } catch (err) {
      const expiredError = errorTokenHasExpired(err, [
        () => toast.error("Token has expired \nLogout after 3 seconds"),
        // () => setTimeout(() => logout(), 3000),
      ]);

      !expiredError && toast.error(err.message);
      console.error(err);
    }
  };

  const handleSubmitData = () => {
    const data = {
      store: {
        bankAcct: state.bankAcct, // "123456789",
        bankCode: state.bankCode, // "123",
        bankName: state.bankName, // "bank",
        companyAddress: state.companyAddress, // "taipei",
        contactPerson: state.contactPerson, // "frank",
        contactTel: state.contactTel, // "0912345678",
        email: state.contactEmail, // "frankwang95174@gmail.com",
        name: state.companyName, // "test store",
        remark: state.companyRemark, // "test100",
        tel: state.companyTel, // "02-22556677",
        url: state.companyUrl, // "https://www.google.com",
      },
      paymentTmp: {
        unionId: state.unionId, // "F0U0",
        clientAcct: state.clientAcct, // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        clientOper: state.clientOper, // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        tokens: state.tokens,
        // [
        //   "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
        //   "0xE322f5a2981e9Fb8aa2c9816Bbc05E422C228CC5",
        // ],
        withdrawType: state.withdrawType, // "TWD",
      },
    };

    return data;
  };

  /**
   * 錯誤訊息顯示
   */
  const [inputErrMsg, setInputErrMsg] = useState({});
  const handleErrorHint = () => {
    // return this value
    let isError = false;
    // initial
    setInputErrMsg({});

    const { chainName, unionId, tokens, withdrawType, bankCode } = state;

    const showRequired = label => {
      setInputErrMsg(pre => ({ ...pre, [label]: "required" }));
      isError = true;
    };

    !chainName && showRequired("chainName");

    !unionId && showRequired("unionId");

    tokens.length === 0 && showRequired("tokens");

    !withdrawType && showRequired("withdrawType");

    withdrawType === "TWD" && !bankCode && showRequired("bankCode");

    return isError || handleInvalidAddress();
  };

  const handleInvalidAddress = () => {
    let isError = false;

    const { clientAcct, clientOper } = state;

    const showInvalidAddress = label => {
      setInputErrMsg(pre => ({ ...pre, [label]: "invalid address" }));
      isError = true;
    };

    clientAcct && !ethers.utils.isAddress(clientAcct) && showInvalidAddress("clientAcct");
    clientOper && !ethers.utils.isAddress(clientOper) && showInvalidAddress("clientOper");

    return isError;
  };

  return (
    <div className='w-full max-w-[600px] mx-auto my-5 p-5 rounded-lg bg-[#f1f1f1]'>
      <div>
        <Toaster />
      </div>

      {/* error message */}
      <Modal show={errorMsg} size='md' popup={true}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {errorMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={() => setErrorMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success message */}
      <Modal show={successMsg} size='md' popup={true}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            {/* <HiOutlineExclamationCircle className='mx-auto mb-4 text-gray-400 h-14 w-14 dark:text-gray-200' /> */}
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              {successMsg}
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='success' onClick={() => setSuccessMsg("")}>
                確認
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <h1 className='text-2xl font-bold text-center'>建立支付店家</h1>

      <form onSubmit={handleSubmit}>
        <section className='flex flex-col gap-3 my-3'>
          {/* group 1 */}
          <div className='flex gap-3'>
            {/* 鏈 */}
            <div className='basis-1/2'>
              <label htmlFor='chainId' className='inline-block mb-2 text-gray-700 form-label'>
                Chain Id
              </label>
              <span className='ml-5 text-red-500'>{inputErrMsg.chainName}</span>

              <Select
                id='chainId'
                isClearable={true}
                isSearchable={false}
                options={chainOptions}
                onChange={o => {
                  if (!o) {
                    handleChainOptions(supportedChains);
                    return;
                  }

                  const chainName = o.label;
                  const { unions, tokens } = o.value;
                  handleInput("create_chainName", chainName);
                  handleUnionOptions(unions);
                  handleTokenOptions(tokens);
                }}
              />
            </div>

            {/* 聯盟 */}
            <div className='basis-1/2'>
              <label htmlFor='unionId' className='inline-block mb-2 text-gray-700 form-label'>
                Union ID
              </label>
              <span className='ml-5 text-red-500'>{inputErrMsg.unionId}</span>

              <Select
                id='unionId'
                isSearchable={false}
                ref={unionOptionsRef}
                options={unionOptions}
                onChange={o => {
                  if (!o) return;

                  // const label = o.label;
                  const union = o.value;
                  // console.log("label:", label);
                  // console.log("value:", value);
                  handleInput("create_unionId", union.unions);
                }}
              />
            </div>
          </div>

          {/* 幣別 */}
          <div>
            <label htmlFor='tokens' className='inline-block mb-2 text-gray-700 form-label'>
              支付幣種
            </label>
            <span className='ml-5 text-red-500'>{inputErrMsg.tokens}</span>

            <Select
              id='tokens'
              isMulti
              isSearchable={false}
              ref={tokenOptionsRef}
              options={tokenOptions}
              onChange={array => {
                const tokens = array.map(o => {
                  // const label = o.label
                  const token = o.value;
                  return token.tokenAddr;
                });

                // console.log(tokens)
                handleInput("create_tokens", tokens);
              }}
            />
          </div>

          {/* 聯絡人資料 */}
          <div className='flex gap-3'>
            <div className='flex-grow'>
              <label
                htmlFor='contact_person'
                className='inline-block mb-2 text-gray-700 form-label'
              >
                聯絡人
              </label>
              <input
                required
                id='contact_person'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_contactPerson", e.target.value)}
              />
            </div>

            <div className='flex-grow'>
              <label htmlFor='contact_tel' className='inline-block mb-2 text-gray-700 form-label'>
                聯絡人電話
              </label>
              <input
                required
                id='contact_tel'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_contactTel", e.target.value)}
              />
            </div>
          </div>

          {/* 聯絡人資料 */}
          <div className='flex gap-3'>
            <div className='flex-grow'>
              <label htmlFor='contact_email' className='inline-block mb-2 text-gray-700 form-label'>
                聯絡人信箱
              </label>
              <input
                required
                id='contact_email'
                type='email'
                className={cssInput}
                onChange={e => handleInput("create_contactEmail", e.target.value)}
              />
            </div>
          </div>

          {/* 公司資料 */}
          <div className='flex gap-3'>
            <div className='flex-grow'>
              <label htmlFor='company_name' className='inline-block mb-2 text-gray-700 form-label'>
                公司名稱
              </label>
              <input
                required
                id='company_name'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_companyName", e.target.value)}
              />
            </div>

            <div className='flex-grow'>
              <label
                htmlFor='company_remark'
                className='inline-block mb-2 text-gray-700 form-label'
              >
                店家名稱
              </label>
              <input
                required
                id='company_remark'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_companyRemark", e.target.value)}
              />
            </div>
          </div>

          {/* 公司資料 */}
          <div className='flex flex-col gap-3 sm:flex-row'>
            <div className='flex-grow'>
              <label htmlFor='company_url' className='inline-block mb-2 text-gray-700 form-label'>
                公司網址
              </label>
              <input
                required
                id='company_url'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_companyUrl", e.target.value)}
              />
            </div>

            <div className='flex-grow'>
              <label htmlFor='company_tel' className='inline-block mb-2 text-gray-700 form-label'>
                公司電話
              </label>
              <input
                required
                id='company_tel'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_companyTel", e.target.value)}
              />
            </div>
          </div>

          {/* 公司資料 */}
          <div className='flex flex-col gap-3 sm:flex-row'>
            <div className='flex-grow'>
              <label
                htmlFor='company_address'
                className='inline-block mb-2 text-gray-700 form-label'
              >
                公司地址
              </label>
              <input
                required
                id='company_address'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_companyAddress", e.target.value)}
              />
            </div>
          </div>

          {/* 錢包操作地址 */}
          <div className='flex flex-col gap-3 sm:flex-row'>
            <div className='flex-grow'>
              <label htmlFor='clientOper' className='inline-block mb-2 text-gray-700 form-label'>
                錢包操作地址
              </label>
              <span className='ml-5 text-red-500'>{inputErrMsg.clientOper}</span>

              <input
                required
                id='clientOper'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_clientOper", e.target.value)}
              />
            </div>
          </div>

          {/* 出金方式 */}
          <div className=''>
            <label htmlFor='withdraw_type' className='inline-block mb-2 text-gray-700 form-label'>
              出金方式
            </label>
            <span className='ml-5 text-red-500'>{inputErrMsg.withdrawType}</span>

            <Select
              id='withdraw_type'
              isSearchable={false}
              menuPlacement={"top"}
              required={true}
              options={[
                { label: "台幣", value: "TWD" },
                { label: "加密貨幣", value: "Crypto" },
              ]}
              onChange={o => {
                if (!o) return;
                // const label = o.label;
                const value = o.value;
                // console.log("label:", label);
                // console.log("value:", value);
                handleInput("create_withdrawType", value);
                if (value === "Crypto") handleInput("delete_bank"); // 加密貨幣出金，要清掉銀行帳戶資料
                if (value === "TWD") handleInput("delete_crypto"); // 台幣出金，要清掉錢包地址
              }}
            />
          </div>

          {/* 錢包資料 */}
          {state.withdrawType === "Crypto" && (
            <div>
              <label htmlFor='clientAcct' className='inline-block mb-2 text-gray-700 form-label'>
                錢包收款地址
              </label>
              <span className='ml-5 text-red-500'>{inputErrMsg.clientAcct}</span>

              <input
                required
                id='clientAcct'
                type='text'
                className={cssInput}
                onChange={e => handleInput("create_clientAcct", e.target.value)}
              />
            </div>
          )}

          {/* 銀行帳號 */}
          {state.withdrawType === "TWD" && (
            <div>
              <label htmlFor='withdraw_type' className='inline-block mb-2 text-gray-700 form-label'>
                銀行帳號
              </label>
              <span className='ml-5 text-red-500'>{inputErrMsg.bankCode}</span>

              <Select
                id='withdraw_type'
                isSearchable={true}
                placeholder={"銀行代碼"}
                options={bankCodeOptions}
                menuPlacement={"top"}
                className='mb-2'
                onChange={o => {
                  if (!o) return;
                  const value = o.value;
                  // console.log(value);
                  handleInput("create_bankCode", value.code);
                  handleInput("create_bankName", value.name);
                }}
              />
              <input
                required
                id='bank_account'
                type='text'
                placeholder='銀行帳號'
                className={cssInput}
                onChange={e => handleInput("create_bankAcct", e.target.value)}
              />
            </div>
          )}
        </section>

        {/* 按鈕 */}
        <div className='flex justify-center mt-3 space-x-2'>
          <button
            type='submit'
            className='inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'
          >
            送出
          </button>
        </div>
      </form>
    </div>
  );
}

/**
 *    dispatch
 */
function reducer(state, action) {
  switch (action.type) {
    // chain
    case "create_chainName":
      return { ...state, chainName: action.payload };
    case "clear_chainName":
      return { ...state, chainName: "" };
    // union
    case "create_unionId":
      return { ...state, unionId: action.payload };
    case "clear_unionId":
      return { ...state, unionId: "" };
    // 支付幣別
    case "insert_token":
      const isIncluded = state.tokens.includes(action.payload);
      if (isIncluded) return state;
      else return { ...state, tokens: [...state.tokens, action.payload] };
    case "delete_token":
      const _tokens = state.tokens.filter(token => token !== action.payload);
      return { ...state, tokens: _tokens };
    case "create_tokens":
      return { ...state, tokens: action.payload };
    case "clear_tokens":
      return { ...state, tokens: [] };
    // 聯絡人
    case "create_contactPerson":
      return { ...state, contactPerson: action.payload };
    // 聯絡人電話
    case "create_contactTel":
      return { ...state, contactTel: action.payload };
    // 聯絡人 email
    case "create_contactEmail":
      return { ...state, contactEmail: action.payload };
    // 公司名稱
    case "create_companyName":
      return { ...state, companyName: action.payload };
    // 店家名稱
    case "create_companyRemark":
      return { ...state, companyRemark: action.payload };
    // 公司網址
    case "create_companyUrl":
      return { ...state, companyUrl: action.payload };
    // 公司電話
    case "create_companyTel":
      return { ...state, companyTel: action.payload };
    // 公司地址
    case "create_companyAddress":
      return { ...state, companyAddress: action.payload };
    // 收款地址
    case "create_clientAcct":
      return { ...state, clientAcct: action.payload };
    // 操作地址
    case "create_clientOper":
      return { ...state, clientOper: action.payload };
    // 出金方式
    case "create_withdrawType":
      return { ...state, withdrawType: action.payload };
    // 台幣銀行帳戶
    case "create_bankCode":
      return { ...state, bankCode: action.payload };
    case "create_bankName":
      return { ...state, bankName: action.payload };
    case "create_bankAcct":
      return { ...state, bankAcct: action.payload };
    // 刪除
    case "delete_bank":
      return { ...state, bankCode: "", bankName: "", bankAcct: "" };
    case "delete_crypto":
      return { ...state, clientAcct: "" };
    default:
      throw new Error("action.type error!!!");
  }
}
