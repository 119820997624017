import axios from "axios";

// Frank 將 Ryan 和 Jimmy 之前的 api 整合在一起

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKERPAY_2nd_SERVER_URL,
});

/**
 * 管理員登入
 * @param {string} account
 * @param {string} password
 * @returns {token: string}
 */
export async function loginAdmin(account, password) {
  const res = await instance.post("/api/v2/admin/login", { account, password });
  return res.data;
}

/**
 * 廠商登入
 * @param {string} email
 * @param {string} password
 * @returns {token: string}
 */
export async function loginVendor(email, password) {
  const res = await instance.post("/api/v2/user/login", { email, password });
  return res.data;
}

/**
 * 台幣出金紀錄
 * @param {string} token
 * @param {{ states: string, page: string, pageSize: string }} params
 * - states = 'Apply' || 'Remit' || 'Withdraw'
 * - page = '1'
 * - pageSize = '10'
 * @returns {total: number, results: object[]}
 */
export async function getTWDWithdrawLog(token, params) {
  // const { states, page, pageSize } = params;

  const res = await instance.get("/api/v2/admin/withdraw-twd-records", {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  return res.data;
}

/**
 * 廠商申請台幣提領
 * @param {string} paymentId F0U0P34
 * @returns { orderTotal: number } 400.411
 */
export async function applyTWDBalanceWithdraw(token, paymentId) {
  const res = await instance.post(
    "/api/v2/admin/withdraw-twd-records",
    { paymentId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data;
}

/**
 *
 * @param {string} token
 * @param {obj} data
 * {
 *    paymentId: string, // "F0U0P24"
 *    withdrawTxnHash: string, // "0x83ea5949c548260ee76cd7cebf238e2ba93a538b32ed12a84a490291d794742a"
 *    batchNum: string, // "000"
 *    serialNum: string // "000"
 * }
 * @returns
 */
export async function submitTransferInfo(token, data) {
  const { paymentId, withdrawTxnHash, batchNum, serialNum } = data;

  const res = await instance.patch(
    "/api/v2/admin/withdraw-twd-records",
    {
      paymentId,
      withdrawTxnHash,
      batchNum,
      serialNum,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res.data;
}

/**
 * 加密貨幣出金紀錄
 * @param {string} token
 * @param {{ page: string, pageSize: string }} params
 * - page = '1'
 * - pageSize = '10'
 * @returns {total: number, results: object[]}
 */
export async function getCryptoWithdrawLog(token, params) {
  // const { page, pageSize } = params;

  const res = await instance.get("/api/v2/admin/withdraw-records", {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  return res.data;
}

/**
 * 廠商出金申請列表
 * @param {string} token
 * @returns object[]
 */
export async function getVendorWithdrawList(token) {
  const res = await instance.get("/api/v2/store-payments/balances", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}

/**
 * 查詢店家資料
 * @param {string} token
 * @param {number} storeId
 * @returns {}
 */
export async function getStoreById(token, storeId) {
  const res = await instance.get(`/api/v2/store-payments/store/${storeId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}

/**
 * 取得商家報表
 * @param {string} token
 * @param {{ paymentId: string, fromUnixTime: number, toUnixTime: number }} data
 * paymentId = "F1U0P0"
 * fromUnixTime = 0
 * toUnixTime = 1702659598 (10 digit)
 * @returns { orders: {}, withdrawRecords: {}, refundRecords: {} }
 *
 */
export async function getPaymentReport(token, data) {
  const { paymentId, fromUnixTime, toUnixTime } = data;

  const res = await instance.get("/api/v2/payment-report", {
    headers: { Authorization: `Bearer ${token}` },
    params: { paymentId, fromUnixTime, toUnixTime },
  });

  return res.data;
}

/**
 * 查詢 Backerpay 合約
 * @returns { chainName: string, unions: [], tokens: [] }[]
 */
export async function getBackerpayContracts() {
  const res = await instance.get("/api/v2/admin/supported-chains-tokens");

  return res.data;
}

/**
 * 取得未核准的商家列表
 * @param {string} token
 * @param {{ page: string, pageSize: string }} params
 * - page = '1'
 * - pageSize = '10'
 * @returns { total: number, stores: object[]}
 */
export async function getUnapprovedStore(token, params) {
  const res = await instance.get("/api/v2/admin/store/unapproved", {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  return res.data;
}

/**
 * 取得已核准的商家列表
 * @param {string} token
 * @param {{ page: string, pageSize: string }} params
 * - page = '1'
 * - pageSize = '10'
 * @returns { total: number, stores: object[]}
 */
export async function getApprovedStore(token, params) {
  const res = await instance.get("/api/v2/admin/store/approved", {
    headers: { Authorization: `Bearer ${token}` },
    params,
  });

  return res.data;
}

/**
 * 核准商家
 * @param {string} token
 * @param {{ storeId: number, unionId: string, paymentTmpId: number, isApproved: boolean }} data
 * - storeId: 928
 * - unionId: "F0U0"
 * - paymentTmpId: 32
 * - isApproved: true
 */
export async function approveStore(token, storeId, unionId, paymentTmpId) {
  const isApproved = true;
  const data = { storeId, unionId, paymentTmpId, isApproved };

  const res = await instance.post("/api/v2/admin/store/approval", data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}

/**
 * 退件商家
 * @param {string} token
 * @param {{ storeId: number, unionId: string, paymentTmpId: number, isApproved: boolean, rejectionMessage: string }} data
 * - storeId: 928
 * - unionId: "F0U0"
 * - paymentTmpId: 32
 * - isApproved: false
 * - rejectionMessage: "you are shit"
 */
export async function rejectStore(token, storeId, unionId, paymentTmpId, rejectionMessage = "") {
  const isApproved = false;
  const data = { storeId, unionId, paymentTmpId, isApproved, rejectionMessage };

  const res = await instance.post("/api/v2/admin/store/approval", data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}

/**
 * 取得 Backerpay 冷錢包地址
 * @returns string
 * - "0x213...456"
 */
export async function getBackerpayColdWallet() {
  const res = await instance.get("/api/v2/cold-wallet-address");

  return res.data.address;
}

/**
 * 取得支援的鏈
 * @return obj[]
 * obj: {
          "chainName": "Polygon_Mumbai",
          "unions": [
                      {
                        "unions": "F0U0",
                        "type": "QR",
                        "unionAddr": "0x76742Ad4D518442f7C20a5D4319DA3c49b583f14"
                      },
                      {
                        "unions": "F0U1",
                        "type": "EC",
                        "unionAddr": "0xB7898267204126Ea2aa007757C1846CE572d022E"
                      }
                    ],
          "tokens": [
                      {
                          "tokenName": "MATIC",
                          "tokenSymbol": "MATIC",
                          "tokenAddr": "0x0000000000000000000000000000000000000000"
                      },
                      {
                          "tokenName": "ChainLink Token",
                          "tokenSymbol": "LINK",
                          "tokenAddr": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB"
                      },
                    ]
 *  }
 */
export async function getSupportedChains() {
  const res = await instance.get("/api/v2/admin/supported-chains-tokens");

  return res.data;
}

/**
 * 創立商家
 * @param {string} token
 * @param {obj} data
 */
export async function createStore(token, data) {
  /**
## 範例

### 加密貨幣出金

```
{
    "store": {
        "email": "frankwang95174@gmail.com",
        "name": "test store",
        "remark": "test remark"
        "applyRole": 0
    },
    "paymentTmp": {
        "unionId": "F0U0",
        "clientOper": "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        "clientAcct": "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        "tokens": [
            "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
            "0xE322f5a2981e9Fb8aa2c9816Bbc05E422C228CC5"
        ],
        "withdrawType": "Crypto",
        "rate": "1.6"
    }
}
```

### 台幣出金

```
{
    "store": {
        "bankAcct": "123456789",
        "bankCode": "123",
        "bankName": "bank",
        "email": "frankwang95174@gmail.com",
        "name": "test store",
        "remark": "test remark"
        "applyRole": 1
    },
    "paymentTmp": {
        "unionId": "F0U0",
        "clientOper": "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        "tokens": [
            "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
            "0xE322f5a2981e9Fb8aa2c9816Bbc05E422C228CC5"
        ],
        "withdrawType": "Crypto",
        "rate": "1.6"
    }
}
```
 */
  const res = await instance.post("/api/v2/store", data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}

/**
 * 建立店家帳號
 * @param {{
 *  email: string,
 *  password: string,
 *  name: string,
 *  phoneNumber: string
 * }} data
 * @returns
 */
export async function registerVendor(data) {
  const res = await instance.post("/api/v2/user", data);
  return res.data;
}

/**
 * 查詢使用者綁定的商店
 * @param {string} token
 * @returns obj[]
 * obj: {
 *    id: number // 667
 *    userId: number // 738
 *    storeId: number // 106
 *    createdAt: string // "2023-03-21T10:08:09.000Z"
 *    updatedAt: string // "2023-03-21T10:08:09.000Z"
 * }
 */
export async function getStoresByVendor(token) {
  const res = await instance.get("/api/v2/user-store", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
}
