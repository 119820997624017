export default function Pagination({
  currentPage,
  setCurrentPage,
  contentsPerPage,
  totalContents,
}) {
  /**
   * contentsPerPage = 3
   * totalContents = 10
   * lastPage = 4 (10 / 3)
   *
   * 5 paginations in a row
   * row 1 - [1, 2, 3, 4, 5]
   * row 2 - [6, 7, 8, 9, 10]
   * row 3 - [11, 12, 13, 14, 15]
   * ...
   */

  const lastPage = Math.ceil(totalContents / contentsPerPage);
  const paginationsInRow = 5;
  const rowNumber = Math.ceil(currentPage / paginationsInRow); //          row 1 || row 2 || row 3
  let firstIndex = rowNumber * paginationsInRow - paginationsInRow + 1; //     1 ||     6 ||    11
  let lastIndex = rowNumber * paginationsInRow; //                             5 ||    10 ||    15

  // make sure startIndex min is 1
  firstIndex = firstIndex < 1 ? 1 : firstIndex;
  // make sure lastIndex max is paginations
  lastIndex = lastIndex > lastPage ? lastPage : lastIndex;
  // array of numbers in a row
  const showPaginations = createRangeArray(firstIndex, lastIndex); // [1,2,3,4,5] || [6,7,8,9,10]

  function createRangeArray(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  //   console.log("currentPage", currentPage);
  //   console.log("rowNumber", rowNumber);
  //   console.log("startIndex", startIndex);
  //   console.log("lastIndex", lastIndex);

  const prePage = () => {
    setCurrentPage(page => {
      if (page - 1 < 1) return page;
      return page - 1;
    });
  };

  const nextPage = () => {
    setCurrentPage(page => {
      if (page + 1 > lastPage) return page;
      return page + 1;
    });
  };

  return (
    <div className='flex justify-center gap-4 text-xl'>
      <button onClick={prePage}>{"<"}</button>

      {lastPage &&
        showPaginations.map(page => {
          const isCurrentPage = currentPage === page;

          return (
            <button
              key={page}
              className={`p-2 rounded-xl ${isCurrentPage ? "bg-slate-400" : ""}`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          );
        })}

      <button onClick={nextPage}>{">"}</button>
    </div>
  );
}
