import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// apis
import { registerVendor } from "../../api/BackerpayApi_2nd";

// const
const INPUTS = ["email", "password", "password_2", "name", "phoneNumber"];

export default function RegisterVendor() {
  const [formData, setFormData] = useState({});
  const [errMsg, setErrMsg] = useState({});
  const navigate = useNavigate();

  /**
   * 修改輸入
   * @param {string} key
   * @param {string} value
   */
  const handleChange = (key, value) => {
    setFormData(pre => ({ ...pre, [key]: value }));
  };

  /**
   * 送出表單
   * @param {event} e
   */
  const submitFormData = async e => {
    e.preventDefault();

    try {
      const isPassError = hasPassError();
      const { password_2, ...data } = formData;
      const res = isPassError && (await registerVendor(data));
      res && setFormData({}); // clear form data
      res && navigate("/vendor/login-vendor", { state: { ...data } }); // redirect

      // errors
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      console.error(err);
    }
  };

  /**
   * 檢查錯誤
   */
  const hasPassError = () => {
    setErrMsg({});
    return isAllRequired() && isEmail() && isPasswordMatch();
  };

  const isAllRequired = () => {
    const requiredInputs = INPUTS;
    for (let input of requiredInputs)
      !formData[input] && setErrMsg(pre => ({ ...pre, [input]: "required" }));

    return requiredInputs.every(input => formData[input]);
  };

  const isEmail = () => {
    const email = formData.email;
    const isEmail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email);
    !isEmail && setErrMsg(pre => ({ ...pre, email: "invalid email" }));

    return isEmail;
  };

  const isPasswordMatch = () => {
    const isMatch = formData.password === formData.password_2;
    !isMatch && setErrMsg(pre => ({ ...pre, password: "password not match" }));
    return isMatch;
  };

  return (
    <div>
      <div>
        <Toaster />
      </div>

      <h1 className='mt-10 mb-5 text-4xl font-bold text-center md:text-left md:ml-10 md:mb-0'>
        店家
      </h1>

      <form className='max-w-md px-4 mx-auto space-y-3'>
        <h2 className='text-3xl font-bold text-center'>註冊帳號</h2>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='email'>
            Email(帳號)
          </label>
          <input
            required
            className='rounded-2xl'
            id='email'
            type='email'
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
        </div>
        <p className='text-sm text-right text-red-500'>{errMsg?.email}</p>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='password'>
            設定密碼
          </label>
          <input
            required
            className='rounded-2xl'
            id='password'
            type='password'
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
        </div>
        <p className='text-sm text-right text-red-500'>{errMsg?.password}</p>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='password-2'>
            確認密碼
          </label>
          <input
            required
            className='rounded-2xl'
            id='password_2'
            type='password'
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
        </div>
        <p className='text-sm text-right text-red-500'>{errMsg?.password_2}</p>

        <br />
        <h3 className='text-xl font-bold text-center'>聯絡人資訊</h3>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='name'>
            姓名
          </label>
          <input
            required
            className='rounded-2xl'
            id='name'
            type='text'
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
        </div>
        <p className='text-sm text-right text-red-500'>{errMsg?.name}</p>

        <div className='flex items-center justify-between space-x-5'>
          <label className='text-xl font-bold' htmlFor='phoneNumber'>
            電話
          </label>
          <input
            required
            className='rounded-2xl'
            id='phoneNumber'
            type='text'
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
        </div>
        <p className='text-sm text-right text-red-500'>{errMsg?.phoneNumber}</p>

        <br />
        <button
          className='mx-auto py-2.5 px-7 block text-3xl font-bold rounded-xl text-white bg-[#2010D6]'
          onClick={submitFormData}
        >
          建立
        </button>
      </form>
    </div>
  );
}
