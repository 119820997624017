import "./dist/css/main.css";
import "./dist/css/swiper-bundle.min.css";
import "./dist/css/index.css";

import React from "react";
import {useTranslation} from "react-i18next";

// import axios from "axios";
// import { Button } from "flowbite-react";

// hooks
import useScript from "../../hooks/useScript";
import LazyLoadImage from "../../components/LazyLoadImage";

export default function Index() {
    const {t} = useTranslation();
    // import js script
    useScript("dist/js/main.js");
    useScript("dist/js/swiper-bundle.min.js");
    useScript("dist/js/gsap.min.js");
    useScript("dist/js/ScrollTrigger.min.js");
    useScript("dist/js/index.js");

    return (
        <div>
            {/* <link rel='stylesheet' href='dist/css/main.css' />
      <link rel='stylesheet' href='dist/css/index.css' />
      <link rel='stylesheet' href='dist/css/swiper-bundle.min.css' /> */}

            <main>
                <div
                    className='kv-section relative bg-[#ffd54e] mb-[-12vh] pt-[17.5vh] md:mb-[13.685vw] md:pt-[21vh] h-[100vh] md:max-h-[600px]'>
                    <div className='container'>
                        <h1 className="mb-1 md:mb-4 text-[3rem] md:text-[4.4rem] lg:text-[5.5rem] font-bold">BACKER
                            PAY</h1>
                        <div className='kv-title font-bold'>
                            <span>{t("開啟")}</span>
                            {t("你的加密貨幣")}
                            <br/>
                            {t("支付服務")}
                        </div>
                    </div>
                    <div className='absolute left-1/2 top-0 -translate-x-1/2 w-full md:w-11/12'>
                        <img className='hidden md:block w-full' src='dist/images/kv_bg_circle.png' alt=''
                             loading={'lazy'}/>
                        <img className='md:hidden w-full' src='dist/images/kv_bg_circle_mb.png' alt=''
                             loading={'lazy'}/>
                    </div>
                    <div className='opacity-0 animate-[fade_1.5s_ease_0s_1_forwards]'>
                        <div
                            className='absolute left-1/2 -translate-x-1/2 origin-center top:[13.5vh] w-[113%] md:top-[2vw] md:w-1/2'>
                            <picture>
                                <source type={'image/webp'} srcSet={'dist/images/kv_circle01.webp'}/>
                                <img src='dist/images/kv_circle01.png' alt='' loading={'lazy'}
                                     className={'animate-[spin_60s_linear_infinite]'}/>
                            </picture>
                        </div>
                        <div
                            className='absolute left-1/2 -translate-x-1/2 origin-center animate-spin animate-[fade_1.5s_ease_0s_1_forwards] top:[-8vw] w-[177%] md:top-[-11vw] md:w-[79%]'>
                            <picture>
                                <source type={'image/webp'} srcSet={'dist/images/kv_circle02.webp'}/>
                                <img src='dist/images/kv_circle02.png' alt='' loading={'lazy'}
                                     className={'animate-[spin_60s_linear_infinite_reverse]'}/></picture>
                        </div>
                    </div>
                    <div
                        className='absolute opacity-0 animate-[fade_1.5s_ease_1s_1_forwards] left-1/2 -translate-x-1/2 top-[48vh] w-[90%] md:left-1/3 md:top-[30vh] md:w-[42%] md:translate-x-0'>
                        <picture>
                            <source type={'image/webp'} srcSet={'dist/images/kv_img.webp'}/>
                            <img src='dist/images/kv_img.gif' alt='kv-img'/>
                        </picture>
                    </div>
                    <div className='coin-wrap'>
                        <picture>
                            <source type={'image/webp'} srcSet={'dist/images/coin.webp'}/>
                            <img className='hidden md:block' src='dist/images/coin.png' alt='' loading={'lazy'}/>
                        </picture>
                        <picture>
                            <source type={'image/webp'} srcSet={'dist/images/coin_mb.webp'}/>
                            <img className='md:hidden' src='dist/images/coin_mb.png' alt='' loading={'lazy'}/>
                        </picture>
                    </div>
                    <div className='scroll font-bold'>
                        SCROLL
                        <div className='arrow'>
                            <picture>
                                <source type={'image/webp'} srcSet={'dist/images/scroll_arrow.webp'}/>
                                <img src='dist/images/scroll_arrow.png' alt='' loading={'lazy'}/>
                            </picture>
                        </div>
                    </div>
                </div>
                <div className='cards-section'>
                    <div className='yel-ball-b'/>
                    <div className='yel-ball-s'/>
                    <div className='container'>
                        <div className='cards'>
                            <div className='swiper cardSwiper'>
                                <div className='swiper-wrapper'>
                                    <div className='swiper-slide'>
                                        <div className='card'>
                                            <div className='card-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/card01.webp'}/>
                                                    <img src='dist/images/card01.gif' alt=''/>
                                                </picture>
                                            </div>
                                            <div className='f30-20 font-bold'>{t("交易無國界")}</div>
                                            <div className='f22-18'>{t("提前布局元宇宙")}</div>
                                        </div>
                                    </div>
                                    <div className='swiper-slide'>
                                        <div className='card'>
                                            <div className='card-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/card02.webp'}/>
                                                    <img src='dist/images/card02.gif' alt=''/>
                                                </picture>
                                            </div>
                                            <div className='f30-20 font-bold'>{t("體驗升級")}</div>
                                            <div className='f22-18'>{t("付款簡單又便利")}</div>
                                        </div>
                                    </div>
                                    <div className='swiper-slide'>
                                        <div className='card'>
                                            <div className='card-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/card03.webp'}/>
                                                    <img src='dist/images/card03.gif' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                            <div className='f30-20 font-bold'>{t("申辦快速")}</div>
                                            <div className='f22-18'>{t("最佳商務夥伴")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='cardSwiper-pagination'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='apply-section'>
                    <div className='yel-ball'>
                        <img className='pc' src='dist/images/yel_ball02.png' alt='' loading={'lazy'}/>
                        <img className='mo' src='dist/images/yel_ball02_mb.png' alt='' loading={'lazy'}/>
                    </div>
                    <div className='bavepay-text'>
                        <div id='trigger1' className='spacer s0'/>
                        <div id='word'>
                            <div>BAKERPAY</div>
                        </div>
                    </div>
                    <div className='container'>
                        <h2 className='f44-30 font-bold'>
                            {t("擁有加密貨幣錢包")}
                            <br/>
                            {t("即可快速註冊")}
                        </h2>
                        <h3>
                            <div className='f24-18 font-bold'>HOW TO APPLY</div>
                            <div className='f36-25 font-bold'>{t("如何申辦加密貨幣錢包")}</div>
                        </h3>
                        <div className='how-wrap'>
                            <div className='bg'>
                                <picture>
                                    <source type={'image/webp'} srcSet={'dist/images/apply_bg.webp'}/>
                                    <img className='pc' src='dist/images/apply_bg.png' alt='' loading={'lazy'}/>
                                </picture>
                                <picture>
                                    <source type={'image/webp'} srcSet={'dist/images/apply_bg_mb.webp'}/>
                                    <img className='mo' src='dist/images/apply_bg_mb.png' alt='' loading={'lazy'}/>
                                </picture>
                            </div>
                            <div className='swiper-wrap'>
                                <div
                                    style={{swiperNavigationColor: "#fff", swiperPaginationColor: "#fff"}}
                                    className='swiper phoneSwiper'
                                >
                                    <div className='swiper-wrapper'>
                                        <div className='swiper-slide'>
                                            <div className='phone-case'/>
                                            <div className='phone-img'>
                                                {/* 300*600 */}
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/step_1.webp'}/>
                                                    <img src='dist/images/step_1.png' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='phone-case'/>
                                            <div className='phone-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/step_2.webp'}/>
                                                    <img src='dist/images/step_2.png' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='phone-case'/>
                                            <div className='phone-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/step_3.webp'}/>
                                                    <img src='dist/images/step_3.png' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='phone-case'/>
                                            <div className='phone-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/step_4.webp'}/>
                                                    <img src='dist/images/step_4.png' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='phone-case'/>
                                            <div className='phone-img'>
                                                <picture>
                                                    <source type={'image/webp'} srcSet={'dist/images/step_5.webp'}/>
                                                    <img src='dist/images/step_5.png' alt='' loading={'lazy'}/>
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='phoneSwiper-navigation hidden md:block'>
                                        <div className='phoneSwiper-button-prev hidden md:block'/>
                                        <div className='line'/>
                                        <div className='phoneSwiper-button-next hidden md:block'/>
                                    </div>
                                </div>
                                <div className='swiper numSwiper'>
                                    <div className='swiper-wrapper'>
                                        <div className='swiper-slide'>
                                            <div className='num'>01</div>
                                            <div className='content'>{t("下載Metamask App")}</div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='num'>02</div>
                                            <div className='content'>{t("創建密碼")}</div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='num'>03</div>
                                            <div className='content'>{t("寫下助記詞")}</div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='num'>04</div>
                                            <div className='content'>{t("確認助記詞")}</div>
                                        </div>
                                        <div className='swiper-slide'>
                                            <div className='num'>05</div>
                                            <div className='content'>{t("申辦成功")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='phoneSwiper-navigation md:hidden'>
                                    <div className='phoneSwiper-button-prev md:hidden'/>
                                    <div className='line md:hidden'/>
                                    <div className='phoneSwiper-button-next md:hidden'/>
                                </div>
                            </div>
                        </div>
                        <div className='btnwrap'>
                            {/* <a className='btn' href='/#' target='_blank' rel='noreferrer'>
                了解更多
                <span className='more' />
              </a> */}
                        </div>
                    </div>
                </div>
                <div className='support-section'>
                    <div className='container'>
                        <div className='center-box'>
                            <h3>
                                <div className='f24-18 font-bold'>ALL SUPPORT</div>
                                <div className='f36-25 font-bold'>{t("支援多款加密貨幣錢包")}</div>
                            </h3>
                        </div>
                        <div className='swiper supportSwiper'>
                            <div className='swiper-wrapper'>
                                <div className='swiper-slide'>
                                    <div className='logo-wrap'>
                                        {/* 164*40 */}
                                        <picture>
                                            <source type={'image/webp'} srcSet={'dist/images/logo1.webp'}/>
                                            <img src='dist/images/logo1.png' alt='' loading={'lazy'}/>
                                        </picture>
                                    </div>
                                </div>
                                <div className='swiper-slide'>
                                    <div className='bg-white logo-wrap'>
                                        {/* 164*40 */}
                                        <img src='dist/images/KryptoGO_logo.svg' alt='' loading={'lazy'}/>
                                    </div>
                                </div>
                                {/* <div className='swiper-slide'> */}
                                {/* <div className='logo-wrap yelbg'> */}
                                {/* 164*40 */}
                                {/* <img src='dist/images/logo3.png' alt='' /> */}
                                {/* </div> */}
                                {/* </div> */}
                                {/* <div className='swiper-slide'> */}
                                {/* <div className='logo-wrap'> */}
                                {/* 164*40 */}
                                {/* <img src='dist/images/logo4.png' alt='' /> */}
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className='yel-box'/>
                        <div className='grey-box'/>
                    </div>
                </div>
                <div className='computer-section'>
                    <div className='container'>
                        <h2 className='f44-30 font-bold'>
                            <span>{/* <i>30</i>分鐘 */}</span>
                            {t("創建自己的")}
                            <br/>
                            {t("加密貨幣商店")}
                        </h2>
                        <ul>
                            <li>
                                <div className='icon-wrap'>
                                    <picture>
                                        <source type={'image/webp'} srcSet={'dist/images/icon_shop.webp'}/>
                                        <img src='dist/images/icon_shop.png' alt='' loading={'lazy'}/>
                                    </picture>

                                </div>
                                <div className='list-content'>
                                    <div className='f30-20 font-bold'>{t("商城內容設定")}</div>
                                    <p>
                                        {t("商品圖片、售價、說明")}
                                        <br/>
                                        {t("物流資料收集")}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className='icon-wrap'>
                                    <picture>
                                        <source type={'image/webp'} srcSet={'dist/images/icon_chart.webp'}/>
                                        <img src='dist/images/icon_chart.png' alt=''/>
                                    </picture>
                                </div>
                                <div className='list-content'>
                                    <div className='f30-20 font-bold'>{t("收款資訊建置")}</div>
                                    <p>
                                        {t("支援多鏈、多幣種")}
                                        <br/>
                                        {t("進階服務－多簽、分潤")}
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <div className='macbook'>
                            <div className='frame'>
                                <LazyLoadImage className={'hidden lg:block'} imgSrc={'dist/images/macbook.png'}
                                               sourceSrc={'dist/images/macbook.webp'} alt={'macbook'}></LazyLoadImage>
                                <LazyLoadImage className={'lg:hidden'} imgSrc={'dist/images/macbook_mb.png'}
                                               sourceSrc={'dist/images/macbook_mb.webp'}
                                               alt={'macbook_mb'}></LazyLoadImage>
                            </div>
                            <div className='sreen animatable fadeIn'>
                                {/* 868*530 */}
                                <picture>
                                    <source type={'image/webp'} srcSet={'dist/images/makbook_content.webp'}/>
                                    <img className='hidden lg:block' src='dist/images/makbook_content.png' alt=''
                                         loading={'lazy'}/>
                                </picture>
                                {/* 620*530 */}
                                <picture>
                                    <source type={'image/webp'} srcSet={'dist/images/makbook_content_mb.webp'}/>
                                    <img className='lg:hidden' src={`dist/images/makbook_content_mb.png`} alt=''
                                         loading={'lazy'}/>
                                </picture>
                            </div>
                        </div>
                        {/*<div className='-smalltext font-bold f24-18'>CREATE A STORE</div>*/}
                    </div>
                </div>
                <div className='plan-section'>
                    <div className='yel-ball'>
                        <picture>
                            <source type={'image/webp'} srcSet={'dist/images/yel_ball03.webp'}/>
                            <img className='pc' src='dist/images/yel_ball03.png' alt='' loading={'lazy'}/>
                        </picture>
                        <picture>
                            <source type={'image/webp'} srcSet={'dist/images/yel_ball03_mb.webp'}/>
                            <img className='mo' src='dist/images/yel_ball03_mb.png' alt='' loading={'lazy'}/>
                        </picture>
                    </div>
                    <div className='container'>
                        <div className='content-wrap'>
                            <h2 className='f44-30 font-bold'>{t("提供多元企業合作方案")}</h2>
                            <ul>
                                <li>
                                    <div className='icon-wrap'>
                                        <LazyLoadImage imgSrc={'dist/images/icon_phone.png'}
                                                       sourceSrc={'dist/images/icon_phone.webp'} alt={'plan'}/>
                                    </div>
                                    <div className='list-content'>
                                        <div className='f30-20 font-bold'>{t("收款QRcode")}</div>
                                        <p>{t("加密貨幣支付好簡單")}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon-wrap'>
                                        <LazyLoadImage imgSrc={'dist/images/icon_buy.png'}
                                                       sourceSrc={'dist/images/icon_buy.webp'}
                                                       alt={'icon_buy'}></LazyLoadImage>
                                    </div>
                                    <div className='list-content'>
                                        <div className='f30-20 font-bold'>{t("商城網站API介接")}</div>
                                        <p>
                                            {t("提供BACKER PAY作為網路商店的付款方式")}
                                            <br/>
                                            {t("提前布局新時代支付模式")}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='yel-bg'>
                        <div className='imgwrap'>
                            <LazyLoadImage imgSrc={'dist/images/plan.gif'} sourceSrc={'dist/images/plan.webp'}
                                           alt={'plan'}/>
                        </div>
                        <div className='small-text font-bold f24-18'>COOPERATION PLAN</div>
                    </div>
                </div>
            </main>

            <div className='top_btn'/>
        </div>
    );
}
