import React from "react";
import { Routes, Route } from "react-router-dom";

// import useScript from "./hooks/useScript";

// basic pages
import RequireAuth from "./pages/RequireAuth";
import RequireVendor from "./pages/RequireVendor";
import RequireVendorForStore from "./pages/RequireVendorForStore";
import Unauthorized from "./pages/Unauthorized";
import Missing from "./pages/Missing";

// 後台頁面
import Layout from "./pages/Layout";
import LoginAdmin from "./pages/LoginAdmin";
import Home from "./pages/Home";
import CreatePayment from "./pages/CreatePayment";
import ApprovePayment from "./pages/ApprovePayment";
import Approve3wKyc from "./pages/Approve3wKyc";
import UserKycFlow from "./pages/UserKycFlow";
import ApproveWithdraw from "./pages/ApproveWithdraw";
import BehaviorReport from "./pages/BehaviorReport";
import UpdateContract from "./pages/UpdateContract";

// newcoin pages
import NewcoinRegister from "./pages/Newcoin/NewcoinRegister";
import ApproveNewcoinVendor from "./pages/Newcoin/ApproveNewcoinVendor";

// 前台頁面
import LayoutBavepay from "./pages/LayoutBavepay";
import Baveshop from "./pages/Bavepay/Baveshop";
import FAQ from "./pages/Bavepay/FAQ";
import Index from "./pages/Bavepay/Index";
import MemberProfile from "./pages/Bavepay/MemberProfile";
import Product from "./pages/Bavepay/Product";
import Registration from "./pages/Bavepay/Registration";

// Vendor page
import LayoutVendor from "./pages/LayoutVendor";
import RegisterVendor from "./pages/Vendor/RegisterVendor";
import ListStore from "./pages/Vendor/ListStore";
import LoginVendor from "./pages/Vendor/LoginVendor";
import CreateStore from "./pages/Vendor/CreateStore/CreateStore";

const VENDOR_LINKS = [
  // public
  { isPrivate: false, label: "註冊帳號", path: "register-vendor", element: RegisterVendor },
  { isPrivate: false, label: "登入帳號", path: "login-vendor", element: LoginVendor },
  // private
  { isPrivate: true, label: "顯示商家", path: "list-store", element: ListStore },
  { isPrivate: true, label: "創立商家", path: "create-store", element: CreateStore },
];

function App() {
  return (
    <Routes>
      {/* bavepay 商家(vendor) 前台登  */}
      <Route path='/' element={<LayoutBavepay />}>
        <Route path='/' element={<Index />} />
        <Route path='faq' element={<FAQ />} />
        <Route path='product' element={<Product />} />
        <Route path='baveshop' element={<Baveshop />} />

        {/* register -> login */}
        <Route path='registration' element={<Registration />} />

        {/* protect route */}
        <Route element={<RequireVendor />}>
          <Route path='memberprofile' element={<MemberProfile />} />
        </Route>
      </Route>

      {/* 管理者(admin) 後台 */}
      <Route path='/' element={<Layout />}>
        {/* public routes */}
        <Route path='home' element={<Home />} />
        <Route path='login' element={<LoginAdmin />} />
        <Route path='unauthorized' element={<Unauthorized />} />
        <Route path='newcoinregister/:walletAddress' element={<NewcoinRegister />} />
        <Route path='userkycflow' element={<UserKycFlow />} />

        {/* private routes */}
        <Route element={<RequireAuth />}>
           <Route path='approve-newcoin-vendor' element={<ApproveNewcoinVendor />} />
          <Route path='approve-payment' element={<ApprovePayment />} />
          <Route path='approve-3w-kyc' element={<Approve3wKyc />} />
          <Route path='approve-withdraw' element={<ApproveWithdraw />} />
          <Route path='create-payment' element={<CreatePayment />} />
          <Route path='behavior-report' element={<BehaviorReport />} />
          <Route path='update-contract' element={<UpdateContract />} />
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />
      </Route>

      {/* 商家(vendor) 後台登入路徑 */}
      <Route path='/vendor' element={<LayoutVendor links={VENDOR_LINKS} />}>
        {
          // public
          VENDOR_LINKS.map(
            link =>
              !link.isPrivate && (
                <Route key={link.path} path={link.path} element={<link.element />} />
              )
          )
        }

        <Route element={<RequireVendorForStore />}>
          {
            // private
            VENDOR_LINKS.map(
              link =>
                link.isPrivate && (
                  <Route key={link.path} path={link.path} element={<link.element />} />
                )
            )
          }
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
