import { useState } from "react";
import useSWR from "swr";
import toast, { Toaster } from "react-hot-toast";

// wagmi
import { useAccount, useContractRead } from "wagmi";
import { InjectedConnector } from "@wagmi/core";

// apis
import { getBackerpayContracts } from "../api/BackerpayApi_2nd";

// utils
import { writeContractProcess } from "../utils/WagmiTool";
import { prepareWriteContract, writeContract } from "@wagmi/core";

// contract
import { CHAIN_ID } from "../contract/ChainInfo";
import BavepayABI from "../contract/BavepayABI.json";

export default function UpdateContract() {
  /**
   * backend data
   */
  const [backerpayContracts, setBackerpayContracts] = useState([]);
  useSWR("getContracts", getBackerpayContracts, {
    onSuccess(data) {
      setBackerpayContracts(data);
    },
    onError(err) {
      toast.error(err.message);
      console.error(err);
    },
  });

  return (
    <div>
      <div>
        <Toaster />
      </div>

      <div className='space-y-4'>
        {backerpayContracts.map(contract => {
          const { chainName, unions } = contract;

          return (
            <div key={chainName}>
              <h1 className='px-2 py-1 text-xl bg-slate-300'>{chainName}</h1>

              <ul className='px-2 mt-1 space-y-4'>
                {unions.map(union => {
                  const { unionAddr } = union;
                  const chainId = CHAIN_ID[chainName];
                  return <UnionDetail key={unionAddr} union={union} chainId={chainId} />;
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * component
 */
function UnionDetail({ union, chainId }) {
  const { type, unionAddr, unions } = union;

  // state
  const [paymentNumber, setPaymentNumber] = useState(0);
  const [rate, setRate] = useState(0);

  // wagmi account
  const { isConnected } = useAccount();

  // wagmi read contract hook
  const [contractAddr, setContractAddr] = useState("");
  const { data: nowRate, isLoading: isLoadingNowRate } = useContractRead({
    address: isConnected && paymentNumber && Number(paymentNumber) >= 0 && contractAddr,
    abi: BavepayABI,
    functionName: "payments",
    args: [paymentNumber],
    watch: true,
    chainId,

    // 改寫回傳內容
    select: data => Number(data.rate) / 10000, // 2%(現實) --> 20000(合約)
    // error
    onError(err) {
      toast.error("read rate fail");
      console.log(chainId);
      console.log(err);
    },
  });

  // btn - update rate
  const btnConfirm = () => {
    writeContractProcess(
      chainId,
      new InjectedConnector(),
      () => updateContractRate(unionAddr, paymentNumber, rate),
      { toast }
    );
  };

  /**
   * 更新合約手續費
   * @param {string} address `0x${string}`
   * @param {number} paymentNumber 0 | 1 | 2 | ...
   * @param {number} rate 1.6 | 2 | ...
   * @returns obj
   * {
   *    hash: `0x${string}`,
   *    wait: (confirmations?: number) => Promise<TransactionReceipt>
   * }
   */
  async function updateContractRate(address, paymentNumber, rate) {
    const config = await prepareWriteContract({
      address,
      abi: BavepayABI,
      functionName: "setRate",
      args: [paymentNumber, rate * 10000],
    });

    return await writeContract(config);
  }

  return (
    <li>
      <h1 className='flex items-center gap-4 text-lg font-bold text-gray-500'>
        <span>Type: {type}</span>
        {/* <span>{getShortCryptoAddress(unionAddr)}</span> */}
        <span>Contract: {unionAddr}</span>
      </h1>

      <main className='flex items-center'>
        <div>
          <label>{unions}P</label>
          <input
            type='number'
            onChange={e => {
              const paymentNumber = e.target.value;
              // const isPaymentNumber = Number(paymentNumber) >= 0 && !isNaN(paymentNumber);
              // if (!isPaymentNumber) return alert("wrong number");
              setContractAddr(unionAddr);
              setPaymentNumber(paymentNumber);
            }}
            className='w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        <div className='ml-5'>
          <label>Rate</label>
          <input
            type='number'
            onChange={e => setRate(e.target.value)}
            className='w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
          <span>%</span>

          <button
            disabled={rate <= 0}
            className='ml-2 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 disabled:bg-gray-300'
            onClick={btnConfirm}
          >
            set rate
          </button>
        </div>

        {isLoadingNowRate ? <p className='animate-pulse'>loading</p> : nowRate && <p>{nowRate}%</p>}
      </main>
    </li>
  );
}
