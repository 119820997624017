import React from "react";

// import { ethers } from "ethers";

const Wallet = window.sessionStorage.wallet;
const WalletToken = window.sessionStorage.walletToken;

export const WalletContext = React.createContext(null);

const WalletContextProvider = ({ children }) => {
  const [walletAccount, setWalletAccount] = React.useState(Wallet || "");
  const [provider, setProvider] = React.useState(null);

  // 暫時使用
  const [walletToken, setWalletToken] = React.useState(WalletToken || "");

  /**
   *    listen to account changed
   */
  React.useEffect(() => {
    const handleAccountChanged = accounts => {
      // setWalletAccount(ethers.utils.getAddress(accounts[0]));
      // console.log("Change wallet to:", accounts[0]);

      // 切鏈的話就登出
      setWalletAccount("");
    };

    if (window?.ethereum) window.ethereum.on("accountsChanged", handleAccountChanged);

    return () => {
      if (window?.ethereum) window.ethereum.removeListener("accountsChanged", handleAccountChanged);
    };
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletAccount,
        setWalletAccount,
        provider,
        setProvider,
        walletToken,
        setWalletToken,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletContextProvider;
