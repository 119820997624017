import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "English", shortName: "EN" },
  zh: { nativeName: "中文", shortName: "中文" },
};

export default function Example() {
  const { i18n } = useTranslation();
  const nowLng = lngs[i18n.resolvedLanguage].shortName;

  return (
    <div className=''>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            {nowLng}
            {/* <ChevronDownIcon
              className='w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100'
              aria-hidden='true'
            /> */}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-28 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {Object.keys(lngs).map((lng, idx) => {
              // const isSelected = i18n.resolvedLanguage === lng;

              return (
                <div className='px-1 py-1' key={idx}>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        {/* {active ? (
                          <EditActiveIcon className='w-5 h-5 mr-2' aria-hidden='true' />
                        ) : (
                          <EditInactiveIcon className='w-5 h-5 mr-2' aria-hidden='true' />
                        )} */}
                        {lngs[lng].nativeName}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
