import "flowbite";
import { useState } from "react";
import { Link } from "react-router-dom";

/**
 * @param { {isPrivate: boolean, label: string, path: string}[] } links
 */
const NavbarForAll = ({ user, logout, links }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onLogout = () => {
    logout();
  };

  return (
    <header>
      <div className='flex justify-between items-center p-3 bg-[#f1f1f1]'>
        {/* bavepay 的網頁要另外載入，不能用 Link */}
        <a href='/'>
          <h1 className='text-2xl'>Backerpay</h1>
        </a>

        <nav>
          <div className='relative flex items-center gap-3'>
            <button
              className='text-black font-medium rounded-lg text-sm px-2.5 py-2.5 text-center inline-flex items-center'
              type='button'
              onClick={() => setIsMenuOpen(prev => !prev)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-6 h-6'
              >
                <path
                  fillRule='evenodd'
                  d='M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z'
                  clipRule='evenodd'
                />
              </svg>
            </button>

            {/* <!-- Dropdown menu --> */}
            <div
              className={`
                  ${isMenuOpen ? "" : "hidden"} 
                  absolute top-0 right-0
                  z-10 min-w-max bg-white rounded divide-y divide-gray-100 shadow`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className='px-4 py-3 text-gray-900'>
                <div className='text-base font-bold text-center'>{user?.account}</div>
              </div>

              <ul
                className='py-1 text-sm text-gray-700'
                aria-labelledby='dropdownInformationButton'
              >
                {links.map(link => {
                  if (link.isPrivate && !user?.token) return "";
                  return (
                    <li key={link.path}>
                      <Link to={link.path} className='block px-4 py-2 hover:bg-gray-100'>
                        {link.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className='py-1'>
                <button
                  className='w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                  onClick={onLogout}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavbarForAll;
