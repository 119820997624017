import React from "react";
import { Outlet } from "react-router-dom";

// componets
import NavbarForAll from "../components/NavbarForAll";

// hooks
import { useVendorAuth } from "../context/VendorAuthContext";

const LayoutVendor = ({ links }) => {
  const { vendor, dispatch } = useVendorAuth();
  const logout = () => dispatch({ type: "logout" });

  return (
    <main className='flex flex-col w-full h-screen App'>
      <NavbarForAll user={vendor} logout={logout} links={links} />
      <Outlet />
    </main>
  );
};

export default LayoutVendor;
