import React from "react";

import { ethers } from "ethers";
import { useParams } from "react-router-dom";

// api
import axiosBackerpay from '../../api/BackerpayApi'
import newcoinApi from "../../api/NewcoinApi";

// urls
const URL_CREATE_NEWCOIN_VENDOR = "/platformapi/create_newcoin_vendor";
const URL_GET_VENDER = "/vender/get_vender";
const URL_GET_STATUS = "/vender/get_status";

// data sample
/*
const SAMPLE = {
  unionId: "F0U1P0",
  name: "新思",
  tel: "0911555999",
  email: "newcoin@gmail.com",
  url: "https://www.newcoin.com.tw",
  remark: "newcoin01", // 店名
  clientAcct: "0xBab30D77441A4aca44062DEA15A39A3D6BfE3094",
  clientOper: "0x6635d23f0113BB5c9cE71CeA374a5bA63366b0F0", // KYC 用戶錢包地址
  tokens: "0xc439CDc77b80F1A59f346AC5245810528A5B2932",

  // newcoin 專用
  contact_person: "Lee",
  contact_tel: "12354654135",
  company_address: "台北市",
};
*/

// regex patterns
const regexPatterns = {
  tel: /^[0-9]*$/,
  contact_tel: /^[0-9]*$/,
  clientAcct: /^(0x)\w{40}/i,
  clientOper: /^(0x)\w{40}/i,
  email: /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
  //             yourname @ domain   .  com          ( .uk )
};

// error message
const errorMessages = {
  tel: "公司電話錯誤",
  contact_tel: "聯絡人電話錯誤",
  clientAcct: "收款地址錯誤",
  clientOper: "操作地址錯誤",
  email: "信箱錯誤",
};

export default function NewcoinRegister() {
  const { walletAddress } = useParams(); // url 有帶入walletAddress，則不用手動輸入 vendorWallet

  const [isLoading, setIsLoading] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState("");
  const [serverErr, setServerErr] = React.useState("");
  const [inputErrs, setInpuErrs] = React.useState([]);
  const [walletErr, setWalletErr] = React.useState("");

  const [vendorWallet, setVendorWallet] = React.useState("");
  const [kycData, setKycData] = React.useState({});

  // validation function
  function validate(e, regex) {
    const errMsg = errorMessages[e.target.id];

    if (regex.test(e.target.value)) {
      // console.log("yes");
      e.target.classList.remove("bg-red-100");
      const _inputErr = inputErrs.filter(err => err !== errMsg);
      setInpuErrs(_inputErr);

      //
    } else {
      // console.log("no");
      e.target.classList.add("bg-red-100");
      if (!inputErrs.includes(errMsg)) setInpuErrs(errs => [...errs, errMsg]);
    }
  }

  const handleInputChange = (e, o) => {
    const key = e.target.id;
    const pattern = regexPatterns[key];
    pattern && validate(e, pattern);
    setKycData(o);
  };

  /**
   *    handle submit
   */
  const handleSubmit = async e => {
    e.preventDefault();

    setServerSuccess("");
    setServerErr("");

    try {
      const response = await axiosBackerpay.post(URL_CREATE_NEWCOIN_VENDOR, kycData);
      if (response?.data?.op_code === "1") {
        setServerSuccess("店家建立完成");
      } else if (response?.data?.op_code === "-1") {
        setServerSuccess("店家已存在");
      } else {
        setServerSuccess("店家建立失敗");
      }
      console.log(response.data);
    } catch (err) {
      setServerErr("Server Error!!!");
      console.error(err);
    }
  };

  React.useEffect(() => {
    const _getToken = async address => {
      try {
        const response = await newcoinApi.post(URL_GET_STATUS, { address });
        const token = response?.data?.token;
        if (token) return token;
      } catch (err) {
        console.error(err);
      }
    };

    const _getNewcoinVendor = async address => {
      try {
        // get token
        const token = await _getToken(address);
        if (!token) {
          setServerErr("此地址不是新思幣廠商");
          throw new Error("no token");
        }

        // get vendor
        const response = await newcoinApi.get(URL_GET_VENDER, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: { address },
        });

        if (response?.data?.op_code === "1") {
          return response.data;
        } else {
          console.log("沒有拿到廠商資料", response);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const initVendorKycData = async () => {
      setIsLoading(true);

      const newcoin = await _getNewcoinVendor(walletAddress || vendorWallet);

      const company_address = newcoin?.company_address;
      const company_name = newcoin?.company_name;
      const company_url = newcoin?.company_url;
      const contact_eamil = newcoin?.contact_eamil;
      const contact_person = newcoin?.contact_person;
      const contact_phone = newcoin?.contact_phone;

      const vendorKycData = {
        // company
        unionId: "F0U0",
        storeId: "",
        name: company_name,
        tel: contact_phone,
        email: contact_eamil,
        url: company_url,
        remark: "",
        clientAcct: walletAddress || vendorWallet,
        clientOper: walletAddress || vendorWallet,
        tokens: [process.env.REACT_APP_NEWCOIN_CONTRACT_ADDRESS],
        rate: 0,

        // newcoin 專用
        contact_person,
        contact_tel: contact_phone,
        company_address,
      };

      console.log("load vendor kyc data");
      setKycData(vendorKycData);
      setIsLoading(false);
    };

    if (ethers.utils.isAddress(walletAddress || vendorWallet)) {
      initVendorKycData();
    } else {
      setKycData({});
    }
  }, [walletAddress, vendorWallet]);

  return (
    <div className='w-full max-w-[600px] mx-auto my-5 p-5 rounded-lg bg-[#f1f1f1]'>
      {/* loading */}
      {isLoading && (
        <div className='fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-100/50'>
          <div
            className='inline-block w-8 h-8 border-4 rounded-full spinner-border animate-spin'
            role='status'
          >
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}

      <h1 className='text-2xl font-bold text-center'>新思幣收款連結建立</h1>
      <p className='text-sm text-center'>送出後需 1-3 個工作天審核，審核完成後會 Email 通知</p>

      {/* error message */}
      <div
        className={`
            p-2 my-3
            text-center font-bold rounded
            bg-red-200 text-rose-700 
            ${serverErr ? "" : "hidden"}
          `}
      >
        <p>{serverErr}</p>
      </div>

      <form onSubmit={handleSubmit}>
        <h2 className='text-xl'>公司資料</h2>

        <section className='flex flex-col gap-3 my-3'>
          {/* vendor wallet */}
          {!walletAddress && (
            <div className=''>
              <label
                htmlFor='vendor_wallet'
                className='block mb-2 text-sm font-medium text-gray-900 '
              >
                廠商錢包
              </label>
              <input
                id='vendor_wallet'
                type='text'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                value={vendorWallet}
                onChange={e => {
                  if (!ethers.utils.isAddress(e.target.value)) {
                    setWalletErr("地址格式錯誤");
                  } else {
                    setServerErr("");
                    setWalletErr("");
                  }
                  setVendorWallet(e.target.value);
                }}
              />
              <p className='mt-2 text-sm text-red-600 dark:text-red-500'>
                <span className='font-medium'>{walletErr}</span>
              </p>
            </div>
          )}

          <div>
            <label htmlFor='unionId' className='block mb-2 text-sm font-medium text-gray-900 '>
              Union ID
            </label>
            <input
              id='unionId'
              type='text'
              className='cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              disabled={true}
              value={kycData.unionId || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 '>
              公司 Email
            </label>
            <input
              id='email'
              type='email'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.email}
              required
              value={kycData.email || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='name' className='block mb-2 text-sm font-medium text-gray-900 '>
              公司名稱
            </label>
            <input
              id='name'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.name}
              required
              value={kycData.name || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='tel' className='block mb-2 text-sm font-medium text-gray-900 '>
              公司電話
            </label>
            <input
              id='tel'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.tel}
              required
              value={kycData.tel || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='url' className='block mb-2 text-sm font-medium text-gray-900 '>
              公司網址
            </label>
            <input
              id='url'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.url}
              required
              value={kycData.url || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='remark' className='block mb-2 text-sm font-medium text-gray-900 '>
              店家名稱
            </label>
            <input
              id='remark'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.remark}
              required
              value={kycData.remark || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='clientAcct' className='block mb-2 text-sm font-medium text-gray-900 '>
              錢包收款地址
            </label>
            <input
              id='clientAcct'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.clientAcct}
              required
              value={kycData.clientAcct || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='clientOper' className='block mb-2 text-sm font-medium text-gray-900 '>
              錢包操作地址
            </label>
            <input
              id='clientOper'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.clientOper}
              required
              value={kycData.clientOper || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='token' className='block mb-2 text-sm font-medium text-gray-900 '>
              支付幣種
            </label>
            <input
              id='token'
              type='text'
              className='cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              disabled
              value={kycData.tokens || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>
        </section>

        <div className='my-3 border-b-2 border-black'></div>

        <h2 className='text-xl'>聯絡人資料</h2>
        <section className='flex flex-col gap-3 my-3'>
          <div>
            <label
              htmlFor='contact_person'
              className='block mb-2 text-sm font-medium text-gray-900 '
            >
              聯絡人
            </label>
            <input
              id='contact_person'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.contact_person}
              required
              value={kycData.contact_person || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label htmlFor='contact_tel' className='block mb-2 text-sm font-medium text-gray-900 '>
              聯絡人電話
            </label>
            <input
              id='contact_tel'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.contact_tel}
              required
              value={kycData.contact_tel || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>

          <div>
            <label
              htmlFor='company_address'
              className='block mb-2 text-sm font-medium text-gray-900 '
            >
              公司地址
            </label>
            <input
              id='company_address'
              type='text'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
              placeholder={kycData.company_address}
              value={kycData.company_address || ""}
              onChange={e => {
                const o = { ...kycData };
                o[e.target.id] = e.target.value;
                handleInputChange(e, o);
              }}
            />
          </div>
        </section>

        {/* <p class='my-3 text-sm text-red-600'>
          <span class='font-medium'>Oops!</span> Username already taken!
        </p> */}

        <button
          type='submit'
          className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'
          disabled={inputErrs.length > 0}
        >
          Submit
        </button>

        {/* success message */}
        <div
          className={`
            p-2 my-3
            text-center font-bold rounded
            bg-green-200 text-green-900
            ${serverSuccess ? "" : "hidden"}
          `}
        >
          <p>{serverSuccess}</p>
        </div>
      </form>
    </div>
  );
}
