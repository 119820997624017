import "./i18n";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";

// context
import WalletContextProvider from "./context/WalletContext";
import { VendorAuthContextProvider } from "./context/VendorAuthContext";
import { AdminAuthContextProvider } from "./context/AdminAuthContext";
import { WagmiProvider } from "./context/WagmiContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiProvider>
        <WalletContextProvider>
          <AdminAuthContextProvider>
            <VendorAuthContextProvider>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </VendorAuthContextProvider>
          </AdminAuthContextProvider>
        </WalletContextProvider>
      </WagmiProvider>
    </BrowserRouter>
  </React.StrictMode>
);
