import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { navbar, footer, index, registration, memberprofile } from "./i18n-translate";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "zh",
    resources: {
      zh: {
        translation: {
          ...navbar.zh,
          ...footer.zh,
          ...index.zh,
          ...registration.zh,
          ...memberprofile.zh,
        },
      },
      en: {
        translation: {
          ...navbar.en,
          ...footer.en,
          ...index.en,
          ...registration.en,
          ...memberprofile.en,
        },
      },
    },
  });
