import React from "react";
import Select from "react-select";

import { Card, Button, Textarea } from "flowbite-react";

// components
import UserKyc from "../components/UserKyc";

export default function Edit3wKyc({ kycData, dispatch, editKycData }) {
  // console.log(kycData);
  const [risk, setRisk] = React.useState(kycData?.risk || ""); // 低 | 高
  const [remark, setRemark] = React.useState(kycData?.remark || "");

  return (
    <div className='flex flex-col gap-3'>
      <Card>
        <section className='flex gap-3'>
          {/* 用戶資料 */}
          <UserKyc
            user={{
              name: kycData?.name,
              birthDay: new Date(kycData?.birthDay), // 要以 new Date() 的型別送入
              nationality: kycData?.nationality,
              isDoubleNationality: kycData?.isDoubleNationality === "1" ? true : false, // 後端回傳是 '1' | '0'，需轉換 true | false
              idNumber: kycData?.idNumber,
              issueDay: new Date(kycData?.issueDay), // 要以 new Date() 的型別送入
              issuePlace: kycData?.issuePlace,
              idAddress: kycData?.issuePlace,
              idType: kycData?.idType, // "初發 | 換發 | 補發"
            }}
            // btnName={"修改"}
            callback={(type, inputs) => {
              // console.log(type, inputs);
              // console.log(kycData)
              // editKycData("update", { ...kycData, ...inputs });
            }}
          />

          {/* 身分證 */}
          <div className='w-full flex flex-col justify-center items-center'>
            <img className='max-w-xs w-full' src={kycData.idPhotoHead} alt='' />
            <img className='max-w-xs w-full' src={kycData.idPhotoTail} alt='' />
          </div>
        </section>
      </Card>

      <Card>
        <h2 className='text-4xl font-bold'>註記</h2>

        <div className='flex-grow'>
          <label htmlFor='id_type' className='form-label inline-block mb-2 text-gray-700'>
            風險
          </label>

          <Select
            required
            isSearchable={false}
            options={isRiskOptions}
            defaultValue={isRiskOptions.find(option => option.value === risk)}
            onChange={({ label, value }) => {
              setRisk(value);
            }}
          />
        </div>

        <Textarea
          id='comment'
          className='bg-[#fff]'
          placeholder='退件原因'
          required={true}
          rows={4}
          value={remark}
          onChange={e => setRemark(e.target.value)}
        />

        <div className='flex justify-center items-center gap-3'>
          <Button
            color='success'
            disabled={!risk}
            onClick={() => {
              editKycData("approve_kyc", { ...kycData, risk, remark });
              dispatch({ type: "close_edit" });
            }}
          >
            核准
          </Button>
          <Button
            color='failure'
            disabled={!risk}
            onClick={() => {
              editKycData("reject_kyc", { ...kycData, risk, remark });
              dispatch({ type: "close_edit" });
            }}
          >
            退件
          </Button>
          <Button
            color='gray'
            className=''
            onClick={() => {
              dispatch({ type: "close_edit" });
            }}
          >
            取消
          </Button>
        </div>
      </Card>
    </div>
  );
}

/**
 *    is risk
 */
const isRiskOptions = [
  { label: "低", value: "低" },
  { label: "高", value: "高" },
];
