import React from "react";

import "./dist/css/main.css";
import "./dist/css/baveshop.css";

import useScript from "../../hooks/useScript";

export default function Baveshop() {
  // useScript("dist/js/main.js");
  useScript("dist/js/faq.js");

  return (
    <div>
      <main>
        <div className='banner-section'>
          <div className='bg'>
            <div className='banner pc' style={{ backgroundImage: "url(dist/images/banner.jpg)" }} />
            <div
              className='banner mo'
              style={{ backgroundImage: "url(dist/images/banner_mb.jpg)" }}
            />
          </div>
          <div className='container'>
            <h1 className='main-title'>BAVE SHOP</h1>
          </div>
          <div className='yel-ball' />
        </div>
        <div className='tag-section'>
          <div className='container'>
            <div id='top-menu-ul' className='top-menu-ul'>
              <div className='item_Menu'>
                <div className='item_menu_Box'>
                  <ul className='item_menu_list slides'>
                    <li className='active'>
                      <a href='/#'>全部</a>
                    </li>
                    <li>
                      <a href='/#'>優惠組合</a>
                    </li>
                    <li>
                      <a href='/#'>GAS加值</a>
                    </li>
                    <li>
                      <a href='/#'>會員資格</a>
                    </li>
                    <li>
                      <a href='/#'>優惠組合</a>
                    </li>
                    <li>
                      <a href='/#'>GAS加值</a>
                    </li>
                    <li>
                      <a href='/#'>購買問題</a>
                    </li>
                    <li>
                      <a href='/#'>新手專區</a>
                    </li>
                    <li>
                      <a href='/#'>註冊問題</a>
                    </li>
                    <li>
                      <a href='/#'>購買問題</a>
                    </li>
                    <li>
                      <a href='/#'>新手專區</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='flex-direction-nav'>
                <a href='/#' className='lbtn flex-prev'>
                  <div />
                </a>
                <a href='/#' className='rbtn flex-next'>
                  <div />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='product-section'>
          <div className='container'>
            <ul>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值GAS加值GAS加值GAS</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包GAS超值包GAS超值包GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
              <li>
                <a href='/#'>
                  <div className='product-img'>
                    <div className='tag fw7'>GAS加值</div>
                    <div className='imgwrap'>
                      <img src='dist/images/product.png' alt='' />
                    </div>
                  </div>
                  <div className='product-name f18 fw7'>GAS超值包</div>
                  <div className='product-price f24 fw7'>NTD 75</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='page-section'>
          <div className='container'>
            <dl className='page'>
              {/* 若沒有上一則或下一則請使用 nopage */}
              <dt className='ltbn nopage'>
                <a href='/#'>
                  <span />
                </a>
              </dt>
              <dd className='active'>
                <a href='/#'>1</a>
              </dd>
              <dd>
                <a href='/#'>2</a>
              </dd>
              <dd>
                <a href='/#'>3</a>
              </dd>
              <dd>
                <a href='/#'>4</a>
              </dd>
              <dd>
                <a href='/#'>5</a>
              </dd>
              <dt className='rtbn'>
                <a href='/#'>
                  <span />
                </a>
              </dt>
            </dl>
          </div>
        </div>
      </main>

      <div className='top_btn' />
    </div>
  );
}
