import "./dist/css/main.css";
import "./dist/css/member-profile.css";

import { useState } from "react";
import useSWR from "swr";
import ExcelJs from "exceljs";
import moment from "moment-timezone";
import bigDecimal from "js-big-decimal";
import { InjectedConnector } from "@wagmi/core";
import toast, { Toaster } from "react-hot-toast";

// api
import {
  getStoreById,
  getVendorWithdrawList,
  applyTWDBalanceWithdraw,
  getPaymentReport,
} from "../../api/BackerpayApi_2nd";

// contract
import { CHAIN_ID } from "../../contract/ChainInfo";
import CONTRACT_ABI from "../../contract/BavepayABI.json";

// components
import Spinner from "../../components/Spinner/Spinner";
import EnterDatetimeModal from "../../components/Bavepay/MemberProfile/EnterDatetimeModal";

// hooks
import useScript from "../../hooks/useScript";
import { useVendorAuth } from "../../context/VendorAuthContext";

// utils
import {
  isOnline,
  isWithdrawByTwd,
  getPaymentNumber,
  getShortCryptoAddress,
} from "../../utils/tools";
import { errorTokenHasExpired } from "../../api/ErrorHelper";
import {
  connectContract,
  connectWallet,
  writeConfigToContract,
  switchChainId,
  waitContract,
} from "../../utils/WagmiTool/index";

export default function MemberProfile() {
  // loading
  const [isLoading, setIsLoading] = useState(false);

  // dowload report
  const [openDatetimeModal, setOpenDatetimeModal] = useState(false);
  const [downloadData, setDownloadData] = useState({ token: "", paymentId: "" });

  // vendor auth
  const { vendor, dispatch: vendorDispatch } = useVendorAuth();
  const logout = () => vendorDispatch({ type: "logout" });

  /**
   * 下載報表
   * @param {Date，由 EnterDatetimeModal 傳回} startDatetime
   * @param {Date，由 EnterDatetimeModal 傳回} endDatetime
   * @returns
   */
  const handleDownloadReport = async (downloadData, startDatetime, endDatetime) => {
    const { token, paymentId } = downloadData;

    if (!startDatetime || !endDatetime) return alert("neet to set time interval");
    if (!token) return alert("dowload report without token");
    if (!paymentId) return alert("no payment ID");

    // 傳給後端的 Unix time 要是 10 碼
    const fromUnixTime = Math.floor(startDatetime.valueOf() / 1000);
    const toUnixTime = Math.floor(endDatetime.valueOf() / 1000);

    try {
      setIsLoading(false);

      const data = { paymentId, fromUnixTime, toUnixTime };
      const report = await getPaymentReport(token, data);
      const { orders, withdrawRecords, refundRecords } = report;

      // 產出報表
      await produceExcel(startDatetime, endDatetime, orders, withdrawRecords, refundRecords);

      // errors
    } catch (err) {
      toast.error(err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 按鈕 - 報表
   */
  const btnReport = async (store, payment) => {
    const { paymentId } = payment;

    setDownloadData({ paymentId, token: vendor.token });
    setOpenDatetimeModal(true);
  };

  /**
   * 按鈕 - 提領
   */
  const btnWithdraw = async (store, payment) => {
    const { withdrawType } = payment;
    // console.log(withdrawType);

    if (withdrawType.toUpperCase() === "TWD") {
      await withdrawTWDBalance(vendor.token, store, payment);
    } else if (withdrawType.toUpperCase() === "CRYPTO") {
      await withdrawCryptoBalance(vendor.token, store, payment);
    } else {
      toast.error(`${withdrawType} is not allowed withdraw type (TWD or Crypto)`);
    }
  };

  /**
   * 台幣提領
   * @param {*} token
   * @param {*} store
   * @param {*} payment
   * @returns
   */
  async function withdrawTWDBalance(token, store, payment) {
    if (isLoading) return;

    const { paymentId } = payment;

    try {
      setIsLoading(true);

      const res = await applyTWDBalanceWithdraw(token, paymentId);
      toast.success(`${paymentId} apply withdraw ${res.orderTotal}`);
      console.log(`${paymentId} apply withdraw ${res.orderTotal}`);

      // errors
    } catch (err) {
      const errorExpired = errorTokenHasExpired(err, [
        () => toast.error("Token has expired \nLogout after 3 seconds"),
        () => setTimeout(() => logout(), 3000),
      ]);

      // other errors
      !errorExpired && toast.error(err?.response?.data?.message || err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * 加密貨幣提領
   * @param {*} token
   * @param {*} store
   * @param {*} payment
   */
  async function withdrawCryptoBalance(token, store, payment) {
    if (isLoading) return;

    const { storeId } = store;
    const { chainName, paymentId } = payment;

    const chainId = CHAIN_ID[chainName] || 80001;
    const connector = new InjectedConnector();

    try {
      setIsLoading(true);

      // 1. connect wallet
      await connectWallet(chainId, connector);
      // 2. switch chain
      await switchChainId(chainId);
      // 3. get union address
      const currentStore = await getStoreById(token, storeId);
      const { unionAddr } = currentStore[0];
      // 4. connect contract
      const args = [getPaymentNumber(paymentId)];
      const config = await connectContract(unionAddr, CONTRACT_ABI, "withdrawAll", args);
      // 5. write contract
      const res = await writeConfigToContract(config);
      // 6. wait contract
      const isSuccess = await waitContract(res?.hash, res?.wait);
      isSuccess && toast.success("transaction success");

      // errors
    } catch (err) {
      toast.error(err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Backend Data
   * 帳戶資料
   */
  const [listOfStore, setListOfStore] = useState([]);

  const { isLoading: isLoadingStore } = useSWR(
    ["/getVendorWithdrawList", vendor.token],
    ([fakeURL, token]) => getVendorWithdrawList(token),
    {
      onSuccess(data) {
        setListOfStore(data);
        console.log(data);
      },

      onError(err) {
        const expiredError = errorTokenHasExpired(err, [
          () => toast.error("Token has expired \nPlease login again"),
          setTimeout(() => logout(), 3000),
        ]);

        !expiredError && toast.error(err.message);
        console.error(err);
      },
    }
  );

  /**
   *    import js script
   */
  useScript("dist/js/main.js");
  useScript("dist/js/member-profile.js");
  // useScript("https://fastly.jsdelivr.net/npm/echarts@5.3.3/dist/echarts.min.js");

  return (
    <div>
      <div>
        <Toaster />
      </div>

      {/* loading */}
      <Spinner isLoading={isLoading || isLoadingStore} />

      {/* enter datetime */}
      <EnterDatetimeModal
        open={openDatetimeModal}
        setOpen={setOpenDatetimeModal}
        confirmCallback={(startDateTime, endDateTime) => {
          // set downloadData from btnReport
          handleDownloadReport(downloadData, startDateTime, endDateTime);
        }}
      />

      <main>
        <div className='banner-section'>
          <div className='bg'>
            <div className='banner pc' style={{ backgroundImage: "url(dist/images/banner.jpg)" }} />
            <div
              className='banner mo'
              style={{ backgroundImage: "url(dist/images/banner_mb.jpg)" }}
            />
          </div>
          <div className='container'>
            <h1 className='main-title'>帳戶總覽</h1>
          </div>
          <div className='yel-ball' />
        </div>
        <div className='tag-section'>
          <div className='container'>
            <div id='top-menu-ul' className='top-menu-ul'>
              <div className='item_Menu'>
                <div className='item_menu_Box'>
                  <ul className='item_menu_list slides'>
                    <li>
                      {/* <a href='/#' data-id='balance'>
                        帳戶餘額
                      </a> */}
                    </li>
                    <li>
                      <a href='#record' data-id='record'>
                        帳戶紀錄
                      </a>
                    </li>
                    <li>
                      <a href='#announcement' data-id='announcement'>
                        公告中心
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='flex-direction-nav'>
                <a href='/#' className='lbtn flex-prev'>
                  <div />
                </a>
                <a href='/#' className='rbtn flex-next'>
                  <div />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* 圓餅圖 */}
        {/* <div className='balance-section' id='balance'>
          <div className='container'>
            <div className='white-bg'>
              <div className='account-info '>
                <div className='balance'>
                  <div className='balance-title'>
                    <h2 className='f30-20 fw7'>帳戶餘額</h2>
                    <div className='btnwrap'>
                      <a className='btn' href='/#' id='view'>
                        隱藏資產估值
                        <span className='eye' />
                      </a>
                    </div>
                  </div>
                  <div className='balance-amount'>
                    <span className='open'>0.02133332</span>
                    <span className='close'>**********</span>&nbsp;<i>BTC</i>
                  </div>
                </div>
                <div className='total'>
                  <div className='total-title'>
                    <h2 className='f30-20 fw7'>總估值</h2>
                  </div>
                  <div className='total-amount'>
                    NT$&nbsp;<span className='open'>12,176.32</span>
                    <span className='close'>**********</span>
                  </div>
                </div>
              </div>
              <div className='pie-chart'>
                <div className='title' />
                <div id='chart-container'></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='record-section' id='record'>
          <div className='container'>
            <div className='title-wrap'>
              <h2 className='f30-20 fw7'>帳戶紀錄</h2>
              {/* 若沒有上一則或下一則請使用 nopage */}
              {/* <dl className='page'>
                <dt className='ltbn nopage'>
                  <a href='/#'>
                    <span />
                  </a>
                </dt>
                <dd className='active'>
                  <a href='/#'>1</a>
                </dd>
                <dd>
                  <a href='/#'>2</a>
                </dd>
                <dd>
                  <a href='/#'>3</a>
                </dd>
                <dd>
                  <a href='/#'>4</a>
                </dd>
                <dd>
                  <a href='/#'>5</a>
                </dd>
                <dt className='rtbn'>
                  <a href='/#'>
                    <span />
                  </a>
                </dt>
              </dl> */}
            </div>

            <div className='white-bg'>
              <ul className='table-title desk'>
                <li className='t1'>店家名稱</li>
                <li className='t2'>
                  未提領餘額<br></br> (TWD)
                </li>
                <li className='t2'>加密貨幣數量</li>
                {/* <li className='t3'>日期</li> */}
                <li className='t3'>鏈-幣種</li>
                <li className='t3'>類型</li>
                <li className='t3'>出金方式</li>
                <li className='t3' />
              </ul>

              <div className='table-title mobile'>
                商城名稱 / 未提領餘額 (TWD) / 加密貨幣數量 / 鏈-幣種 / 類型 / 出金方式
              </div>

              {/* payments data */}
              {isLoadingStore && listOfStore.length === 0 ? (
                <div className='p-4 text-2xl animate-pulse'>讀取中...</div>
              ) : (
                <ul className='break-words lists'>
                  {listOfStore.map(({ store }) => {
                    return store.payments.map(payment => {
                      const totalTWD = getTWDBalance(payment.tokens);

                      return (
                        <li key={payment.paymentId} className='table-content'>
                          <div className='mb-wrap'>
                            <div className='t1'>
                              <p className='font-bold'>{store.name}</p>
                              <p>{store.remark}</p>
                              <p className='mt-1 text-xs text-gray-500'>{payment.paymentId}</p>
                            </div>

                            <div className='t2'>{new bigDecimal(totalTWD).getPrettyValue()}</div>

                            <div className='t2'>
                              <button className='p-2 text-white rounded-lg peer bg-slate-500'>
                                show
                              </button>
                              <div className='fixed inset-0 z-50 hidden bg-black/50 peer-focus-within:block'>
                                <CryptoBalanceModal tokens={payment.tokens} />
                              </div>
                            </div>

                            <div className='t3 overflow-clip'>{payment.chainName}</div>

                            <div className='t3'>
                              {isOnline(payment.paymentId) ? "線上" : "實體"}
                            </div>

                            <div className='t3'>
                              {isWithdrawByTwd(payment.withdrawType) ? (
                                <div>
                                  <p>台幣出金</p>
                                </div>
                              ) : (
                                <div>
                                  <p>加密貨幣出金</p>
                                  <p className='mt-1 text-xs text-gray-500'>
                                    操作地址 {getShortCryptoAddress(payment.clientOper)}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='flex flex-col items-center h-full gap-3 t4 btns justif-between'>
                            <div className='btnwrap'>
                              <button
                                className='btn grey'
                                onClick={() => btnReport(store, payment)}
                              >
                                報表
                              </button>
                            </div>
                            <div className='btnwrap'>
                              <button
                                className='btn yel'
                                onClick={() => {
                                  if (totalTWD <= 0) return toast.error("balance not enough");
                                  btnWithdraw(store, payment);
                                }}
                              >
                                提領
                              </button>
                            </div>
                          </div>
                        </li>
                      );
                    });
                  })}

                  {/* <li className='table-content'>
                  <div className='mb-wrap'>
                    <div className='t1'>Iphone手機殼商城</div>
                    <div className='t2'>12,000</div>
                    <div className='t3'>2022.08.15</div>
                  </div>
                  <div className='t4 btns'>
                    <div className='btnwrap'>
                      <a className='btn grey' href='/#'>
                        明細
                      </a>
                    </div>
                    <div className='btnwrap'>
                      <a className='btn yel' href='/#'>
                        提領
                      </a>
                    </div>
                  </div>
                </li>
                <li className='table-content'>
                  <div className='mb-wrap'>
                    <div className='t1'>Iphone手機殼商城</div>
                    <div className='t2'>12,000</div>
                    <div className='t3'>2022.08.15</div>
                  </div>
                  <div className='t4 btns'>
                    <div className='btnwrap'>
                      <a className='btn grey' href='/#'>
                        明細
                      </a>
                    </div>
                    <div className='btnwrap'>
                      <a className='btn yel' href='/#'>
                        提領
                      </a>
                    </div>
                  </div>
                </li>
                <li className='table-content'>
                  <div className='mb-wrap'>
                    <div className='t1'>Iphone手機殼商城</div>
                    <div className='t2'>12,000</div>
                    <div className='t3'>2022.08.15</div>
                  </div>
                  <div className='t4 btns'>
                    <div className='btnwrap'>
                      <a className='btn grey' href='/#'>
                        明細
                      </a>
                    </div>
                    <div className='btnwrap'>
                      <a className='btn yel' href='/#'>
                        提領
                      </a>
                    </div>
                  </div>
                </li>
                <li className='table-content'>
                  <div className='mb-wrap'>
                    <div className='t1'>Iphone手機殼商城</div>
                    <div className='t2'>12,000</div>
                    <div className='t3'>2022.08.15</div>
                  </div>
                  <div className='t4 btns'>
                    <div className='btnwrap'>
                      <a className='btn grey' href='/#'>
                        明細
                      </a>
                    </div>
                    <div className='btnwrap'>
                      <a className='btn yel' href='/#'>
                        提領
                      </a>
                    </div>
                  </div>
                </li> */}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className='announcement-section' id='announcement'>
          <div className='imgwrap'>{/* <img src='dist/images/member.png' alt='' /> */}</div>
          <div className='container'>
            <h2 className='f36-20 fw7'>公告中心</h2>
            <ul>
              <li>
                <div className='date'>2022.12.15</div>
                <div className='headline'>BackerPay測試版正式在師園鹽酥雞上線囉！</div>
              </li>
              {/* <li>
                <div className='date'>2022.08.16</div>
                <div className='headline'>系統維修公告，時間：8/20凌晨2點至4點</div>
              </li>
              <li>
                <div className='date'>2022.08.16</div>
                <div className='headline'>系統維修公告，時間：8/20凌晨2點至4點</div>
              </li>
              <li>
                <div className='date'>2022.08.16</div>
                <div className='headline'>
                  平台新開幕，連線錢包即可參與抽獎，邀請好友一同連線及可再獲得平台贈送的小禮物平台新開幕，連線錢包即可參與抽獎，邀請好友一同連線及可再獲得平台贈送的小禮物
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </main>

      <div className='top_btn'></div>
    </div>
  );
}

/**
 * 生產表單
 * @param {Unix time} fromUnixTime
 * @param {Unix time} toUnixTime
 * @param {訂單資料} order
 * @param {提款資料} withdraw
 */
async function produceExcel(startDatetime, endDatetime, order, withdraw, refund) {
  // 1. 建立表格
  const workbook = new ExcelJs.Workbook();
  // 2. 添加工作表
  const sheetOrder = workbook.addWorksheet("訂單");
  const sheetWithdraw = workbook.addWorksheet("提領");
  // const sheetRefund = workbook.addWorksheet("退款");
  // 3. 填塞資料
  sheetOrder.addTable({
    name: "order", // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [
      { name: "序號" }, // 1 | 2 | 3
      { name: "日期" }, // "2022-12-25"
      { name: "交易時間" }, // "17:50:48"
      { name: "廠商編號" }, // storeId
      { name: "訂單編號" }, // orderId
      { name: "銷售金額" }, // sum
      { name: "手續費" }, // fee
      { name: "手續費（稅）" }, // feeTax
      { name: "總計" }, // total
    ],
    rows: order.records
      // 添加加總在最後一筆
      .concat({
        fee: order.fee,
        feeTax: order.feeTax,
        sum: order.sum,
        total: order.total,
      })
      // 每一行資料
      .map((record, rowIdx) => {
        const { time, storeId, orderId, sum, fee, feeTax, total } = record;

        const id = time ? rowIdx + 1 : `共 ${rowIdx} 筆`;
        const yearMonthDay = time && moment(new Date(time)).format("YYYY-MM-DD");
        const hourMinSec = time && moment(new Date(time)).format("HH:mm:ss");

        return [id, yearMonthDay, hourMinSec, storeId, orderId, sum, fee, feeTax, total];
      }),
  });
  sheetWithdraw.addTable({
    name: "withdraw", // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
    ref: "A1", // 從A1開始
    columns: [
      { name: "序號" },
      { name: "日期" },
      { name: "提領時間" },
      { name: "台幣" },
      { name: "出金方式" },
      { name: "加密貨幣" },
      { name: "幣別" },
    ],
    rows: withdraw.records
      // 添加加總在最後一筆
      .concat({
        sum: withdraw.total,
      })
      // 每一行資料
      .map((record, rowIdx) => {
        const { time, withdrawType, amount, tokenSymbol, sum } = record;

        const id = time ? rowIdx + 1 : `共 ${rowIdx} 筆`;
        const yearMonthDay = time && moment(new Date(time)).format("YYYY-MM-DD");
        const hourMinSec = time && moment(new Date(time)).format("HH:mm:ss");

        return [id, yearMonthDay, hourMinSec, sum, withdrawType, amount, tokenSymbol];
      }),
  });

  // 時間
  const formatFrom = moment(new Date(startDatetime)).format("YYYYMMDD");
  const formatTo = moment(new Date(endDatetime)).format("YYYYMMDD");

  // 表格裡面的資料都填寫完成之後，訂出下載的callback function
  // 異步的等待他處理完之後，創建url與連結，觸發下載
  workbook.xlsx.writeBuffer().then(content => {
    const link = document.createElement("a");
    const blobData = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = `報表-${formatFrom}-${formatTo}.xlsx`;
    link.href = URL.createObjectURL(blobData);
    link.click();
  });
}

/**
 *
 * @param {token[]} tokens
 * token: { orderTotal: string }
 * - orderTotal: "97.9"
 * @returns number
 */
function getTWDBalance(tokens) {
  if (!Array.isArray(tokens)) throw new Error(`${tokens} is not array`);

  return tokens.reduce((total, token) => {
    const { orderTotal } = token;
    return (total += Number(orderTotal));
  }, 0);
}

/**
 *
 * @param {token[]} tokens
 * token: { orderTotal: string, tokenSymbol }
 * - orderTotal: "97.9"
 * - tokenSymbol: "USDT"
 * @returns <ul>...</ul>
 */
function CryptoBalanceModal({ tokens }) {
  return (
    <div className='flex items-center justify-center h-screen'>
      <ul className='p-10 space-y-2 bg-white rounded-xl'>
        {tokens.map(token => {
          const { tokenSymbol, orderTotal } = token;

          return (
            <li key={tokenSymbol} className='flex justify-between gap-5'>
              <span>{tokenSymbol}:</span>
              <span>{orderTotal} TWD</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
