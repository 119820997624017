import useSWR from "swr";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

// hooks
import { useVendorAuth } from "../../../context/VendorAuthContext";

// apis
import { errorTokenHasExpired } from "../../../api/ErrorHelper";
import {
  createStore,
  getBackerpayColdWallet,
  getSupportedChains,
} from "../../../api/BackerpayApi_2nd";

// components
import Form from "./components/Form";
import BackDrop from "../../../components/Spinner/BackDrop";

/**
 * 建立店家
 */
export default function CreatePayment() {
  const [isLoading, setIsLoading] = useState(false);

  const { vendor, dispatch } = useVendorAuth();
  const logout = () => dispatch({ type: "logout" });

  /**
   * 取得貝殼支付冷錢包
   */
  const [bpWallet, setBpWallet] = useState("");
  useSWR("/getBackerpayColdWallet", getBackerpayColdWallet, {
    onSuccess(data) {
      if (bpWallet) return;
      setBpWallet(data);
    },
    onError(err) {
      toast.error(err.message);
      console.error(err);
    },
  });

  /**
   * 取得支援的鏈
   */
  const [supportedChains, setSupportedChains] = useState([]);
  useSWR("/getSupportedChains", getSupportedChains, {
    onSuccess(data) {
      if (supportedChains.length > 0) return;
      setSupportedChains(data);
    },
    onError(err) {
      toast.error(err.message);
      console.error(err);
    },
  });

  /**
   * handle submit
   */
  const handleSubmit = async form => {
    if (isLoading) return;

    const formData = handleSubmitData(form);
    console.log("submit form data:\n", formData);

    try {
      setIsLoading(true);
      const res = await createStore(vendor.token, formData);
      toast.success("create success");
      console.log(res);

      // errors
    } catch (err) {
      const expiredError = errorTokenHasExpired(err, [
        () => toast.error("Token has expired \nLogout after 3 seconds"),
        () => setTimeout(() => logout(), 3000),
      ]);

      !expiredError && toast.error(err?.response?.data?.message || err.message);
      console.error(err);

      // final
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 重組 form data 資料
   */
  const handleSubmitData = form => {
    const applyRole = { company: 0, person: 1 };

    return {
      store: {
        bankAcct: form?.bankAcct, // "123456789",
        bankCode: form?.bankCode, // "123",
        bankName: form?.bankName, // "bank",
        email: form?.email, // "frankwang95174@gmail.com",
        name: form?.name, // "test store",
        remark: form?.remark, // "test100",
        applyRole: applyRole[form?.applyRole], // 0 || 1
      },
      paymentTmp: {
        unionId: form?.unionId, // "F0U0",
        clientOper: form?.clientOper, // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        clientAcct: form?.clientAcct, // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",
        tokens: form?.tokens,
        // [
        //   "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
        //   "0xE322f5a2981e9Fb8aa2c9816Bbc05E422C228CC5",
        // ],
        rate: form?.rate, // "1.6",
        withdrawType: form?.withdrawType, // "TWD" || "Crypto",
      },
    };
  };

  return (
    <div className='w-full max-w-[600px] mx-auto my-5 p-5 rounded-lg bg-[#f1f1f1]'>
      <div>
        <Toaster />
      </div>

      {/* isLoading */}
      {isLoading && <BackDrop />}

      <Form supportedChains={supportedChains} onSubmit={handleSubmit} />
    </div>
  );
}
