import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section className='h-full flex flex-col justify-center items-center space-y-5'>
      <h1 className='text-4xl font-bold'>未授權</h1>
      <p className='text-xl'>此帳號未授權</p>
      <div className=''>
        <button
          onClick={goBack}
          className='py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700'
        >
          Go Back
        </button>
      </div>
    </section>
  );
};

export default Unauthorized;
