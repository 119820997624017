import Select from "react-select";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// json
import taiwanBankCode from "../../../../data/taiwanBankCode.json";

// utils
import {
  checkEmail,
  checkEthAddress,
  checkHasValue,
  checkNumber,
} from "../../../../utils/CheckError";

// 銀行選項
const BankCodeOptions = taiwanBankCode.map(bank => ({
  label: `${bank.code} - ${bank.name}`,
  value: { code: bank.code, name: bank.name },
}));

// 出金選項
const WithdrawOptions = [
  { label: "台幣", value: "TWD" },
  { label: "加密貨幣", value: "Crypto" },
];

// 不顯示的 tokens
const BlockTokens = [
  "0x0000000000000000000000000000000000000000", // 原生幣
  process.env.REACT_APP_NEWCOIN_CONTRACT_ADDRESS, // 新思幣合約
];

export default function Form({ supportedChains, onSubmit }) {
  const navigate = useNavigate()

  const [isAgreed, setIsAgeed] = useState(false)
  const [formData, setFormData] = useState({
    // type - required
    rate: "", // "1.6",
    unionId: "", //F0U0",
    withdrawType: "", // "TWD" || "Crypto",
    // company - required
    email: "", // "frankwang95174@gmail.com",
    name: "", // "test company name",
    remark: "", // "remark 1",
    applyRole: null, // 0 || 1
    // web3 - required
    clientAcct: "", // option, required when withdrawType === 'Crytpo'
    tokens: [
      //   "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      //   "0xE322f5a2981e9Fb8aa2c9816Bbc05E422C228CC5",
    ],
    // bank - required when withdrawType === 'TWD'
    bankAcct: "", // "123456789",
    bankCode: "", // "123",
    bankName: "", // "bank",
    // crypto - required when withdrawType === 'Crypto'
    clientOper: "", // "0x8c8b2b5f1e7a1c2e2b5f1e7a1c2e2b5f1e7a1c2e",

    // api 沒有的
    chainName: "",
  });

  /**
   * enter input
   */
  const onChangeInput = e => {
    // console.log(e);
    const name = e.target.name;
    const value = e.target.type === "radio" ? e.target.id : e.target.value;
    setFormData(pre => ({ ...pre, [name]: value }));

    // error check
    isInputPassError(name, value);

    // reset other value
    name === "bankCode" && initBankName(value);
    name === "withdrawType" && initWithdraw(value);
    name === "chainName" && initSelectedChain(value);
  };

  /**
   * set bank name in form data
   */
  const initBankName = bankCode => {
    const bank = BankCodeOptions.find(bank => bank.value.code === bankCode);
    const bankName = bank?.value?.name || "";
    setFormData(pre => ({ ...pre, bankName }));
  };

  /**
   * clear value when withdraw type change
   */
  const initWithdraw = type => {
    if (type === "TWD") {
      setFormData(pre => ({ ...pre, clientAcct: "" }));
    }
    if (type === "Crypto") {
      setFormData(pre => ({ ...pre, bankCode: "", bankAcct: "", bankName: "" }));
    }
  };

  /**
   * chain option
   */
  const chainOptions = supportedChains.map(chain => ({
    label: chain.chainName,
    value: chain.chainName,
  }));
  const initSelectedChain = chainName => {
    const chain = supportedChains.find(chain => chain.chainName === chainName);
    const unions = chain?.unions || [];
    const tokens = chain?.tokens || [];
    initUnionIdOptions(unions);
    initTokensOptions(tokens);
  };

  /**
   * unionId option
   */
  const unionOptionsRef = useRef();
  const [unionOptions, setUnionIdOptions] = useState([
    // { label: "F0U0-QR", value: "F0U0" }
  ]);
  const initUnionIdOptions = unions => {
    const options = unions.map(union => {
      const { unions, type } = union;
      return { label: `${unions} - ${type}`, value: unions };
    });
    setUnionIdOptions(options);
    setFormData(pre => ({ ...pre, unionId: "" })); // clear form data
    unionOptionsRef.current.clearValue(); // clear select
  };

  /**
   * token option
   */
  const tokenOptionsRef = useRef();
  const [tokenOptions, setTokenOptions] = useState([
    // { label: "USDT-Tether USD", value: "0x123..."}
  ]);
  const initTokensOptions = tokens => {
    const options = tokens
      .filter(token => !BlockTokens.includes(token.tokenAddr))
      .map(token => {
        const { tokenSymbol, tokenName, tokenAddr } = token;
        return { label: `${tokenSymbol} - ${tokenName}`, value: tokenAddr };
      });
    setTokenOptions(options);
    setFormData(pre => ({ ...pre, tokens: [] })); // clear form data
    tokenOptionsRef.current.clearValue(); // clear select
  };

  /**
   * pass all error before submit
   */
  const [errMsg, setErrMsg] = useState({});
  const hasPassError = () => {
    const keys = ["rate", "name", "chainName"]
      .concat(["unionId", "clientOper", "withdrawType"])
      .concat(["email", "applyRole"])
      .concat(formData["withdrawType"] === "TWD" ? ["bankCode", "bankAcct"] : [])
      .concat(formData["withdrawType"] === "Crypto" ? ["clientAcct"] : []);

    return isAllKeysHaveValue(keys) && isEveryInputPassError(keys);
  };

  const isAllKeysHaveValue = keys => {
    return keys.map(key => isInputHasValue(key)).every(bool => bool);
  };

  const isInputHasValue = key => {
    function onErrorKey(key) {
      return msg => setErrMsg(pre => ({ ...pre, [key]: msg }));
    }
    return checkHasValue(formData, key, { onError: onErrorKey(key) });
  };

  const isEveryInputPassError = keys => {
    return keys.map(key => isInputPassError(key, formData[key])).every(bool => bool);
  };

  const isInputPassError = (key, value) => {
    const onError = errMsg => setErrMsg(pre => ({ ...pre, [key]: errMsg }));

    // error check
    if (key === "rate") return checkNumber(value, { onError });
    if (key === "email") return checkEmail(value, { onError });
    if (key === "bankAcct") return checkNumber(value, { onError });
    if (key === "clientOper") return checkEthAddress(value, { onError });
    if (key === "clientAcct") return checkEthAddress(value, { onError });

    return true;
  };

  return (
    <form>
      <div className='space-y-12 sm:space-y-16'>
        <div>
          <h2 className='text-2xl font-semibold leading-7 text-gray-900'>建立新店家</h2>
          {/* <p className='max-w-2xl mt-1 text-sm leading-6 text-gray-600'>
              Use a permanent address where you can receive mail.
            </p> */}

          <div className='pb-12 mt-5 space-y-8 border-b border-gray-900/10 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0'>
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='rate'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                交易手續費
                <p className='text-xs text-red-500'>{errMsg?.rate}</p>
              </label>
              <div className='relative flex items-center mt-2 sm:col-span-2 sm:mt-0'>
                <input
                  type='text'
                  name='rate'
                  id='rate'
                  autoComplete='given-name'
                  // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  className='text-right pr-5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                  onChange={onChangeInput}
                />
                <span className='absolute right-1'>%</span>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='name'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                店家名稱(顯示於消費介面)
                <p className='text-xs text-red-500'>{errMsg?.name}</p>
              </label>
              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <input
                  type='text'
                  name='name'
                  id='name'
                  autoComplete='name'
                  // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                  onChange={onChangeInput}
                />
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='remark'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                備註
                <p className='text-xs text-red-500'>{errMsg?.remark}</p>
              </label>
              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <input
                  id='remark'
                  name='remark'
                  type='text'
                  autoComplete='remark'
                  // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                  onChange={onChangeInput}
                />
              </div>
            </div>

            {/* Chain */}
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='chainName'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                Chain
                <p className='text-xs text-red-500'>{errMsg?.chainName}</p>
              </label>

              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <Select
                  id='chainName'
                  isClearable={true}
                  isSearchable={false}
                  options={chainOptions}
                  onChange={o => {
                    const { value } = o || { label: null, value: null };
                    const e = { target: { name: "chainName", value: value } };
                    onChangeInput(e);
                  }}
                />
              </div>
            </div>

            {/* Union */}
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='unionId'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                Union
                <p className='text-xs text-red-500'>{errMsg?.unionId}</p>
              </label>

              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <Select
                  id='unionId'
                  isSearchable={false}
                  ref={unionOptionsRef}
                  options={unionOptions}
                  onChange={o => {
                    const { value } = o || { label: null, value: null };
                    const e = { target: { name: "unionId", value: value } };
                    onChangeInput(e);
                  }}
                />
              </div>
            </div>

            {/* 支付幣種 */}
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='tokens'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                支付幣種
                <p className='text-xs text-red-500'>{errMsg?.tokens}</p>
              </label>

              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <Select
                  id='tokens'
                  isMulti
                  isSearchable={false}
                  ref={tokenOptionsRef}
                  options={tokenOptions}
                  onChange={array => {
                    const tokenAddrs = array.map(o => {
                      const { value: tokenAddr } = o || { label: null, value: null };
                      return tokenAddr;
                    });
                    const e = { target: { name: "tokens", value: tokenAddrs } };
                    onChangeInput(e);
                  }}
                />
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='clientOper'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                錢包操作地址
                <p className='text-xs text-red-500'>{errMsg?.clientOper}</p>
              </label>
              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <input
                  type='text'
                  name='clientOper'
                  id='clientOper'
                  autoComplete='clientOper'
                  // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                  onChange={onChangeInput}
                />
              </div>
            </div>

            {/* 出金方式 */}
            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='withdrawType'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                出金方式
                <p className='text-xs text-red-500'>{errMsg?.withdrawType}</p>
              </label>

              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <Select
                  id='withdrawType'
                  isSearchable={false}
                  options={WithdrawOptions}
                  onChange={o => {
                    const { value } = o || { label: null, value: null };
                    const e = { target: { name: "withdrawType", value: value } };
                    onChangeInput(e);
                  }}
                />
              </div>
            </div>

            {/* 出金方式 */}
            {formData?.withdrawType === "TWD" && (
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
                <label
                  htmlFor='bankCode'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  銀行代碼
                  <p className='text-xs text-red-500'>{errMsg?.bankCode}</p>
                </label>

                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <Select
                    id='bankCode'
                    isSearchable={false}
                    options={BankCodeOptions}
                    onChange={o => {
                      const { value } = o || { label: null, value: null };
                      const e = { target: { name: "bankCode", value: value.code } };
                      onChangeInput(e);
                    }}
                  />
                </div>
              </div>
            )}

            {/* 銀行帳號 */}
            {formData?.withdrawType === "TWD" && (
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
                <label
                  htmlFor='bankAcct'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  銀行帳號
                  <p className='text-xs text-red-500'>{errMsg?.bankAcct}</p>
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='bankAcct'
                    id='bankAcct'
                    autoComplete='bankAcct'
                    // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            )}

            {/* 錢包收款地址 */}
            {formData?.withdrawType === "Crypto" && (
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
                <label
                  htmlFor='clientAcct'
                  className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
                >
                  錢包收款地址
                  <p className='text-xs text-red-500'>{errMsg?.clientAcct}</p>
                </label>
                <div className='mt-2 sm:col-span-2 sm:mt-0'>
                  <input
                    type='text'
                    name='clientAcct'
                    id='clientAcct'
                    autoComplete='clientAcct'
                    // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6'
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            )}

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                通知 Email
                <p className='text-xs text-red-500'>{errMsg?.email}</p>
              </label>
              <div className='mt-2 sm:col-span-2 sm:mt-0'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  // className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6'
                  onChange={onChangeInput}
                />
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2'>
              <label
                id='applyRole'
                className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'
              >
                申辦身份
                <p className='text-xs text-red-500'>{errMsg?.applyRole}</p>
              </label>
              <div className='flex items-center gap-5 mt-2 sm:col-span-2 sm:mt-0 sm:pt-1.5'>
                <div className='flex items-center gap-3'>
                  <input
                    id='person'
                    name='applyRole'
                    type='radio'
                    className='w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600'
                    onChange={onChangeInput}
                  />
                  <label
                    htmlFor='person'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    自然人申請
                  </label>
                </div>
                <div className='flex items-center gap-3'>
                  <input
                    id='company'
                    name='applyRole'
                    type='radio'
                    className='w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600'
                    onChange={onChangeInput}
                  />
                  <label
                    htmlFor='company'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    法人申請
                  </label>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-start gap-2 sm:py-2'>
              <input
                type='checkbox'
                id='ckeck-out-backerpay-terms'
                onChange={(e) => {
                  console.log(e.target.checked)
                  setIsAgeed(e.target.checked)}}
              />

              <label htmlFor='ckeck-out-backerpay-terms'>
                我已同意商家合約書
                <span
                  className='font-bold text-blue-500 cursor-pointer'
                  onClick={() => window.open("/service/backerpay.pdf", "_blank")}
                >
                  服務條款
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='flex items-center justify-center mt-6 gap-x-6'>
        <button
          type='button'
          className='text-2xl px-3 py-2 font-semibold bg-[#9E9DA8] rounded-lg text-white'
          onClick={() => navigate(-1)}
        >
          返回
        </button>
        <button
          type='submit'
          className='inline-flex justify-center px-3 py-2 text-2xl font-semibold text-white bg-[#2010D6] disabled:bg-[#2010D6]/50 rounded-lg'
          disabled={!isAgreed}
          onClick={e => {
            e.preventDefault();
            hasPassError() && onSubmit(formData);
          }}
        >
          確認
        </button>
      </div>
    </form>
  );
}
