import { WagmiConfig, createClient, configureChains } from "wagmi";

import { polygon, polygonMumbai } from "wagmi/chains";

// import { alchemyProvider } from "wagmi/providers/alchemy";
// import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import { InjectedConnector } from "wagmi/connectors/injected";
// import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(
  [polygon, polygonMumbai],
  [
    // alchemyProvider({ apiKey: 'ES24yYDYEAuTbl8XrzRgwKYPmGCsYx1x' }),
    // infuraProvider({ apiKey: '6e3d30e028c04cec9c024c0687cbe87' }),
    jsonRpcProvider({
      rpc: chain => {
        const url = {
          [polygon.id]: "https://polygon-mainnet.g.alchemy.com/v2/ME_emxoE6dMVow8F478jzv8l3pWFQQVC",
          [polygonMumbai.id]:
            "https://polygon-mumbai.g.alchemy.com/v2/ES24yYDYEAuTbl8XrzRgwKYPmGCsYx1x",
        };
        return { http: url[chain.id] };
      },
    }),
    publicProvider(),
  ]
);

// Set up client
const client = createClient({
  autoConnect: false,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true, // 回重覆詢問是否允許連線
      },
    }),

    // new MetaMaskConnector({
    //   chains,
    //   options: {
    //     shimDisconnect: true,
    //     // UNSTABLE_shimOnConnectSelectAccount: true // 回重覆詢問是否允許連線
    //   },
    // }),

    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     qrcode: true,
    //   },
    // }),
  ],
  provider,
  webSocketProvider,
});

export const WagmiProvider = ({ children }) => {
  return <WagmiConfig client={client}>{children}</WagmiConfig>;
};
