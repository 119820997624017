import axios from "axios";

// const BASE_URL =
//   process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_BACKERPAY_SERVER_URL;
const BASE_URL = process.env.REACT_APP_BACKERPAY_SERVER_URL;
const BEHAVIOR_REPORT_URL = BASE_URL + "/platformapi/behaviorReport";

export default axios.create({
  baseURL: BASE_URL,
});

export async function getBehaviorReport(from, to, config) {
  const res = await axios.post(BEHAVIOR_REPORT_URL, { startDate: from, endDate: to }, config);

  if (res?.data?.op_code === "-101") return -101; // user token expired
  if (res?.data?.op_code === "-1") return -1; // no data
  if (res?.data?.op_code !== "1") throw new Error(res?.data?.op_msg);

  return res.data;
}
