import { Link } from "react-router-dom";

const Missing = () => {
  return (
    <article className='h-full flex flex-col justify-center items-center  space-y-5'>
      <h1 className='text-4xl font-bold'>Oops!</h1>
      <p className='text-xl'>Page Not Found</p>
      <div className='py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700'>
        <Link to='/'>Visit Our Homepage</Link>
      </div>
    </article>
  );
};

export default Missing;
