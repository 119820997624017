const ethers = require("ethers");

const CHAINS = require("./chains.json");

/**
 * 判斷裝置是否為手機
 */
export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // alert("Is mobile");
    return true;
  } else {
    // alert("not mobile");
    return false;
  }
}

/**
 * 收機開啟 MetaMask App
 * @param {*} url
 * @returns
 */
export function mobileOpenApp(url) {
  if (isMobile() && !window?.ethereum) {
    alert("前往 metamask App");

    // fullUrl = 'https://metamask.app.link/dapp/https://devpayflow.backerpay.com'
    const baseUrl = "https://metamask.app.link/dapp/";
    return () => window.location.replace(baseUrl + url);
  }
}

/**
 * 等待換鏈
 * @param {秒數} seconds
 */
export async function waitFirstSwitchChain(seconds = 1) {
  // 手機換鏈要等一下，不然合約讀取會失敗

  const wait = new Promise(resolve => {
    setTimeout(() => {
      // console.log("time out");
      resolve();
    }, seconds * 1000);
  });
  await wait;
}

/**
 *  加鏈
 */
const addWalletNetwork = async network => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [network],
    });
  } catch (addError) {
    // handle "add" error
    alert("請手動將網路換到合約佈署的網路");
  }
};

/**
 *  換鏈
 */
const switchWalletNetwork = async network => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: network.chainId }], // chain id: 0x04
    });
  } catch (switchError) {
    addWalletNetwork(network);
  }
};

/**
 * 換鏈 + 取得連線授權 + 取得現在帳號
 */
export default async function connectMetaMask(chainName) {
  if (!window.ethereum) throw new Error("no MetaMask");
  if (!CHAINS[chainName]) throw new Error(`${chainName} chain not exist`);

  // switch network
  await switchWalletNetwork(CHAINS[chainName]);

  // connect current wallet
  const currentAddress = await connectWallet();

  return currentAddress;
}

/**
 * 取得 rpc provider
 */
export function connectRpcProvider(chainName) {
  const url = CHAINS[chainName].rpcUrls[0];
  return new ethers.providers.JsonRpcProvider(url);
}

/**
 * 取得現在錢包的 signer
 */
export function connectSigner() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  return provider.getSigner();
}

/**
 * 取得現在錢包的 地址
 */
export async function connectWallet() {
  // get permission
  // connet to wallet
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  // address
  return ethers.utils.getAddress(accounts[0]);
}

/**
 *    檢查是否有交易卡住
 */
export async function isNonceBlock(chainName, walletAccount) {
  // nonce
  // 277       | 278 279 | 280
  // confirmed | pending | next

  // 這裡不能接 metamask 的 provider，不然 nonce 回傳會一樣
  // const url = "https://rpc-mumbai.maticvigil.com";
  const provider = connectRpcProvider(chainName);

  // const nonceAfterPending = await provider.getTransactionCount(walletAccount, "pending"); // 正在 pending 的下一個號碼
  // const nonceAfterConfirmed = await provider.getTransactionCount(walletAccount); // 已完成 confirmed 的下一個號碼
  const awaitNonceAfterPending = provider.getTransactionCount(walletAccount, "pending"); // 正在 pending 的下一個號碼
  const awaitNonceAfterConfirmed = provider.getTransactionCount(walletAccount); // 已完成 confirmed 的下一個號碼
  const nonceAfterPending = await awaitNonceAfterPending;
  const nonceAfterConfirmed = await awaitNonceAfterConfirmed;

  // console.log(walletAccount);
  // console.log("Nonce after pending:", nonceAfterPending); // 280
  // console.log("Nonce after confirmed:", nonceAfterConfirmed); // 278
  // console.log(nonceAfterPending !== nonceAfterConfirmed);
  return nonceAfterPending !== nonceAfterConfirmed;
}

/**
 * 查找新的 tx hash
 * @param {rpc provider} provider
 * @param {終止查詢的區塊號碼} stopBlockNumber
 * @param {錢包地址} walletAddress
 * @param {被覆蓋的 previousNonce} nonce
 */
export async function getNewTxHash(chainName, stopBlockNumber, walletAddress, previousNonce) {
  const provider = connectRpcProvider(chainName);
  const baseNumber = 5;
  let currentBlockNumber = await provider.getBlockNumber();

  console.log("current block number:" + currentBlockNumber);

  // 取當前的區塊號碼 往回查找 新的 tx hash，
  for (
    let blockNumber = currentBlockNumber + baseNumber;
    blockNumber >= stopBlockNumber;
    blockNumber--
  ) {
    try {
      let block = await provider.getBlockWithTransactions(blockNumber);
      console.log("check block number:" + block.number);

      if (block && block.transactions) {
        for (let i = 0; i < block.transactions.length; i++) {
          const transaction = block.transactions[i];
          const from = transaction.from;
          const nonce = transaction.nonce;

          if (walletAddress === from && previousNonce === nonce) {
            // console.log(`new tx in block ${i}:`, block);
            return transaction;
          }
        }

        // let bool = block.transactions.every(e => {
        //   if (address === e.from && nonce === e.nonce) {
        //     console.log(e.hash, e.nonce, e);
        //     return false;
        //   }
        //   return true;
        // });

        // if (!bool) {
        //   break;
        // }
      }
    } catch (err) {
      console.error("Error in block " + blockNumber, err);
    }
  }
}
