import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// hooks
import useLoginAdmin from "../hooks/useLoginAdmin";

const LoginAdmin = () => {
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const { login, error, isLoading } = useLoginAdmin();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from || "/home";

  const handleLogin = async e => {
    e.preventDefault();
    const admin = await login(account, password);
    admin && navigate(from, { replace: true });
  };

  return (
    <div>
      <h1 className='text-center text-2xl mt-5 font-bold z-[9999]'>Login</h1>

      <form className='login max-w-[400px] mx-auto flex flex-col gap-3 p-5' onSubmit={handleLogin}>
        <div>
          <label htmlFor='account' className='font-medium'>
            Account
          </label>
          <input
            type='text'
            className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
            onChange={e => setAccount(e.target.value)}
            value={account}
          />
        </div>

        <div>
          <label htmlFor='password' className='font-medium'>
            Password:
          </label>
          <input
            type='password'
            className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
        </div>

        <button
          className='text-white disabled:bg-slate-500 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'
          disabled={isLoading}
        >
          Log in
        </button>

        {/* error message */}
        {error && (
          <div className='p-2 font-bold text-center bg-red-200 rounded text-rose-700'>
            <p>{error}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginAdmin;
