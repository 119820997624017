import React from "react";

import "./dist/css/main.css";
import "./dist/css/faq.css";

import useScript from "../../hooks/useScript";

export default function FAQ() {
  // useScript("dist/js/main.js");
  useScript("dist/js/faq.js");

  return (
    <div>
      <main>
        <div className='banner-section'>
          <div className='bg'>
            <div className='banner pc' style={{ backgroundImage: "url(dist/images/banner.jpg)" }} />
            <div
              className='banner mo'
              style={{ backgroundImage: "url(dist/images/banner_mb.jpg)" }}
            />
          </div>
          <div className='container'>
            <h1 className='main-title'>常見問題</h1>
          </div>
          <div className='yel-ball' />
        </div>
        <div className='search-section'>
          <div className='container'>
            <form>
              <div className='form-input'>
                <input type='text' placeholder='請輸入關鍵字' />
              </div>
              <div className='btnwrap small'>
                <a className='btn' href='/#'>
                  搜尋
                  <span className='search' />
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className='tag-section'>
          <div className='container'>
            <div id='top-menu-ul' className='top-menu-ul'>
              <div className='item_Menu'>
                <div className='item_menu_Box'>
                  <ul className='item_menu_list slides'>
                    <li className='active'>
                      <a href='/#'>全部</a>
                    </li>
                    <li>
                      <a href='/#'>註冊問題</a>
                    </li>
                    <li>
                      <a href='/#'>購買問題</a>
                    </li>
                    <li>
                      <a href='/#'>新手專區</a>
                    </li>
                    <li>
                      <a href='/#'>註冊問題</a>
                    </li>
                    <li>
                      <a href='/#'>購買問題</a>
                    </li>
                    <li>
                      <a href='/#'>新手專區</a>
                    </li>
                    <li>
                      <a href='/#'>註冊問題</a>
                    </li>
                    <li>
                      <a href='/#'>購買問題</a>
                    </li>
                    <li>
                      <a href='/#'>新手專區</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='flex-direction-nav'>
                <a href='/#' className='lbtn flex-prev'>
                  <div />
                </a>
                <a href='/#' className='rbtn flex-next'>
                  <div />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='faq-section'>
          <div className='container'>
            <ul>
              <li className='active'>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>什麼是加密貨幣？</div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>加密貨幣的好處有哪些？</div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>為何要用加密貨幣？</div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>
                      聽說投資虛擬貨幣可以賺很多錢，怎麼可以拿來消費呢？
                    </div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>加密貨幣是不是很多詐騙？</div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>GAS FEE是什麼呢？</div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='question-wrap'>
                  <div className='question'>
                    <div className='f30-20 fw7'>Q.</div>
                    <div className='content fw7'>
                      都是虛擬貨幣，為什麼會有那麼多種類，又有什麼不同呢？
                    </div>
                  </div>
                  <div className='icon' />
                </div>
                <div className='answer-wrap'>
                  <div className='answer'>
                    <div className='f30-20 fw7'>A.</div>
                    <div className='content'>
                      <div className='editor_content'>
                        <div className='editor_box'>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>去監管</span>
                              ：非中心化的錢包，只需要下載小狐狸 (metamask) 即可擁有一個匿名的帳戶{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>速度快</span>
                              ：點對點的傳輸系統不需要透過冗長的銀行流程，只需
                              要透過礦工認證，短短15秒內就可以讓錢傳送到地球另一端{" "}
                            </span>
                          </p>
                          <p>
                            <span style={{ fontSize: "16px" }}>
                              • <span style={{ color: "#1b9cff" }}>更便宜</span>
                              ：傳統銀行體系，必須養活成千上萬員工以及公司營
                              運，所以手續費都在幫銀行養活員工，但是區塊鏈科技不用養任何人員，只需要便宜的GAS
                              FEE
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div className='top_btn' />
    </div>
  );
}
