/**
 * 判斷裝置是否為手機
 * @returns true
 */
export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // alert("Is mobile");
    return true;
  } else {
    // alert("not mobile");
    return false;
  }
};

/**
 * 判斷 商家編號 是否正確
 * @param {商家編號 F0U0P1 || F0U1P2} _paymentId
 * @returns true
 */
export const isPaymentId = _paymentId => {
  const paymentId = _paymentId.toUpperCase(); // 轉大寫
  const pattern = /^F\d+U\d+P\d+$/g;
  return pattern.test(paymentId);
};

/**
 * 判斷 商家編號 是否為 QR(實體) 或 EC(線上)
 * @param {商家編號 F0U0P1 || F0U1P2} _paymentId
 * @returns true
 */
export const isOnline = _paymentId => {
  if (!isPaymentId(_paymentId)) throw new Error("wrong paymentId");

  // 判斷 + 回傳
  const online = "U1";
  const match = _paymentId.toUpperCase().match(/U\d+/g); // null || [ 'U{number}' ]
  return match && match[0] === online;
};

/**
 * 取得 聯盟編號
 * @param {商家編號 F0U0P1 || F0U1P2} paymentId
 * @returns F{number}U0 || F{number}U1
 */
export const getUnion = _paymentId => {
  if (!isPaymentId(_paymentId)) throw new Error("wrong paymentId");
  return _paymentId.toUpperCase().split("P")[0];
};

/**
 * 取得 商家號碼
 * @param {商家編號 F0U0P1 || F0U1P2} _paymentId
 * @returns '{number}'
 */
export const getPaymentNumber = _paymentId => {
  if (!isPaymentId(_paymentId)) throw new Error("wrong paymentId");
  const match = _paymentId.toUpperCase().match(/P\d+/g); // null || [ 'P{number}' ]
  return match && match[0].slice(1);
};

/**
 * 判斷 台幣
 * @param {'TWD' || 'Crypto'} withdrawType
 * @returns true
 */
export const isWithdrawByTwd = withdrawType => {
  const pattern = /TWD/i;
  return pattern.test(withdrawType);
};

/**
 *    search
 */
export const isSearched = (searchText, source) => {
  // if searchText and source are '', then re.test() return true
  const re = new RegExp(searchText, "ig");
  return re.test(source);
};

/**
 * 加密使用者密碼
 * @param {*} password
 */
export function encryptPassword(password) {
  // const NodeRSA = require("node-rsa");
  // const fs = require("fs");
  // const pi_key = fs.readFileSync("./rsa_1024_priv.pem", { encoding: "utf8" });
  // const pi_key = process.env.REACT_APP_TOKEN_KEY;
  // const key = new NodeRSA(pi_key);
  // const cipherText = key.encryptPrivate(password, "base64");
  // console.log("加密:", cipherText);
}

/**
 * 取得簡單的地址
 * @param {string} address 
 * @param {number} headLetters 
 * @param {number} tailLetters 
 * @returns string '0x123...456'
 */
export function getShortCryptoAddress(address, headLetters=3, tailLetters=4) {
  const head = address.slice(0, 2 + headLetters); // 2 is 0x
  const middle = "...";
  const tail = address.slice(-tailLetters);
  return head + middle + tail;
}
