import React from "react";
import {Link, Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// components
import LanguageDropdown from "../LanguageDropdown";
import {useVendorAuth, authReducer} from "../../context/VendorAuthContext";

// 後台 urls
// const URL_3W_KYC = "/userkycflow";

// urls
const URL_index = "/";
// const URL_baveshop = "/baveshop";
// const URL_faq = "faq";
const URL_memberprofile = "/memberprofile";
const URL_product = "/product";
// const URL_registration = "/registration";

export default function Header() {
    const {t} = useTranslation();
    const {vendor, dispatch} = useVendorAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [logoutFlag, setLogoutFlag] = React.useState(false);

    function logout() {
        // remove session storage
        dispatch({type: "logout"});

        // redirect to login page
        navigate(URL_memberprofile, {replace: false});
    }

    // add a useEffect hook to monitor the logoutFlag
    React.useEffect(() => {
        // add your re-rendering logic here, if needed
    }, [logoutFlag]);

    return (
        <header className='navbar'>
            <div className='container__logo'>
                {/* <a href='/'>
          <embed src='dist/images/logo.svg' type='image/svg+xml' />
        </a> */}
                <Link to={URL_index} alt={'index'} aria-label={'Go to index page.'}>
                    {/* <img src='dist/images/logo.png' alt='' /> */}
                    <embed className='' src='dist/images/logo-svg-w.svg' type='image/svg+xml'/>
                </Link>
            </div>
            <nav className='container__menu'>
                <ul className='menu__wrap'>
                    <li className='menu__title mit'>
                        {/* <button>會員專區</button> */}
                        <div className='menu__sub member-sub'>
                            <div className='menu__sub__wrap'>
                                <div className='wrap-title'>{t('廠商專區')}</div>
                                <ul>
                                    <li>
                                        {/* <a href={URL_memberprofile}>廠商總覽</a> */}
                                        <Link to={URL_memberprofile}>{t('廠商專區')}</Link>
                                    </li>
                                    <li>
                                        <a href='/'>基本資料設定</a>
                                    </li>
                                    <li>
                                        <a href='/'>收款權限設定</a>
                                    </li>
                                    <li>
                                        <a href='/'>提領設定</a>
                                    </li>
                                    <li>
                                        <a href='/'>通知設定</a>
                                    </li>
                                    <li>
                                        <a href='/'>簽核系統</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='line'/>
                            <div className='menu__sub__wrap'>
                                <div className='wrap-title'>收帳管理</div>
                                <ul>
                                    <li>
                                        <a href='/'>收款連結管理</a>
                                    </li>
                                    <li>
                                        {/* <a href={URL_product}>建立收款連結（一般）</a> */}
                                        <Link to={URL_product}>建立收款連結（一般）</Link>
                                    </li>
                                    <li>
                                        <a href='/'>建立收款連結（多簽）</a>
                                    </li>
                                    <li>
                                        <a href='/'>系統開發</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    {/* <li className='menu__title mit'>
            <button href='/'>開發者專區</button>
            <div className='menu__sub'>
              <ul>
                <li>
                  <a href='/'>APIs申請</a>
                </li>
                <li>
                  <a href='/'>APIs文件</a>
                </li>
                <li>
                  <a href='/'>APIs操作手冊</a>
                </li>
              </ul>
            </div>
          </li> */}

                    {/* <li className='menu__title mit'>
            <button href='/'>服務介紹</button>
            <div className='menu__sub'>
              <ul>
                <li>
                  <a href='/'>費率說明</a>
                </li>
                <li>
                  <a href='/'>關於我們</a>
                </li>
                <li>
                  <a href='/'>聯絡我們</a>
                </li>
              </ul>
            </div>
          </li> */}

                    {/* <a href={URL_baveshop}>BAVE SHOP</a> */}
                    {/* <li className='menu__title'>
            <button>
              <Link to={URL_baveshop}>BACKER SHOP</Link>
            </button>
          </li> */}

                    {/* <a href={URL_faq}>常見問題</a> */}
                    {/* <li className='menu__title'>
            <button>
              <Link to={URL_faq}>常見問題</Link>
            </button>
          </li> */}
                </ul>
            </nav>
            <div className='container__member'>
                <ul>
                    <li>
                        <LanguageDropdown/>
                    </li>
                    <li>
                        {/* <Link to={URL_3W_KYC} className='signinbtn'>
              身分驗證
            </Link> */}
                    </li>
                    <li>
                        {/* 狀態切換直接隱藏 */}
                        {!vendor ?
                            <Link
                                className='loginbtn'
                                to={URL_memberprofile}>
                                {t('廠商專區')}
                            </Link>
                            :
                            <button className="bg-[#ef9500] px-6 py-3 rounded-full text-white" onClick={logout}>
                                {t('登出')}
                            </button>
                        }
                        {/* <a class="logoutbtn" href="/#">登出</a> */}
                    </li>
                </ul>
            </div>
            <div className='container__sign'>{/* <Link to={URL_3W_KYC}>身分驗證</Link> */}</div>
            <div className='container__burger'>
                <div className='burger'>
                    <span/>
                </div>
            </div>
        </header>
    );
}
