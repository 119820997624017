import { ethers } from "ethers";

/**
 * 檢查數字
 * @param {string} email 'test@gmail.com'
 * @param {string} errMsg
 * @param {function} msgCallback
 * @returns boolean
 */
export function checkNumber(number, { errMsg, onError }) {
  const isNumber = !isNaN(number);
  const msg = isNumber ? "" : errMsg || "invalid number";
  onError?.(msg);
  return isNumber;
}

/**
 * 檢查信箱
 * @param {string} email 'test@gmail.com'
 * @param {string} errMsg
 * @param {function} msgCallback
 * @returns boolean
 */
export function checkEmail(email, { errMsg, onError }) {
  const isEmail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email);
  const msg = isEmail ? "" : errMsg || "invalid email";
  onError?.(msg);
  return isEmail;
}

/**
 * 檢查 ETH 錢包地址
 * @param {string} address '0xD44b4d894F9A4F5637CB42338191b0CF90d74ADC'
 * @param {string} errMsg
 * @param {function} onError
 * @returns boolean
 */
export function checkEthAddress(address, { errMsg, onError }) {
  const isAddr = ethers.utils.isAddress(address);
  const msg = isAddr ? "" : errMsg || "invalid address";
  onError?.(msg);
  return isAddr;
}

/**
 * 檢查 ETH 錢包地址
 * @param {obj} form
 * @param {string} key
 * @param {string} errMsg
 * @param {function} onError
 * @returns boolean
 */
export function checkHasValue(form, key, { errMsg, onError }) {
  const isValue = form[key] ? true : false;
  const msg = isValue ? "" : errMsg || "required";
  onError?.(msg);
  return isValue;
}
