import React from "react";
import moment from "moment-timezone";

import { Card } from "flowbite-react";

import axios from "axios";

// import { Link, useLocation } from "react-router-dom";

// utils
import connectMetaMask from "../utils/MetaMask/connectMetaMask";

// components
import TextInput from "../components/TextInput";
import PhotoInput from "../components/PhotoInput";

import UserKyc from "../components/UserKyc";

const BAVEPAY_URL = process.env.REACT_APP_BACKERPAY_SERVER_URL;
const URL_SEND_EMAIL = BAVEPAY_URL + "/platformapi/sendemailcode";
const URL_SEND_EMAIL_CODE = BAVEPAY_URL + "/platformapi/approve_email";
const URL_SEND_PHONE = BAVEPAY_URL + "/platformapi/sendephonecode";
const URL_SEND_PHONE_CODE = BAVEPAY_URL + "/platformapi/approve_phone";
const URL_UPLOAD_IMAGE = BAVEPAY_URL + "/platformapi/upload_image";
const URL_CREATE_USER_KYC = BAVEPAY_URL + "/platformapi/create_user_kyc";

const reducer = (state, action) => {
  switch (action.type) {
    // email
    case "create_email":
      return { ...state, email: action.payload };
    case "set_isEmailVerified":
      return { ...state, isEmailVerified: true };
    case "set_isEmailNotVerified":
      return { ...state, isEmailVerified: false };
    // phone
    case "create_phone":
      return { ...state, phone: action.payload };
    case "set_isPhoneVerified":
      return { ...state, isPhoneVerified: true };
    case "set_isPhoneNotVerified":
      return { ...state, isPhoneVerified: false };
    // wallets
    case "insert_wallet":
      const isIncluded = state.wallets.includes(action.payload);
      if (isIncluded) return state;
      else return { ...state, wallets: [...state.wallets, action.payload] };
    case "delete_wallet":
      const _wallets = state.wallets.filter(wallet => wallet !== action.payload);
      return { ...state, wallets: _wallets };
    case "confirm_wallets":
      return { ...state, wallets: action.payload };
    // user
    case "create_user":
      return { ...state, user: action.payload };
    // id phote head
    case "create_idPhotoHeadUrl":
      return { ...state, idPhotoHead: action.payload };
    // id phote tail
    case "create_idPhotoTailUrl":
      return { ...state, idPhotoTail: action.payload };

    default:
      throw new Error("action.type error!!!");
  }
};

export default function UserKycFlow() {
  const [errorMsgs, setErrorMsgs] = React.useState([]);

  // 0: enter email -> 1: verify email
  // 2: enter phone -> 3: verify phone
  // 4: connect wallet address
  const [stage, setStage] = React.useState(0);
  const [state, dispatch] = React.useReducer(reducer, {
    email: "",
    isEmailVerified: false,
    phone: "",
    isPhoneVerified: false,
    wallets: [],
    user: {},
    idPhotoHead: "", // on server
    idPhotoTail: "", // on server
    status: "not approved", // approved, rejected, not approved
  });

  /**
   * 檢查 KYC 資料是否完整
   * @returns true || nothing
   */
  const isKycDataOk = () => {
    const errMsgs = [];

    !state.isEmailVerified && errMsgs.push("信箱未驗證");
    !state.isPhoneVerified && errMsgs.push("手機未驗證");
    state.wallets.length === 0 && errMsgs.push("沒有錢包地址");
    Object.values(state.user).length === 0 && errMsgs.push("基本資料未填寫");
    (!state.idPhotoHead || !state.idPhotoTail) && errMsgs.push("身分證未上傳");

    setErrorMsgs(errMsgs);

    if (errMsgs.length === 0) return true;

    // console.log([...Object.values(state)]);
    // const canPostData = [...Object.values(state)].every(Boolean) // 除了 billAddress2 以外的 data 都是有資料的
  };

  const postData = async () => {
    const isDataOk = isKycDataOk();

    if (!isDataOk) return;

    // 需要轉換成資料庫的時間格式
    const _birthDay = moment(state.user.birthDay).format("YYYY/MM/DD");
    const _issueDay = moment(state.user.issueDay).format("YYYY/MM/DD");
    const _user = { ...state.user, birthDay: _birthDay, issueDay: _issueDay };

    setErrorMsgs([]);

    try {
      const response = await axios.post(URL_CREATE_USER_KYC, { ...state, user: _user });
      // console.log(state)
      // return
      if (response?.data?.op_code === "1") {
        console.log(response);
        return setStage(999); // 顯示成功畫面
      } else if (response?.data?.op_code === "-1") {
        throw new Error("用戶資料己存在");
      } else {
        console.log(response);
        throw new Error(response?.data?.op_code);
      }
    } catch (err) {
      console.error(err);
      setErrorMsgs([err.message]);
      return setStage(-1); // 顯示失敗畫面
    }
  };

  /**
   *    handle submit
   */
  const handleSubmit = async (inputType, inputValue) => {
    console.log(inputType);
    console.log(inputValue);

    // email
    if (inputType === "email") {
      const isSuccess = await isSendEmail(inputValue);
      if (!isSuccess) return alert("Email 驗證碼傳送失敗");
      dispatch({ type: "create_email", payload: inputValue });
      dispatch({ type: "set_isEmailNotVerified" }); // 重新輸入 email 就要重新驗證
    }
    if (inputType === "emailVerification") {
      const isSuccess = await isEmailCodeApproved(state.email, inputValue);
      if (!isSuccess) return alert("Email 驗證失敗");
      dispatch({ type: "set_isEmailVerified" });
    }

    // phone
    if (inputType === "phone") {
      const isSuccess = await isSendPhone(inputValue);
      if (!isSuccess) return alert("手機 驗證碼傳送失敗");
      dispatch({ type: "create_phone", payload: inputValue });
      dispatch({ type: "set_isPhoneNotVerified" }); // 重新輸入 phone 就要重新驗證
    }
    if (inputType === "phoneVerification") {
      const isSuccess = await isPhoneCodeApproved(state.phone, inputValue);
      if (!isSuccess) return alert("手機 驗證失敗");
      dispatch({ type: "set_isPhoneVerified" });
    }

    // wallet
    if (inputType === "insertWallet") {
      dispatch({ type: "insert_wallet", payload: inputValue });
      return;
    }
    if (inputType === "deleteWallet") {
      dispatch({ type: "delete_wallet", payload: inputValue });
      return;
    }
    if (inputType === "confirmWallets") {
      dispatch({ type: "confirm_wallets", payload: inputValue });
    }

    // user
    if (inputType === "user") {
      dispatch({ type: "create_user", payload: inputValue });
    }

    // id photos
    if (inputType === "idPhotoHead") {
      const imageUrl = await handleImageUpload(inputValue);
      if (!imageUrl) return alert("上傳照片失敗");
      dispatch({ type: "create_idPhotoHeadUrl", payload: imageUrl });
    }
    if (inputType === "idPhotoTail") {
      const imageUrl = await handleImageUpload(inputValue);
      if (!imageUrl) return alert("上傳照片失敗");
      dispatch({ type: "create_idPhotoTailUrl", payload: imageUrl });
    }

    console.log("next step");
    setStage(prev => prev + 1);
  };

  return (
    <div>
      {/* flow navbar */}
      <nav className='flex p-5 overflow-auto' aria-label='Breadcrumb'>
        <ol className='inline-flex items-center space-x-1 md:space-x-3'>
          {/* flow #0,1 Email */}
          <li>
            <button
              className={`
                flex justify-center items-center gap-3 
                text-sm text-gray-700 font-medium 
                whitespace-nowrap
                hover:text-gray-900 
                ${stage === 0 || stage === 1 ? "text-blue-700" : ""}
                `}
              onClick={() => setStage(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='w-6 h-6'
              >
                <path d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z' />
                <path d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z' />
              </svg>
              Email
            </button>
          </li>

          {/* flow #2,3 手機 */}
          <li>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 2 || stage === 3 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(2)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path d='M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z' />
                  <path
                    fillRule='evenodd'
                    d='M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z'
                    clipRule='evenodd'
                  />
                </svg>
                手機
              </button>
            </div>
          </li>

          {/* flow #4 連線錢包 */}
          <li aria-current='page'>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 4 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(4)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path d='M2.273 5.625A4.483 4.483 0 015.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 3H5.25a3 3 0 00-2.977 2.625zM2.273 8.625A4.483 4.483 0 015.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 6H5.25a3 3 0 00-2.977 2.625zM5.25 9a3 3 0 00-3 3v6a3 3 0 003 3h13.5a3 3 0 003-3v-6a3 3 0 00-3-3H15a.75.75 0 00-.75.75 2.25 2.25 0 01-4.5 0A.75.75 0 009 9H5.25z' />
                </svg>
                連線錢包
              </button>
            </div>
          </li>

          {/* flow #5 基本資料 */}
          <li aria-current='page'>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 5 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(5)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z'
                    clipRule='evenodd'
                  />
                </svg>
                基本資料
              </button>
            </div>
          </li>

          {/* flow #6 身分證 - 正面 */}
          <li aria-current='page'>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 6 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(6)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z'
                    clipRule='evenodd'
                  />
                </svg>
                身分證 - 正面
              </button>
            </div>
          </li>

          {/* flow #7 身分證 - 反面 */}
          <li aria-current='page'>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 7 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(7)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z'
                    clipRule='evenodd'
                  />
                </svg>
                身分證 - 反面
              </button>
            </div>
          </li>

          {/* flow #8 確認送出 */}
          <li aria-current='page'>
            <div className='flex items-center'>
              {svgArrow()}

              <button
                className={`
                    flex justify-center items-center gap-3 
                    text-sm text-gray-700 font-medium 
                    whitespace-nowrap
                    hover:text-gray-900 
                    ${stage === 8 ? "text-blue-700" : ""}
                    `}
                onClick={() => setStage(8)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='w-6 h-6'
                >
                  <path d='M11.47 1.72a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 01-1.06-1.06l3-3zM11.25 7.5V15a.75.75 0 001.5 0V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z' />
                </svg>
                確認送出
              </button>
            </div>
          </li>
        </ol>
      </nav>

      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}

      {/* test button */}
      {/* <button
        className='block mx-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5'
        onClick={postData}
      >
        post data
      </button> */}

      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------- */}

      {/* forms */}
      <main>
        {stage === 0 && (
          <TextInput
            label={"Email"}
            button={"取得驗證碼"}
            errorMessage={"信箱格式錯誤"}
            inputType={"email"}
            initValue={state.isEmailVerified ? state.email : ""}
            callback={handleSubmit}
          />
        )}

        {stage === 1 && (
          <TextInput
            label={"輸入 Email 驗證碼"}
            button={"輸入驗證碼"}
            errorMessage={"驗證碼錯誤"}
            inputType={"emailVerification"}
            initValue={""}
            callback={handleSubmit}
          />
        )}

        {stage === 2 && (
          <TextInput
            label={"手機"}
            button={"取得驗證碼"}
            errorMessage={"手機格式錯誤"}
            inputType={"phone"}
            initValue={state.isPhoneVerified ? state.phone : ""}
            callback={handleSubmit}
          />
        )}

        {stage === 3 && (
          <TextInput
            label={"輸入 手機 驗證碼"}
            button={"輸入驗證碼"}
            errorMessage={"驗證碼錯誤"}
            inputType={"phoneVerification"}
            callback={handleSubmit}
          />
        )}

        {/* 錢包 */}
        {stage === 4 && (
          <form className='block max-w-sm p-5 mx-auto border border-gray-200 rounded-lg shadow-md'>
            {/* label */}
            <label className='block mb-2 text-sm font-medium text-gray-900'>錢包地址</label>

            {/* inputs */}
            {state.wallets.map(wallet => (
              <div key={wallet} className='flex items-center justify-between gap-1'>
                <input
                  disabled
                  required
                  placeholder={wallet}
                  className={`
                    form-control 
                    block
                    w-full
                    mr-1
                    my-1
                    pl-3 py-1.5
                    text-base text-gray-700
                    font-normal
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition ease-in-out
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                    `}
                />
                {/* delete button */}
                <button
                  className='px-2 py-2 mt-0 text-sm font-medium text-white rounded-lg whitespace-nowrap bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:ring-rose-300'
                  onClick={e => {
                    e.preventDefault();
                    return handleSubmit("deleteWallet", wallet);
                  }}
                >
                  刪除
                </button>
              </div>
            ))}

            {/* button */}
            <div className='flex items-center justify-between mt-2'>
              <button
                className='whitespace-nowrap text-sm px-5 py-2.5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg'
                onClick={async e => {
                  e.preventDefault();

                  try {
                    // step #1 connect wallet
                    // if (!walletAddress) {
                    //   e.target[0].value = await connectMetaMask("Polygon_Mumbai"); // 更改 input 內容，並連結錢包
                    //   e.target[1].innerText = "確認"; // 更改 button 文字
                    //   return;
                    // }

                    const currentAddress = await connectMetaMask("Polygon_Mumbai");
                    return handleSubmit("insertWallet", currentAddress);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                新增錢包
              </button>
              {/* <button
                className='whitespace-nowrap text-white text-sm px-5 py-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg'
                onClick={e => {
                  e.preventDefault();
                  handleSubmit("confirmWallets", state.wallets);
                }}
              >
                確認
              </button> */}
            </div>
            <p className='mt-2'>
              想增加 2 個以上的錢包，請 <span className='text-blue-500'>切換</span> 錢包帳號繼續新增
            </p>
          </form>
        )}

        {/* 用戶資料 */}
        {stage === 5 && <UserKyc user={state.user} callback={handleSubmit} btnName={"確認"} />}

        {/* 身分證 - 正面 */}
        {stage === 6 && (
          <PhotoInput
            label={"身分證 - 正面"}
            button={"確認上傳"}
            errorMessage={"上傳失敗"}
            inputType={"idPhotoHead"}
            initValue={state.idPhotoHead}
            callback={handleSubmit}
          />
        )}

        {/* 身分證 - 反面 */}
        {stage === 7 && (
          <PhotoInput
            label={"身分證 - 反面"}
            button={"確認上傳"}
            errorMessage={"上傳失敗"}
            inputType={"idPhotoTail"}
            initValue={state.idPhotoTail}
            callback={handleSubmit}
          />
        )}

        {/* 確認送出 */}
        {stage === 8 && (
          <Card className='max-w-sm mx-auto'>
            <button
              className='block mx-auto text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5'
              onClick={postData}
            >
              送出資料
            </button>

            <div
              className={`
                p-2 
                text-center font-bold rounded
              bg-red-200 text-rose-700
                ${errorMsgs?.length !== 0 ? "" : "hidden"}
              `}
            >
              {errorMsgs?.map(error => (
                <p key={error}>{error}</p>
              ))}
            </div>
          </Card>
        )}

        {/* 完成畫面 */}
        {stage === 999 && (
          <article className='mt-10 text-center'>
            <h1 className='text-5xl font-medium leading-tight'>KYC 資料審核中</h1>
            <h3 className='text-3xl font-medium leading-tight'>
              系統將在 5-7 個工作天內信件回覆審核結果
            </h3>
          </article>
        )}

        {/* 失敗畫面 */}
        {stage === -1 && (
          <article className='mt-10 text-center'>
            <h1 className='text-5xl font-medium leading-tight'>KYC 資料審核中</h1>
            <h3 className='text-3xl font-medium leading-tight text-rose-500'>
              {errorMsgs[0] || "資料上傳失敗"}
            </h3>
          </article>
        )}
      </main>
    </div>
  );
}

// svgs
const svgArrow = () => (
  <svg
    className='w-6 h-6 text-gray-400'
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
      clipRule='evenodd'
    ></path>
  </svg>
);

/**
 *
 * @param {image file} files
 * @returns a image url from server
 */
const handleImageUpload = async files => {
  // console.log(URL.createObjectURL(files[0]))
  const formData = new FormData();
  formData.append("file", files[0]);

  try {
    const response = await axios.post(URL_UPLOAD_IMAGE, formData);
    if (response?.data?.op_code === "1") {
      const imageUrl = response?.data?.items[0];
      return imageUrl;
    }
  } catch (err) {
    console.error(err);
  }
};

const isSendEmail = async email => {
  try {
    const response = await axios.post(URL_SEND_EMAIL, { email });
    if (response?.data?.op_code === "1") return true;
    else console.log(response);
  } catch (err) {
    console.error(err);
  }
};

const isSendPhone = async phone => {
  try {
    const response = await axios.post(URL_SEND_PHONE, { phone });
    if (response?.data?.op_code === "1") return true;
    else console.log(response);
  } catch (err) {
    console.error(err);
  }
};

const isEmailCodeApproved = async (email, code) => {
  try {
    const response = await axios.post(URL_SEND_EMAIL_CODE, { email, code });
    if (response?.data?.op_code === "1") return true;
    else console.log(response);
  } catch (err) {
    console.error(err);
  }
};

const isPhoneCodeApproved = async (phone, code) => {
  try {
    const response = await axios.post(URL_SEND_PHONE_CODE, { phone, code });
    if (response?.data?.op_code === "1") return true;
    else console.log(response);
  } catch (err) {
    console.error(err);
  }
};
