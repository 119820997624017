/**
 * given that the two arrays are already sorted
 * sort them by key
 * O(n) time & O(n) space
 * @param { compare key } key
 * @param { add newKey to tell which group } newKey
 * @returns
 */
export const mergeTwoBy = (key, newKey) => (arr1, arr2) => {
  /**
    example:
    const approveds = [{ id: 1 }, { id: 3 }, { id: 25 }, { id: 29 }];
    const notApproveds = [{ id: 9 }, { id: 12 }, { id: 30 }];
        
    mergeTwoBy("id")(approveds, notApproveds)
    >>> [ { id: 1 }, { id: 3 }, { id: 9 }, { id: 12 }, { id: 25 }, { id: 29 }, { id: 30 } ]
        
    mergeTwoBy("id", "isApproved")(approveds, notApproveds)
    >>> [ 
            { id: 1, isApproved: true }, { id: 3, isApproved: true },
            { id: 9, isApproved: false }, { id: 12, isApproved: false },
            { id: 25, isApproved: true }, { id: 29, isApproved: true },
            { id: 30, isApproved: false }
        ]
*/
  let merged = [];
  let index1 = 0;
  let index2 = 0;
  let current = 0;

  while (current < arr1.length + arr2.length) {
    let isArr1Depleted = index1 >= arr1.length;
    let isArr2Depleted = index2 >= arr2.length;

    // a:1 b:0 => a
    // a:0 b:1 => b
    // a:1 b:1 => a | b
    // a:0 b:0 => ''
    if (!isArr1Depleted && (isArr2Depleted || arr1[index1][key] < arr2[index2][key])) {
      if (newKey) arr1[index1][newKey] = true; // add new key
      merged[current] = arr1[index1];
      index1++;
    } else {
      if (newKey) arr2[index2][newKey] = false;
      merged[current] = arr2[index2];
      index2++;
    }

    current++;
  }

  return merged;
};
