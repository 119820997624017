import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// hooks
import { useVendorAuth } from "../context/VendorAuthContext";

// 商家(vendor) 後台登入路徑
const RequireVendorForStore = () => {
  const { vendor } = useVendorAuth();
  const location = useLocation();

  return !vendor?.token ? <Navigate to='/vendor/login-vendor' state={{ from: location }} replace /> : <Outlet />;
};

export default RequireVendorForStore;
