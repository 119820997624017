import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { ExclamationTriangleIcon, CheckCircleIcon } from "@heroicons/react/24/outline";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EnterDatetimeModal({
  open = false,
  setOpen,
  confirmBtnName = "確認",
  confirmCallback,
  cancelBtnName = "取消",
  cancelCallback,
}) {
  // const [open, setOpen] = useState(true);
  const [startDatetime, setStartDateTime] = useState("");
  const [endDatetime, setEndDateTime] = useState("");

  const cancelButtonRef = useRef(null);

  const confirm = () => {
    setOpen(false);
    confirmCallback && confirmCallback(startDatetime, endDatetime);
    setStartDateTime("");
    setEndDateTime("");
  };

  const cancel = () => {
    setOpen(false);
    cancelCallback && cancelCallback();
    setStartDateTime("");
    setEndDateTime("");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    {/* title & content */}
                    <div className='mt-3 overflow-x-auto text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <div className='p-2 space-y-1'>
                        <Dialog.Title
                          as='h3'
                          className='text-lg font-medium leading-6 text-gray-900'
                        >
                          請輸入起始時間
                        </Dialog.Title>
                        <DatePicker
                          required
                          className={"rounded-md"}
                          dateFormat='yyyy-MM-dd'
                          isClearable={true}
                          selected={startDatetime}
                          onChange={date => {
                            // console.log(date);
                            setStartDateTime(date);
                          }}
                          peekNextMonth
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode='select'
                        />
                        <Dialog.Title
                          as='h3'
                          className='text-lg font-medium leading-6 text-gray-900'
                        >
                          請輸入結束時間
                        </Dialog.Title>
                        <DatePicker
                          required
                          className={"rounded-md"}
                          dateFormat='yyyy-MM-dd'
                          isClearable={true}
                          selected={endDatetime}
                          onChange={date => {
                            // console.log(date);
                            const endOfDay = new Date(date.setHours(23, 59, 59, 999))
                            setEndDateTime(endOfDay);
                          }}
                          peekNextMonth
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode='select'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* buttons */}
                <div className='px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={confirm}
                  >
                    {confirmBtnName}
                  </button>
                  <button
                    type='button'
                    className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={cancel}
                    ref={cancelButtonRef}
                  >
                    {cancelBtnName}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
