import useSWR from "swr";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// hooks
import { useVendorAuth } from "../../context/VendorAuthContext";

// apis
import { getStoresByVendor } from "../../api/BackerpayApi_2nd";

// components
import BackDrop from "../../components/Spinner/BackDrop";

export default function ListStore() {
  const { vendor } = useVendorAuth();

  const navigate = useNavigate();
  const onRedirect = () => {
    navigate("/vendor/create-store");
  };

  const [stores, setStores] = useState([]);
  const { isLoading } = useSWR(
    ["/getStoresByVendor", vendor?.token],
    ([_, token]) => getStoresByVendor(token),
    {
      onSuccess(data) {
        if (stores.length > 0) return;
        setStores(data);
        console.log("store list\n", data);
      },
      onError(err) {
        toast.error(err?.response?.data?.message || err.message);
        console.error(err);
      },
    }
  );

  return (
    <div className='px-4 mt-10 sm:px-6 lg:px-8'>
      <div>
        <Toaster />
      </div>
      
      {/* isLoading */}
      {isLoading && <BackDrop />}

      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-4xl font-bold'>店家(STORE)</h1>
          {/* <p className='mt-2 text-sm text-gray-700'>店家(STORE)</p> */}
        </div>
        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          <button
            type='button'
            className='py-2.5 px-7 text-3xl font-bold rounded-xl text-white bg-[#2010D6] hover:bg-[#2010D6]/90 block text-center shadow-sm'
            onClick={onRedirect}
          >
            建立新店家
          </button>
        </div>
      </div>

      <div className='flow-root mt-8'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-0'
                  >
                    店家名稱
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-xl font-bold text-gray-900'>
                    備註
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-xl font-bold text-gray-900'>
                    線下/線上
                  </th>
                  <th scope='col' className='px-3 py-3.5 text-left text-xl font-bold text-gray-900'>
                    出金方式
                  </th>
                  {/* <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                    <span className='sr-only'>Edit</span>
                  </th> */}
                </tr>
              </thead>

              <tbody className='divide-y divide-gray-200'>
                {stores.map(store => (
                  <tr key={store?.id}>
                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0'>
                      {store?.name}
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                      {store?.remark}
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                      {store?.unionType}
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                      {getPaymentType(store?.withdrawType)}
                    </td>
                    {/* <td className='relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0'>
                      <a href='#' className='text-indigo-600 hover:text-indigo-900'>
                        Edit<span className='sr-only'>, {person.name}</span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function getPaymentType(key) {
  const paymentType = { EC: "線上", QR: "線下" };
  const type = paymentType[key];
  return type || key;
}
