import React from "react";

export default function TextInput({ label, button, errorMessage, inputType, initValue, callback }) {
  const [errorMsg, setErrorMsg] = React.useState("");
  const inputRef = React.useRef();

  /**
   *    handle input change
   */
  const handleChange = e => {
    setErrorMsg("");

    if (!e.target.value) return;
    if (!isValidate(inputType, e.target.value)) return setErrorMsg(errorMessage);
  };

  /**
   *    handle submit
   */
  const handleSubmit = e => {
    e.preventDefault();

    if (errorMsg) return;
    if (!inputRef.current.value) return;

    callback(inputType, inputRef.current.value);
  };

  /**
   *    init value
   */
  React.useEffect(() => {
    // auto focus
    inputRef.current.focus();

    if (initValue) {
      inputRef.current.value = initValue;
    }
  }, [initValue]);

  return (
    <form
      className='block p-5 max-w-sm mx-auto rounded-lg border border-gray-200 shadow-md'
      onSubmit={handleSubmit}
    >
      {/* label */}
      <label htmlFor={inputType} className='block mb-2 text-sm font-medium text-gray-900'>
        {label}
      </label>

      {/* input */}
      <input
        ref={inputRef}
        id={inputType}
        required
        className={`
            form-control 
            block
            w-full
            m-0
            pl-3 py-1.5
            text-base text-gray-700
            font-normal
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition ease-in-out
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
        `}
        onChange={handleChange}
      />

      {/* error message */}
      <p className={`mt-2 text-pink-600 text-sm ${errorMsg ? "" : "hidden"}`}>{errorMsg}</p>

      {/* button */}
      <button className='mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 '>
        {button}
      </button>
    </form>
  );
}

// 需要參照 inputType
const rePatterns = {
  email: /^\S+@\S+\.\S+$/,
  emailVerification: /\b\d{6}\b/g,
  phone: /((?=(09))[0-9]{10})$/g,
  phoneVerification: /\b\d{6}\b/g,
  wallet: /./,
};

const isValidate = (type, inputText) => {
  const re = rePatterns[type];
  if (re) return re.test(inputText);
};
